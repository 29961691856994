import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/merchant";
import PageBox from "../public/index";
import { HashRouter, Link } from 'react-router-dom'
import { Breadcrumb, Row, Col, Button } from "antd";

class Merchant extends Component {
  constructor() {
    super()
    this.state = {
      id: null
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params
    this.setState({ id })
    this.props.getMerchant({ id })
  }

  getForm = () => {
    const { id } = this.state;
    let router=new HashRouter()
    router.history.push(`/mch_comment_form/${id}`)
  }


  render() {
    let { merchant } = this.props.merchant;

    return (
      <PageBox>
        <div className="main">
          <Breadcrumb>
            <Breadcrumb.Item>
              <a href="/">首页</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/merchants">商家评论</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          <Row gutter={16}>
            <Col md={17} xs={24}>
              <div className="main">
                {merchant ?
                  <div className="merchant">
                    <dl>
                      <dt>{merchant.me_title}</dt>
                      <dd>联系电话：{merchant.me_tel}</dd>
                      <dd>联系地址：{merchant.me_addr}</dd>
                      <dd>营业时间：{merchant.me_yytime}</dd>
                      <dd><Button type="primary" onClick={this.getForm}>写评价</Button></dd>
                    </dl>
                  </div>
                  : null}
                <div className="commentForm">
                  <div className="emptyBox"><span>暂无评价</span></div>
                </div>
              </div>
            </Col>
            <Col md={7} xs={24}></Col>
          </Row>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(Merchant);
