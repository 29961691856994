import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/agent";
import PageBox from "../public/index";
// import {Link} from 'react-router-dom'
import { Row, Col, Breadcrumb, Form, Input, Button } from "antd";

// import { HomeOutlined } from "@ant-design/icons";
class HotNews extends Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.onFinish = this.onFinish.bind(this);
    this.state = {
      time: 0
    };
  }
  onFinish(values) {
    let send = { ...values,f_type:1};
    let time = 3;
    let t = setInterval(() => {
      if (time <= 0) {
        clearInterval(t);
        this.formRef.current.resetFields();
      }
      this.setState({ time: time-- });
    }, 1000);
    send['f_type']='培训申请';
    this.props.apply(send);
  }

  componentDidMount(){
    this.props.getFormContent({sign:'training'})
  }

  render() {
    let {formContent}=this.props.index;
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            训练申请
          </Breadcrumb.Item>
        </Breadcrumb>
            <div className="main jiameng">
            {formContent?
                <div className="formIntro" dangerouslySetInnerHTML={{__html:formContent.fc_content}}></div>
                :null}
              <div className="applyTitle">
                请完整填写申请表单，我们将第一时间和您联系。
              </div>
              <Form
                name="cpapply"
                layout="vertical"
                ref={this.formRef}
                onFinish={this.onFinish}
              >
                <Form.Item
                  name="f_name"
                  rules={[
                    {
                      required: true,
                      message: "姓名不能为空"
                    }
                  ]}
                >
                  <Input placeholder="请输入姓名" autoComplete="off" />
                </Form.Item>
                <Form.Item
                  name="f_phone"
                  rules={[
                    {
                      required: true,
                      message: "手机号不能为空"
                    }
                  ]}
                >
                  <Input placeholder="请输入手机号" autoComplete="off" />
                </Form.Item>
                <Form.Item
                  name="f_city"
                  rules={[
                    {
                      required: true,
                      message: "城市不能为空"
                    }
                  ]}
                >
                  <Input placeholder="请输入所在城市" autoComplete="off" />
                </Form.Item>
                <Form.Item
                  name="f_desp"
                >
                  <Input.TextArea placeholder="申请说明" autoComplete="off" rows={4} />
                </Form.Item>
                <Form.Item>
                <Button
                      type="primary"
                      block
                      htmlType="submit"
                      disabled={this.state.time ? true : false}
                    >
                      提交{this.state.time ? this.state.time : null}
                    </Button>
                </Form.Item>
              </Form>
            </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
