const indexInit = {
    
}
const news = (state = indexInit, action) => {
    switch (action.type) {
      case "EBOOK_LST":
        return Object.assign({}, state, {
          ...action
        });
      case "EBOOK_TYPE":
        return Object.assign({}, state, {
          ...action
        });
        case "EBOOK_INFO":
          return Object.assign({}, state, {
            ...action
          });
          case "EBOOK_CHAPTERS":
            return Object.assign({}, state, {
              ...action
            });
            //
            case "EBOOK_CHAPTER":
              return Object.assign({}, state, {
                ...action
              });
              case "EBOOK_RECORD_LST":
              return Object.assign({}, state, {
                ...action
              });
              case "EBOOK_BUY_LST":
              return Object.assign({}, state, {
                ...action
              });
              //
              case "EBOOK_COLLECT":
              return Object.assign({}, state, {
                ...action
              });

      default:
        return state;
    }
}

export default news