import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/user";
import { Form, Button, Input, InputNumber, message,Spin } from "antd";
import { Link } from "react-router-dom";
import PageBox from "../public/index";

class Login extends Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      time: 0,
      values: {}
    };
  }

  handleClick = (e) => {
    e.target.focus();
  }

  getCode = () => {
    this.formRef.current.validateFields(["phone"]).then(value => {
      this.props.sendSms({ phone: value.phone, sign: 'auth' });
      let time = 60;
      let t = setInterval(() => {
        if (time <= 0) {
          clearInterval(t);
        }
        this.setState({ time: time-- });
      }, 1000);
    });
  }

  onFinish = values => {
    values['openid'] = this.props.user.openid;
    this.props.login(values)
  };





  componentDidMount() {
    let url = window.location.href;
    let args = "appid=wx30921e85b104780f&"
    var useragent = window.navigator.userAgent.toLowerCase();
    if (useragent.match(/MicroMessenger/i) == 'micromessenger') {
      args="appid=wxf9770f0d25a2c31a&";
    }


    let flag = true;
    if (url.indexOf('?code=') >= 0) {
      let arr = url.split('?')
      let arr2 = arr[1]
      args += arr2
    } else {
      flag = false;
    }

    if (!flag) {
      message.info('参数无效')
      // let router=new HashRouter()
      // router.history.push("/")
    } else {
      this.props.checkwechat(args)
    }
  }






  render() {
    let { values } = this.state;
    let { promoter } = this.props.match.params;
    if (promoter) {
      let { values } = this.state;
      values['promoter'] = promoter;
    }
    let {openid}=this.props.user;

    return (
      <PageBox>
        {openid?
        <div className="login" style={{ paddingTop: 50 }}>
          <div className="login-tip">微信登陆绑定用户</div>
          <Form
            name="login"
            ref={this.formRef}
            style={{maxWidth:400}}
            initialValues={values}
            onFinish={this.onFinish}
          >
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "请输入正确格式手机号!",
                  pattern: /^1[3456789]\d{9}$/
                }
              ]}
            >
              <Input placeholder="请输入手机号"  onClick={this.handleClick}/>
            </Form.Item>
            <Form.Item
              name="code"
              rules={[
                {
                  required: true,
                  message: "请输入验证码!"
                }
              ]}
            >
              <div style={{ display: "flex", justifyContent: "spaceBetween" }}>
                <InputNumber
                  placeholder="请输入验证码"
                  style={{ flexGrow: 1, marginRight: 10 }}
                  onClick={this.handleClick}
                />
                {this.state.time ? (
                  <Button type="ghost" disabled>
                    {this.state.time} s
                  </Button>
                ) : (
                    <Button type="ghost" onClick={this.getCode}>
                      获取验证码
                    </Button>
                  )}
              </div>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                提交
            </Button>
            </Form.Item>
            <Form.Item className="loginlink">
              <div style={{ textAlign: "right" }}>
                <Link to="/reg">注册账号</Link>
              </div>
            </Form.Item>
          </Form>


        </div>
        :<div className="flexCenter"><Spin tip="授权查询中..."/></div>}
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(Login);
