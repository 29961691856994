import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/index";
import {Card,Button} from 'antd'

class App extends Component {

  componentDidMount(){
    this.props.saleBook()
  }

  render() {
    let { shopBook } = this.props.index;

    return (
      <div className="indexShopBook">
        {shopBook ?
        <Card style={{ width: '100%' }}>
          <Link to="/goods/7">
            <img src={shopBook.gd_thumb} alt="书籍" />
            <div className="title">《{shopBook.gd_title}》</div>
            <div className="price">价格：<span>￥{shopBook.gd_price}</span></div>
            <div className="detail">{shopBook.gd_desp}</div>
            <div style={{textAlign:'center',paddingTop:10}}><Button type="primary" block>订购</Button></div>
          </Link>
          </Card>
          : null}
      </div>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(App);
