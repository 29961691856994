import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/index";

class App extends Component {


  render() {
    let { qipao } = this.props.index;
    let { video } = qipao;
    return (
      <div className="indexVideo">
        <h3>
            <Link to="/pinpaihui/3">影视</Link>
          </h3>
        <ul>
          {video ? video.map(ele => (
            <li key={ele.qp_id}>
              <a href={`/qnarticle/${ele.qp_id}`} title={ele.qp_title}>
                <img src={ele.qp_thumb} alt="视频" />
                <span>{ele.qp_title.length>20?ele.qp_title.substr(0,20)+'...':ele.qp_title}</span>
              </a>
            </li>
          )) : null}
        </ul>
      </div>
    );
  }
}


export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(App);
