import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/order";
import PageBox from "../public/index";
import { HashRouter, Link } from "react-router-dom";
import { Row, Col, Breadcrumb, Button, Spin, Modal, Card, Result, message } from "antd";
import QRCode from 'qrcode.react'
import Address from '../user/shop/addr2'
import moment from "moment";
import "moment/locale/zh-cn";
import Dates from 'common/date'

moment.locale("zh-cn");

class Ebook extends Component {
  ref = React.createRef()
  constructor() {
    super()
    this.buy = this.buy.bind(this)
    this.state = {
      visible: false,
      flag: true,
      validtime: '',
      visible2: false,
      addr: null,
    }
  }

  //保存地址
  clickAddr=(e)=>{
    this.setState({ addr: e })
  }

  buy() {
    let uid = localStorage.getItem("uid")
    let id = this.props.match.params.id;
    let obj = {
      out_trade_no: id
    }
    let { orderInfo } = this.props.order;
    if(!this.state.addr && orderInfo['order_is_true']){
      message.info('请设置收件地址')
      return false;
    }
    if (this.state.addr) {
      obj['addr_id'] = this.state.addr.addr_id;
    }
    if (!uid) {
      this.props.showLogin('login')
    }

    //判断是否为微信环境，如果是，跳转微信授权链接
    // 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3054a15a89a456f3&redirect_uri=http://www.qiniangniang.com/#/auth&response_type=code&scope=snsapi_base&state=' + id + '#wechat_redirect'
    if (orderInfo.is_wechat) {
      let jump = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3054a15a89a456f3&redirect_uri=http%3a%2f%2fwww.qiniangniang.com%2f%23%2fauth&response_type=code&scope=snsapi_base&state=' + id + '#wechat_redirect'
      window.location.href = jump;
    } else {
      this.props.getPayUrl(obj)
    }
  }




  selectAddr=()=>{
    this.setState({ visible2: true })
  }

  cancelModal=()=>{
    this.setState({ visible: false, visible2: false })
    let id = this.props.match.params.id;
    if(id){
      this.props.orderInfo({ id });
    }

  }

  componentDidMount() {
    let id = this.props.match.params.id;
    if(id){
      this.props.orderInfo({ id });
    }

  }

  componentDidUpdate(props) {
    let { flag } = this.state;
    let { orderInfo } = this.props.order;
    if (props.order.orderInfo !== orderInfo && flag) {
      let start = orderInfo.order_createtime
      let end = moment(orderInfo.order_createtime).subtract(-5, 'minutes')
      let t = setInterval(() => {
        let time = Dates.difSeconds(start, end)
        if (time === 'over') {
          this.setState({ validtime: 'over' })
          clearInterval(t)
        }
        this.setState({ validtime: time })
      }, 1000);
      this.setState({ flag: false })
    }
  }

  componentWillUnmount() {
    this.props.delOrder()
  }

  toBaoming=(url)=>{
    let router=new HashRouter()
    router.history.push(url)
  }

  render() {
    let { orderInfo, payUrl } = this.props.order;
    let id = this.props.match.params.id;
    let { addr, validtime } = this.state;
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            确认订单
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col span={24}>
            {orderInfo ?
              <div className="main orderinfo goods">
                <Card title="订单详情" size="small" style={{ marginBottom: 10 }}>
                  <dl>
                    <dt>订单名称：{orderInfo.order_title}</dt>
                    <dd>商品数量：{orderInfo.order_num}</dd>
                    <dd>订单总金额：{orderInfo.order_total}</dd>
                    <dd>订单创建时间：{orderInfo.order_createtime}</dd>
                    <dd>订单状态：{orderInfo.order_statusMsg}</dd>
                    <dd>订单号：{id}</dd>
                  </dl>
                </Card>
                {orderInfo.order_is_true ?
                  <Card title="快递信息" extra={<Button onClick={this.selectAddr}>选择地址</Button>} size="small">
                    {addr ?
                      <dl style={{ paddingTop: 10 }}>
                        <dt>收件人：{addr.addr_name}</dt>
                        <dd>联系电话：{addr.addr_phone}</dd>
                        <dd>收件地址：{addr.addr_detail}</dd>
                      </dl>
                      : (
                        orderInfo.order_name ?
                          <dl style={{ paddingTop: 10 }}>
                            <dt>收件人：{orderInfo.order_name}</dt>
                            <dd>联系电话：{orderInfo.order_phone}</dd>
                            <dd>收件地址：{orderInfo.order_addr}</dd>
                          </dl>
                          : <div style={{ textAlign: 'center', padding: '15px 0' }}>请设置收件地址</div>
                      )
                    }
                  </Card> : null}
                {orderInfo && orderInfo.order_status === 4 ?
                  <div style={{ textAlign: 'center' }}>
                    {orderInfo.order_type===4?
                    <div className="orderend">
                      <Link to={`/competition-user-form/${orderInfo.order_fid}`} onClick={this.toBaoming(`/competition-user-form/${orderInfo.order_fid}`)}>报名成功，点此填写赛事报名信息</Link>
                    </div>
                    :<Result
                    status="success"
                    title="订单结算成功"
                  />}
                  </div>
                  :
                  <div style={{ paddingTop: 15, textAlign: 'center' }}>
                    <div className="validtime" style={{ paddingBottom: 10 }}>
                      {validtime ?
                        validtime === 'over' ? '订单已过有效期，请重新下单' : `订单有效期：${validtime}`
                        : null}
                    </div>
                    {payUrl ?
                      <div>
                        <QRCode value={payUrl} />
                        <div style={{ paddingTop: 10 }}>提示：微信扫码支付</div>
                      </div>
                      : <Button type="primary" size="large" onClick={this.buy} disabled={validtime === 'over' ? true : false}>立即支付</Button>}
                  </div>}
              </div>
              : <Spin />}
          </Col>
        </Row>
        <Modal
          title="选择收件地址"
          visible={this.state.visible2}
          centered={true}
          footer={null}
          onCancel={this.cancelModal}
        >
          <Address onClick={this.clickAddr} />
        </Modal>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(Ebook);
