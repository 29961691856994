const indexInit = {
    
}
const salon = (state = indexInit, action) => {
    switch (action.type) {
      case "SALON_LST":
        return Object.assign({}, state, {
          ...action
        });
        case "SALON_USER":
          return Object.assign({}, state, {
            ...action
          });
        case "SALON_RECORD":
          return Object.assign({}, state, {
            ...action
          });
      default:
        return state;
    }
}

export default salon