import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/competition";
import {Link} from 'react-router-dom'
import {Card } from "antd";

class HotNews extends Component {

  componentDidMount() {
    let id = this.props.id;
    this.props.cpHotUsers(id)
  }

  render() {
    let {cpHotUsers } = this.props.competition;
    let id = this.props.id;
    return (
      cpHotUsers && cpHotUsers.length?
        <Card  className="hotusers" title="人气选手" size="small" style={{marginBottom:15}} extra={<Link to={`/com-users/${id}`} className="cardMore">全部</Link>}>
          <ul className="userLst">
          {cpHotUsers.map(ele=>(
            <li key={ele.user_id}><Link to={`/cpuser/${id}/${ele.user_id}`}>
              <img src={ele.cpu_xing_thumb?ele.cpu_xing_thumb:ele.user_thumb} alt="会员头像"/>
              <span>{ele.cpu_name?ele.cpu_name:ele.user_nickname}</span>
              </Link></li>
          ))}
          </ul>
            <div style={{textAlign:'center'}}><Link to={`/com-users/${id}`} className="cardMore2">查看全部选手</Link></div>
        </Card>
        :null
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
