import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/score";
import PageBox from "components/public/userIndex";
import { Breadcrumb,List} from "antd";
import UserPub from "../public";
import UserLeft from "../left";

class HotNews extends Component {

  componentDidMount() {
    this.props.lst({pageSize: 5, current: 1});
  }

  pages = (page) => {
    this.props.lst({ pageSize: 5, current: page })
  }

  render() {
    let { scoreLst } = this.props.user;
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            会员中心
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="user-public">
          <UserPub />
        </div>
        <div className="user-center" id="medalpage">

          <div className="user-left">
            <UserLeft path={this.props.history.location.pathname} />
          </div>
          <div className="user-right">
            <div className="main">
              <List
                itemLayout="vertical"
                size="large"
                pagination={{
                  onChange: this.pages,
                  pageSize: 5,
                  total: scoreLst ? scoreLst.total : 0
                }}
                dataSource={scoreLst ? scoreLst.data : []}
                renderItem={item => (
                  <List.Item
                    key={item.title}
                  >
                    <List.Item.Meta
                      title={<span>礼物名称：{item.gift_name}</span>}
                    />
                    <div>旗币：{item.gift_score} 礼物数：{item.gift_total} 时间：{item.gift_datetime}</div>
                  </List.Item>
                )}
              />
            </div>
          </div>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
