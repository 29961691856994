import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/learn";
import { Card } from "antd";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

class RightNews extends Component {

  componentDidMount() {
    this.props.edurec();
  }

  render() {
    let { learnRec } = this.props.learn;
    return (
      <div className="right">
        <Card title="推荐课程" size="small">
          <ul className="topicrec">
            {learnRec
              ? learnRec.map(item => (
                  <li key={item.ln_id}>
                    <Link to={`/course/${item.ln_id}`}>
                      <span>{item.ln_title.length>14?item.ln_title.slice(0,14)+'...':item.ln_title}</span>
                    </Link>
                  </li>
                ))
              : null}
          </ul>
        </Card>
      </div>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
) (RightNews);
