import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/goods";
import PageBox from "../public/index";
// import { Link } from "react-router-dom";
import { Row, Col, Breadcrumb, Button, Spin, InputNumber } from "antd";
import Banner from './banner/index'
import FUNC from 'common/public'
import QRCode from 'qrcode.react'
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

class Ebook extends Component {
  constructor(){
    super()
    this.buy=this.buy.bind(this)
    this.state={
      num:1
    }
  }
  buy(){
    let uid=localStorage.getItem("uid")
    let id = this.props.match.params.id;
    let num=this.state.num;
    //登录后发送订单
    if(uid){
      let obj={
        id:id,
        uid:localStorage.getItem("uid"),
        num:num,
        type:9
      }
      this.props.createOrder(obj);
    }else{
      this.props.showLogin('login')
    }
  }


  componentDidMount() {
    let id = this.props.match.params.id;
    this.props.goodsinfo({ id: id, type: 1 });
  }

  render() {
    let { goodsInfo } = this.props.goods;
    let myurl=window.location.href
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            商品详情
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col span={24}>
            {goodsInfo ?
              <div className="main goods">
                <div className="goodstitle">
                  <div className="goods-album">
                    <Banner data={goodsInfo} />
                  </div>
                  <div className="titlebox">
                    <div>
                      <div className="title">{goodsInfo.gd_title}</div>
                      <div className="desp">{goodsInfo.gd_desp}</div>
                      <div className="price1"><span>价格：</span><i>￥{goodsInfo.gd_price2}</i></div>
                      <div className="price2"><span>促销价：</span><i>￥{goodsInfo.gd_price}</i></div>
                    </div>
                    <div className="stockbox">
                      <div className="num">购买数量：<InputNumber size="large" value={this.state.num} onChange={(num)=>{this.setState({num})}} min={1} max={goodsInfo.gd_stock}/></div>
            <div className="stock">库存数量：{goodsInfo.gd_stock}</div>
                      </div>
                      <div style={{paddingTop:5}}>
                        {goodsInfo['shop']['shop_qq']?
                        <Button size="large" style={{marginRight:15}}><a rel="noopener noreferrer" target="_blank" href={`http://wpa.qq.com/msgrd?v=3&uin=${goodsInfo['shop']['shop_qq']}&site=qq&menu=yes`}>联系客服</a></Button>
                        :null}
                      <Button type="primary" onClick={this.buy} size="large">立即购买</Button>
                      </div>
                      
                  </div>
                </div>
                <div  className="goodscontent">
                  <div className="title">商品介绍</div>
                <div dangerouslySetInnerHTML={{ __html: goodsInfo.gd_content }}>
                  </div>
                  {myurl ?
                    <div className="mytuiguang" style={{ textAlign: 'center' }}>
                      <QRCode value={myurl} />
                      <div>本商品二维码</div>
                    </div> : null}
                </div>
              </div>
              : <Spin />}
          </Col>
        </Row>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(Ebook);
