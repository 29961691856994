import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions";
import { DatePicker, Form } from "antd"
import moment from 'moment'

class UserForm extends Component {
  constructor() {
    super()
    this.state = {
      value: ""
    }
  }

  componentDidMount() {

  }

  handleClick = (e) => {
    e.target.focus();
  }

  handleChange = (field, value) => {
    this.setState({ value })
    this.props.onChange(field, moment(value).format('YYYY-MM-DD HH:mm:ss'))
  }

  render() {
    let { item,index} = this.props;
    // let value=item.value ?moment(item.value,'YYYY-MM-DD HH:mm:ss'):"" 

    return <Form.Item
      label={item.title}
      key={item.field}
      name={item.field}
      rules={[
        {
          required: !!item.required,
          message: "请输入" + item.title + "!"
        }
      ]}
    >
      <DatePicker
        placeholder={item.placeholder}
        showTime={item.type==='datetime'?{ defaultValue: moment('11:00:00', 'HH:mm:ss') }:null}
        tabIndex={index}
        minuteStep={30}
        secondStep={60}
        disabled={!!item.disabled}
      />
    </Form.Item>
  }
}

export default connect(state => state, dispatch => bindActionCreators(actionsCreators, dispatch))(UserForm);