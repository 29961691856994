import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/doudou";
import PageBox from "../public/index";
import { Breadcrumb, Col, Row, Card } from "antd";
import { Link } from 'react-router-dom'
import PageAd from './ad/index'


class Doudouwen extends Component {

  componentDidMount() {
    this.props.getDoudouwen()
    this.props.getAds(8)
  }

  render() {
    let { knowledgeChannel, xiushenChannel, ebookChannel, tingIndex, learnIndex, ebookIndex, tingRec, teacherRec, ebookRec, authorRec, readAct, huizhangRec } = this.props.ddw;
    let { eduLstAds } = this.props.index;
    return (
      <PageBox>
        {eduLstAds && eduLstAds.length ?
          <PageAd data={eduLstAds} />
          : null}
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/doudouwen">斗斗文</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="main" id="doudouwen">
          <div className="ddwchannel">
            <div className="channelMenu">
              <dl>
                <dt>听<span>精品</span></dt>
                <dd>
                  {knowledgeChannel ? knowledgeChannel.map(ele => (
                    <Link to={`/edu/${ele.type_id}`} key={ele.type_id} className="item">{ele.type_name}</Link>
                  )) : null}
                </dd>
              </dl>
              <dl>
                <dt>读<span>经典</span></dt>
                <dd>
                  {ebookChannel ? ebookChannel.map(ele => (
                    <Link to={`/ebooktype/${ele.type_id}`} key={ele.type_id} className="item">{ele.type_name}</Link>
                  )) : null}
                </dd>
              </dl>
              <dl>
                <dt>习<span>雅艺</span></dt>
                <dd>
                  {xiushenChannel ? xiushenChannel.map(ele => (
                    <Link to={`/learn/${ele.cate_id}`} key={ele.cate_id} className="item">{ele.cate_name}</Link>
                  )) : null}
                </dd>
              </dl>
            </div>
          </div>
          <div className="ddwitem">
            <Row gutter={16} style={{ marginTop: 15 }}>
              <Col md={17} xs={24}>
                <h2><span>斗斗文听世界</span> <Link to="/edu">更多<i className="iconfont icon-more1"></i></Link></h2>
                <ul className="ddw-ting">
                  {tingIndex ? tingIndex.map(ele => (
                    <li key={ele.ln_id}>
                      <Link to={`/course/${ele.ln_id}`}>
                        <img src={ele.ln_thumb} alt={ele.ln_title} />
                        <dl>
                          <dt>{ele.ln_title}</dt>
                          <dd>￥{ele.ln_price}</dd>
                        </dl>
                      </Link>
                    </li>
                  )) : null}
                </ul>
                <h2><span>斗斗文藏精阁</span><Link to="/ebooktype"> 更多<i className="iconfont icon-more1"></i> </Link></h2>
                <ul className="ddw-du">
                  {ebookIndex ? ebookIndex.map(ele => (
                    <li key={ele.ebook_id}>
                      <Link to={`/ebook/${ele.ebook_id}`}>
                        <img src={ele.ebook_thumb} alt={ele.ebook_title} />
                        <dl>
                          <dt>{ele.ebook_title}</dt>
                          <dd>{ele.ebook_desp}</dd>
                        </dl>
                      </Link>
                    </li>
                  )) : null}
                </ul>
                <h2><span>斗斗文研习社</span> <Link to="/learn">更多<i className="iconfont icon-more1"></i></Link></h2>
                <ul className="ddw-xi">
                  {learnIndex ? learnIndex.map(ele => (
                    <li key={ele.article_id}>
                      <Link to={`/article/${ele.article_id}`}>
                        <img src={ele.article_thumb} alt={ele.article_title} />
                        <dl>
                          <dt>{ele.article_title}</dt>
                          <dd>{ele.article_desp}</dd>
                        </dl>
                      </Link>
                    </li>
                  )) : null}
                </ul>
              </Col>
              <Col md={7} xs={24}>
                {/* 听推荐 */}
                {tingRec ?
                  <Card size="small" title="精品音频推荐" extra={<Link to="/edu">更多</Link>} className="rightCard">
                    {tingRec.map(ele => (
                      <Link to={`/course/${ele.ln_id}`} key={ele.ln_id}>{ele.ln_title}</Link>
                    ))}
                  </Card> : null}
                {/* 老师推荐 */}
                {teacherRec ?
                  <Card size="small" title="老师推荐" extra={<Link to="/edu">更多</Link>} className="rightCard">
                    {teacherRec.map(ele => (
                      <a href={`/author/${ele.author_id}`} key={ele.author_id}>
                        <dl>
                          <dt>{ele.author_name}</dt>
                          <dd dangerouslySetInnerHTML={{ __html: ele.author_desc }} />
                        </dl>
                      </a>
                    ))}
                  </Card> : null}
                {/* 空中书城推荐 */}
                {ebookRec ?
                  <Card size="small" title="精品书籍推荐" extra={<Link to="/ebooktype">更多</Link>} className="rightCard">
                    {ebookRec.map(ele => (
                      <Link to={`/ebook/${ele.ebook_id}`} key={ele.ebook_id}>{ele.ebook_title}</Link>
                    ))}
                  </Card> : null}
                {/* 作者推荐 */}
                {authorRec ?
                  <Card size="small" title="作家推荐" extra={<Link to="/author">更多</Link>} className="rightCard">
                    {authorRec.map(ele => (
                      <Link to={`/author/${ele.author_id}`} key={ele.author_id}>
                        <img src={ele.author_thumb} alt="头像" />
                        <dl>
                          <dt>{ele.author_name}</dt>
                          <dd>{ele.author_desp.substr(0, 30)}...</dd>
                        </dl>
                      </Link>
                    ))}
                  </Card> : null}
                <img src={require('common/img/dushuhui.jpg')} alt="读书会" className="dushuhui" />
                {readAct ?
                  <Card size="small" title="斗斗文读书会" className="rightCard" style={{ marginTop: 5 }}>
                    {readAct.map(ele => (
                      <Link to={`/salon/${ele.salon_id}`} key={ele.salon_id}>{ele.salon_title}</Link>
                    ))}
                  </Card> : null}
                {huizhangRec ?
                  <Card size="small" title="读书会优秀会长风采" className="rightCard">
                    {huizhangRec.map(ele => (
                      <Link to={`/article/${ele.article_id}`} key={ele.article_id}>{ele.article_title}</Link>
                    ))}
                  </Card> : null}
              </Col>
            </Row>
          </div>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(Doudouwen);
