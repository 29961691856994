import BannerAnim from 'rc-banner-anim';
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/goods";
import TweenOne from 'rc-tween-one';
import './banner.css';
const { Element, Thumb } = BannerAnim;
const BgElement = Element.BgElement;

class Ebook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgArray: [],
      intShow: 0,
      prevEnter: false,
      nextEnter: false,
      thumbEnter: true,
    }
    let arr = [
      'onChange',
      'prevEnter',
      'prevLeave',
      'nextEnter',
      'nextLeave'
    ]
    arr.forEach((method) => this[method] = this[method].bind(this));
  }
  componentDidMount() {
    let data = this.props.data.gd_thumbs;
    let arr = data ? data.split(',') : []
    this.setState({ imgArray: arr })
  }

  onChange(type, int) {
    if (type === 'before') {
      this.setState({
        intShow: int,
      });
    }
  }

  getNextPrevNumber() {
    let nextInt = this.state.intShow + 1;
    let prevInt = this.state.intShow - 1;
    if (nextInt >= this.state.imgArray.length) {
      nextInt = 0;
    }
    if (prevInt < 0) {
      prevInt = this.state.imgArray.length - 1;
    }

    return [prevInt, nextInt];
  }

  prevEnter() {
    this.setState({
      prevEnter: true,
    });
  }

  prevLeave() {
    this.setState({
      prevEnter: false,
    });
  }

  nextEnter() {
    this.setState({
      nextEnter: true,
    });
  }

  nextLeave() {
    this.setState({
      nextEnter: false,
    });
  }


  render() {
    const thumbChildren = this.state.imgArray.map((img, i) =>
      <span key={i}><i style={{ backgroundImage: `url(${img})` }} /></span>
    );
    return (
      <BannerAnim
        onChange={this.onChange}
        prefixCls="custom-arrow-thumb"
        arrow={false}
      >
        {this.state.imgArray.map(ele => (
          <Element key={ele}
            prefixCls="banner-user-elem"
          >
            <BgElement
              key={ele}
              className="bg"
              style={{
                backgroundImage: `url(${ele})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            />
          </Element>
        ))}


        <Thumb prefixCls="user-thumb" key="thumb" component={TweenOne}
          animation={{ bottom: this.state.thumbEnter ? 0 : -70 }}
        >
          {thumbChildren}
        </Thumb>
      </BannerAnim>
    );
  }
}
export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(Ebook);