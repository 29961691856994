import Type from "./type";

import Conf from "../common/config";
import { message } from "antd";
import Axios from './request'
const { DOU_CHANNEL,PAGE_AD} = Type;


export function getAds(arg){
  return dispatch => {
    Axios.get(Conf.api + "/banner/lst?type="+arg).then(res => {
      if (!res.data.code) {
        dispatch({ type: PAGE_AD, eduLstAds: res.data.data });
      }
    });
  };
}


/**
 * 斗斗文页面数据
 */
export function getDoudouwen() {
  let that=this;
  return dispatch => {
    that.knowledgeChannel()
    that.xiushenChannel()
    that.ebookChannel()
    that.tingIndex()
    that.ebookIndex()
    that.learnIndex()
    that.tingRec()
    that.teacherRec()
    that.ebookRec()
    that.authorRec()
    that.readAct()
    that.huizhangRec()
  };
}


/**
 * 知识付费频道列表
 */
export function knowledgeChannel() {
  return dispatch => {
    Axios.post(Conf.api + "/doudou/knowledgeChannel").then(res => {
      if (!res.data.code) {
        dispatch({ type: DOU_CHANNEL, knowledgeChannel: res.data.data });
      }
    });
  };
}

/**
 * 修身频道列表
 */
 export function xiushenChannel() {
  return dispatch => {
    Axios.post(Conf.api + "/doudou/xiushenChannel").then(res => {
      if (!res.data.code) {
        dispatch({ type: DOU_CHANNEL, xiushenChannel: res.data.data });
      }
    });
  };
}

/**
 * 空中书城频道列表
 */
 export function ebookChannel() {
  return dispatch => {
    Axios.post(Conf.api + "/doudou/ebookChannel").then(res => {
      if (!res.data.code) {
        dispatch({ type: DOU_CHANNEL, ebookChannel: res.data.data });
      }
    });
  };
}

/**
 * 听模块9篇
 */
export function tingIndex() {
  return dispatch => {
    Axios.post(Conf.api + "/doudou/tingIndex").then(res => {
      if (!res.data.code) {
        dispatch({ type: DOU_CHANNEL, tingIndex: res.data.data });
      }
    });
  };
}

export function ebookIndex() {
  return dispatch => {
    Axios.post(Conf.api + "/doudou/ebookIndex").then(res => {
      if (!res.data.code) {
        dispatch({ type: DOU_CHANNEL, ebookIndex: res.data.data });
      }
    });
  };
}
export function learnIndex() {
  return dispatch => {
    Axios.post(Conf.api + "/doudou/learnIndex").then(res => {
      if (!res.data.code) {
        dispatch({ type: DOU_CHANNEL, learnIndex: res.data.data });
      }
    });
  };
}

/**
 * 听推荐
 */
export function tingRec() {
  return dispatch => {
    Axios.post(Conf.api + "/doudou/tingRec").then(res => {
      if (!res.data.code) {
        dispatch({ type: DOU_CHANNEL, tingRec: res.data.data });
      }
    });
  };
}

export function teacherRec() {
  return dispatch => {
    Axios.post(Conf.api + "/doudou/teacherRec").then(res => {
      if (!res.data.code) {
        dispatch({ type: DOU_CHANNEL, teacherRec: res.data.data });
      }
    });
  };
}

export function ebookRec() {
  return dispatch => {
    Axios.post(Conf.api + "/doudou/ebookRec").then(res => {
      if (!res.data.code) {
        dispatch({ type: DOU_CHANNEL, ebookRec: res.data.data });
      }
    });
  };
}

export function authorRec() {
  return dispatch => {
    Axios.post(Conf.api + "/doudou/authorRec").then(res => {
      if (!res.data.code) {
        dispatch({ type: DOU_CHANNEL, authorRec: res.data.data });
      }
    });
  };
}

export function readAct() {
  return dispatch => {
    Axios.post(Conf.api + "/doudou/readAct").then(res => {
      if (!res.data.code) {
        dispatch({ type: DOU_CHANNEL, readAct: res.data.data });
      }
    });
  };
}

export function huizhangRec() {
  return dispatch => {
    Axios.post(Conf.api + "/doudou/huizhangRec").then(res => {
      if (!res.data.code) {
        dispatch({ type: DOU_CHANNEL, huizhangRec: res.data.data });
      }
    });
  };
}