const indexInit = {

}
const article = (state = indexInit, action) => {
  switch (action.type) {
    case "ARTICLE_LST":
      return Object.assign({}, state, {
        ...action
      });
    case "ARTICLE_TYPE":
      return Object.assign({}, state, {
        ...action
      });
    case "ARTICLE_INFO":
      return Object.assign({}, state, {
        ...action
      });
      case "XIUSHEN":
        return Object.assign({}, state, {
          ...action
        });
      case "CONTACT":
        return Object.assign({}, state, {
          ...action
        });
    default:
      return state;
  }
}

export default article