import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/salon";
import PageBox from "../public/index";
import { Link } from 'react-router-dom'
import { Row, Col, Breadcrumb, Button } from "antd";
import RightNews from "./right";
import QRCode from 'qrcode.react'
import FUNC from 'common/public'

class HotNews extends Component {
  constructor() {
    super()
    this.state = {
      myurl: null
    }
  }
  componentDidMount() {
    let id = this.props.match.params.id;
    this.props.article({ id });
    let obj = { id: id }
    obj['limit'] = 10;
    this.props.users(obj)
    let uid = localStorage.getItem("uid")
    //设置二维码地址
    let myurl=window.location.href;
    if (uid) {
      if(!myurl.includes('?uid')){
        myurl+=`?uid=${uid}`
      }
    }
    this.setState({ myurl})
    //设置cookie供注册使用，如果此uid对应有推广码，则有推广功能
    FUNC.setPromoter()
  }

  componentDidUpdate(props){
    let id = this.props.match.params.id;
    let id2=props.match.params.id;
    let obj={id:id}
    obj['limit']=10;
    if(id!==id2){
        this.props.article({id})
        this.props.users(obj)
      }
      let { article } = this.props.salon;
    if(props.salon.article!==article && article){
      document.title=article.salon_title+' - '+this.props.index.siteInfo.sitename;
    }
  }

  render() {
    let { article, users } = this.props.salon;
    let { myurl } = this.state;
    // let id = this.props.match.params.id;

    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/salon">活动沙龙</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>沙龙详情</Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col md={17} xs={24}>
            <div className="main">
              {article ? (
                <div className="article">
                  <h1>{article.salon_title}</h1>
                  <div>
                    <div className="cpTimes">
                      <div>
                        举办时间：{article.salon_begin} - {article.salon_end}
                      </div>
                      <div>
                        报名时间：{article.salon_bm_begin} -{" "}
                        {article.salon_bm_end}
                      </div>
                      <div>
                        地点：{article.salon_city} {article.salon_addr}
                      </div>
                      <div>
                        已报名人数：{article.count}
                      </div>
                      <div>
                        <span style={{paddingRight:5}}>报名费用：{article.salon_price ? article.salon_price : '免费'}</span>
                        
                        {article.dead ? (
                          <Button disabled>
                            不在报名时间范围内
                          </Button>
                        ) : (
                            article.signed ?
                              <Button disabled>已经报名成功</Button>
                              : <Button onClick={() => { this.props.sign({ id: article.salon_id }) }}>立即报名</Button>
                          )}
                      </div>
                      <div style={{paddingTop:20}}>
                        <p>简介：</p>
                        {article.salon_desp.split('\n').map(ele2=>(
                        <p>{ele2}</p>
                  ))}
                      </div>
                    </div>
                  </div>
                  <div className="comp-content">
                    <h2>活动详情</h2>
                    <div
                      className="article-content"
                      dangerouslySetInnerHTML={{
                        __html: article.salon_content
                      }}
                    ></div>
                  </div>

                  {myurl ?
                    <div className="mytuiguang" style={{ textAlign: 'center' }}>
                      <QRCode value={myurl} />
                      <div>本活动二维码</div>
                    </div> : null}
                  <div className="actusers">
                    <h2>已报名用户</h2>
                    <div className="userlst">
                      {users ?
                        users.map(ele => (
                          <img src={ele.user_thumb?ele.user_thumb:require('common/img/avatar.png')} alt="用户头像" key={ele.user_id} />
                        ))
                        : null}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </Col>
          <Col md={7} xs={24}>
            <div className="right">
              <RightNews />
            </div>
          </Col>
        </Row>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
