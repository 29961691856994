import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/user";
import PageBox from "components/public/userIndex";
import { Link } from 'react-router-dom'
import { Breadcrumb, Card, Row, Col, DatePicker, message } from "antd";
import UserPub from '../public'
import UserLeft from '../left'
import moment from 'moment';
import Pickers from 'antd-mobile/lib/date-picker';  // 加载 JS
import List from 'antd-mobile/lib/list'
import 'antd-mobile/lib/date-picker/style/css';
import 'antd-mobile/lib/list/style/css';     // 加载 CSS


const { RangePicker } = DatePicker;

class HotNews extends Component {
  constructor() {
    super()
    this.state = {
    }
  }
  componentDidMount() {
    this.props.getProfitByMonth()
  }

  componentDidUpdate(props) {
    const { profit_month } = this.props.user;
    if (profit_month && props.user.profit_month !== profit_month) {
      this.setState({ ...profit_month })
    }
  }

  onChange = (value) => {
    if (value) {
      let begin = moment(value[0]).format('YYYY-MM-DD')
      let end = moment(value[1]).format('YYYY-MM-DD')
      const params = {
        begin,
        end
      }
      this.props.getProfitByMonth(params)
    }
  }

  onChange2 = (value, type) => {
    const { begin, end } = this.state;
    if (type === 'begin') {
      let begin1 = moment(value).format('YYYY-MM-DD')
      this.setState({ begin: begin1 })
      if (moment(value) > moment(end)) {
        message.info("开始日期不能大于结束日期")
        return false
      }
      const params = {
        begin: value,
        end
      }
      this.props.getProfitByMonth(params)
    } else {
      let end1 = moment(value).format('YYYY-MM-DD')
      this.setState({ end: end1 })
      if (moment(value) < moment(begin)) {
        message.info("结束日期不能小于开始日期")
        return false
      }
      const params = {
        begin,
        end: value
      }
      this.props.getProfitByMonth(params)
    }
  }

  render() {
    const dateFormat = 'YYYY-MM-DD';
    const { begin, end } = this.state;

    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/user">会员中心</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            收益统计
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="user-public">
          <UserPub />
        </div>
        <div className="user-center" id="medalpage">
          <div className="user-left">
            <UserLeft path={this.props.history.location.pathname} />
          </div>
          <div className="user-right">
            <div className="main">
              <div className="zongshouyi">
                <Row gutter={16}>
                  <Col span={12}>
                    <Card title="总现金收益">
                      <span>0</span>
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card title="总旗币收益">
                      <span>0</span>
                    </Card>
                  </Col>
                </Row>
              </div>
              <div className="countShouyi">
                <div className="title">按指定时间统计</div>
                <div className="dateSelect">
                  {begin && end ?
                    <RangePicker
                      defaultValue={[moment(begin, dateFormat), moment(end, dateFormat)]}
                      format={dateFormat}
                      onChange={this.onChange}
                    /> : null}
                </div>
                <div className="dateSelectMobile">
                  {begin ?
                    <Pickers
                      mode="date"
                      title="选择日期"
                      locale={{ okText: '确定', dismissText: '取消' }}
                      value={new Date(begin)}
                      extra="选择"
                      format={dateFormat}
                      onChange={(value) => { this.onChange2(value, 'begin') }}
                    >
                      <List.Item arrow="horizontal">开始日期</List.Item>
                    </Pickers> : null}
                  {end ?
                    <Pickers
                      mode="date"
                      title="选择日期"
                      value={new Date(end)}
                      extra="选择"
                      format={dateFormat}
                      onChange={(value) => { this.onChange2(value, 'end') }}
                    >
                      <List.Item arrow="horizontal">结束日期</List.Item>
                    </Pickers> : null}
                </div>
                <div className="optionSelect">
                  <Card bordered={false}>
                    <dl>
                      <dt>现金收益</dt>
                      <dd>0</dd>
                    </dl>
                  </Card>
                  <Card bordered={false}>
                    <dl>
                      <dt>旗币收益</dt>
                      <dd>0</dd>
                    </dl>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
