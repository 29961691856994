import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/competition";
import PageBox from "components/public/index";
import {Breadcrumb} from 'antd'
import {Link} from 'react-router-dom'

class ArticlePage extends Component {

componentDidMount(){
  let { id } = this.props.match.params;
  this.props.report({id})
}

componentDidUpdate(props){
  let { id } = this.props.match.params;
  if(id && id!==props.match.params.id){
    this.props.report({ id })
  }

  let { report } = this.props.competition;
    if (props.competition.report !== report && report) {
      document.title = report.report_title + ' - ' + this.props.index.siteInfo.sitename;
    }
}

  render() {
    let { id } = this.props.match.params;
    let { report } = this.props.competition;
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/comreportlst/${id}`}>赛事报道列表</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="comp-content">
        <h2>赛事报道</h2>
         {report?
         <div>
           <h1 style={{textAlign:'center'}}>{report.report_title}</h1>
         <div style={{textAlign:'center',paddingTop:15,paddingBottom:30}}>{report.report_datetime}</div>
         <div dangerouslySetInnerHTML={{__html:report.report_content}}></div>
         </div>
         :null}
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(ArticlePage);
