import React from "react";
import BannerAnim, { Element } from 'rc-banner-anim';
import 'rc-banner-anim/assets/index.css';
import './banner.less'

const BgElement = Element.BgElement;
export default (props) => {
  let thumbs=props.data;
  let all=thumbs.split(',')
return (
  <BannerAnim prefixCls="banner-user" autoPlay arrow={false}>
    {all.map((ele,index) => (
      <Element prefixCls="banner-user-elem" key={`banner-${index}`}>
        <BgElement
          key={`bannerBg-${index}`}
          className="bg"
          style={{
            backgroundImage: `url(${ele})`
          }}
        />
      </Element>
    ))}
  </BannerAnim>
);
}
