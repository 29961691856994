import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/merchant";
import PageBox from "../public/index";
import { HashRouter, Link } from 'react-router-dom'
import { Breadcrumb, Row, Col, Button, Card,Rate} from "antd";
import qs from 'qs'
import Commonts from './comments'


class Merchant extends Component {

  componentDidMount() {
    let { id } = this.props.match.params
    if (!id) {
      let arr = qs.parse(window.location.href.split("?")[1])
      id = arr.id
      if (id) {
        let router = new HashRouter()
        router.history.push("/merchant/" + id)
      }
    }
    this.props.getMerchant({ id })

  }

  comment = () => {
    const { id } = this.props.match.params
    let uid = localStorage.getItem("uid")
    if (uid) {
      let router = new HashRouter()
      router.history.push('/mch_comment_form/' + id)
    } else {
      this.props.showDialog('login')
    }
  }


  render() {
    let { merchant } = this.props.merchant;
    let { id } = this.props.match.params
    return (
      <PageBox>
        <div className="main">
          <Breadcrumb>
            <Breadcrumb.Item>
              <a href="/">首页</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/merchants">商家营</Link>
            </Breadcrumb.Item>
            {merchant ?
              <Breadcrumb.Item>
                {merchant.me_title}
              </Breadcrumb.Item>
              : null}
          </Breadcrumb>
          <Row gutter={16}>
            <Col md={17} xs={24}>
              <div className="main">
                {merchant ?
                  <div className="merchant">
                    <dl>
                      <dt>{merchant.me_title}</dt>
                      <dd><Rate disabled defaultValue={0}/>  <span>{merchant.merchant_count} 条评价</span> </dd>
                      <dd>联系电话：{merchant.me_tel?merchant.me_tel:'暂无电话'}</dd>
                      <dd>联系地址：{merchant.me_addr?merchant.me_addr:'暂无地址'}</dd>
                      <dd>营业时间：{merchant.me_yytime}</dd>
                    </dl>
                    <Button type="primary" onClick={this.comment}>写评价</Button>
                  </div>
                  : null}
                  <div className="mchComments">
                    <Commonts data={{id}}/>
                  </div>
                {/* {comments ?
                  <div className="mchComments">
                    <ul>
                      {comments.map(ele => (
                        <li>
                          <div className="avatar"></div>
                          <dl>
                            <dt></dt>
                            <dd className="star"></dd>
                            <dd className="comment">{ele.desp}</dd>
                            <dd className="datetime">{ele.time}</dd>
                            <dd className="album"></dd>
                          </dl>
                        </li>
                      ))}
                    </ul>
                  </div>
                  : <Card style={{ marginTop: 15 }}>暂无评论</Card>
                } */}
              </div>
            </Col>
            <Col md={7} xs={24}></Col>
          </Row>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(Merchant);
