import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/promote";
import PageBox from "components/public/userIndex";
import {Breadcrumb, Card } from "antd";
import UserPub from "../public";
import UserLeft from "../left";

class LearnRecord extends Component {
  componentDidMount() {
    let uid = localStorage.getItem("uid");
    this.props.getqrbg({ uid })
    this.props.createQrcode({ uid })
  }

  render() {
    let { userInfo, myQrcodes, qrcodeBg } = this.props.user;

    let promoterLink = ""
    if (userInfo) {
      promoterLink = "http://www.qiniangniang.com/#/reg/" + userInfo.user_code;
    }
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            会员中心
          </Breadcrumb.Item>
          <Breadcrumb.Item>我的分享</Breadcrumb.Item>
        </Breadcrumb>
        <div className="user-public">
            <UserPub />
          </div>
        <div className="user-center" id="medalpage">
          <div className="user-left">
            <UserLeft path={this.props.history.location.pathname} />
          </div>
          <div className="user-right">
            <div className="main">
              <Card title="我的分享" size="small">
                {promoterLink ?
                  <div style={{ textAlign: 'center' }}>
                    {myQrcodes?
                    <img src={myQrcodes.org_code} alt="推广码"/>
                    :"暂无推广码"}
                    <h4 style={{ paddingBottom: 15 }}>点击下面背景生成专属分享海报</h4>
                    {qrcodeBg ?
                      <ul className="qrbg">
                        {qrcodeBg.map(ele => (
                          <li key={ele.bg_id} onClick={() => { this.props.createQrcode({bg_id: ele.bg_id }) }}><img src={ele.bg_thumb} alt="背景" /></li>
                        ))}
                      </ul>
                      : null}
                    {myQrcodes ?
                      <img src={myQrcodes.promote_pic} alt="分享二维码" style={{ width: 300 }} />
                      : null}
                    <p>分享链接：{promoterLink}</p>
                  </div>
                  : <div style={{ textAlign: 'center' }}>升级为VIP会员，获取分享权限</div>}
              </Card>
            </div>
          </div>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(LearnRecord);
