import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/index";
import { Link } from 'react-router-dom';
class PublicIndex extends Component {

  render() {
    let { siteInfo } = this.props.index;
    return <div id="logo">
      <a href="/">
        <img src={require('common/img/logo.png')} className="logo" alt="qnn LOGO" />
      </a>
      <span className="red" style={{ paddingLeft: 5 }}>{siteInfo && siteInfo.city ? siteInfo.city : '总站'}</span>
      <Link to="/changecity" style={{ paddingLeft: 5 }}>[选择]</Link>
    </div>
  }
}



export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(PublicIndex);
