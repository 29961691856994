import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions";
import { Radio, Form } from "antd"


class UserForm extends Component {
  constructor() {
    super()
    this.state = {
      value: null
    }
  }

  componentDidMount() {
    let { item } = this.props;
    this.setState({ value: item.value })
    this.props.onChange(item.field, item.value)
  }

  handleClick = (e) => {
    e.target.focus();
  }

  handleChange = (field, value) => {
    this.setState({ value })
    this.props.onChange(field, value)
  }

  render() {
    let { item,index } = this.props;

    return <Form.Item
      label={item.title}
      key={item.field}
      name={item.field}
    >
      <Radio.Group tabIndex={index}>
        {item.options.map(ele3 => (
          <Radio value={ele3} key={ele3}>{ele3}</Radio>
        ))}
      </Radio.Group>
    </Form.Item>
  }
}

export default connect(state => state, dispatch => bindActionCreators(actionsCreators, dispatch))(UserForm);