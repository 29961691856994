const indexInit = {

}
const topic = (state = indexInit, action) => {
  switch (action.type) {
    case "TOPIC_LST":
      return Object.assign({}, state, {
        ...action
      });
    case "TOPIC_INDEX":
      return Object.assign({}, state, {
        ...action
      });
    case "TOPIC_REC":
      return Object.assign({}, state, {
        ...action
      });
    case "TOPIC_COMMENT":
      return Object.assign({}, state, {
        ...action
      });
      case "TOPIC_USER_LST":
        return Object.assign({}, state, {
          ...action
        });
        case "TOPIC_TYPE":
          return Object.assign({}, state, {
            ...action
          });
    default:
      return state;
  }
}

export default topic