import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/competition";
import PageBox from "../public/index";
import { HashRouter, Link } from 'react-router-dom'
import { Row, Col, Breadcrumb, Button, Tag } from "antd";
import RightNews from "./right";
import FUNC from 'common/public'
import HotUser from './hotUser'
import Navs from './nav'
import QRCode from 'qrcode.react'

class HotNews extends Component {
  constructor() {
    super()
    this.state = {
      myurl: null
    }
  }

  baoming=()=>{
    let id = this.props.id;
    let uid = localStorage.getItem("uid")
    if (uid) {
      this.props.baoming({ id })
    } else {
      this.props.showDialog('login')
    }
  }

  componentDidMount() {
    FUNC.setPromoter()
    let obj = this.getArticle()
    this.props.article(obj);
  }

  getArticle=(aid = '') =>{
    let id = aid || this.props.id;
    let uid = localStorage.getItem("uid")
    let obj = { id: id }
    if (uid) {
      obj['uid'] = uid;
    }
    //设置二维码地址
    let myurl = window.location.href;
    if (uid) {
      if (!myurl.indexOf('?uid') >= 0) {
        myurl += `?uid=${uid}`
      }
    }
    this.setState({ myurl })
    return obj;
  }

  componentDidUpdate(props) {
    if (this.props.id !== props.id && this.props.id) {
      let obj = this.getArticle(this.props.id)
      this.props.article(obj);
    }

    let { article } = this.props.competition;
    if (props.competition.article !== article && article) {
      document.title = article.cp_title + ' - ' + this.props.index.siteInfo.sitename;
    }
  }

  wanshan=(id)=>{
    let router=new HashRouter()
    router.history.push(`/competition-user-form/${id}`)
  }

  myHomePage=(id,uid)=>{
    let router=new HashRouter()
    router.history.push(`/cpuser/${id}/${uid}`)
  }

  render() {
    let { article } = this.props.competition;
    let id = this.props.id;
    let uid = localStorage.getItem("uid")
    let link = this.props.link
    let { myurl } = this.state;

    //报名按钮
    let baoming = null;
    if (article) {
      if (article.bmButton.status === 1) {
        baoming = <Button type="primary" shape="round" onClick={() => { this.props.createOrder({ id: id, type: 4 }) }}>选手报名</Button>
      } else if (article.bmButton.status === 2) {
        baoming = <Button  shape="round" onClick={()=>{this.myHomePage(id,uid)}}>我的报名主页</Button>
      } else if (article.bmButton.status === 3){
        baoming = <Button  shape="round" onClick={()=>{this.wanshan(id)}} style={{color:'red'}}>待完善报名信息</Button>
      } else {
        baoming = <Button  shape="round" disabled>{article.bmButton.msg}</Button>
      }
    }
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/competition">赛事</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>赛事详情</Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={{ md: 16, xs: 0 }} style={{ marginTop: 15 }}>
          <Col md={17} xs={24}>
            <div className="content-body">
              <Navs link={link} id={id} />
              {article ? (
                <div className="article" style={{ paddingTop: 30 }}>
                  <h1>{article.cp_title}</h1>
                  <div className="article-date">
                    {article.cp_author ?
                      <span>来源：{article.cp_author}</span>
                      : null}
                    {article.cp_datetime ?
                      <span>发布时间：{article.cp_datetime}</span>
                      : null}
                  </div>
                  {!article.cp_link ? (
                    <div>
                      <div style={{ textAlign: "right", paddingBottom: 15 }} className="baomingBtn">
                        {baoming}
                        {article.need_ticket ? <Button type="default"><Link to={`/cpticket/${id}`}>线下购票</Link></Button> : null}
                      </div>
                      <div className="cpTimes">
                      <div>
                          报名时间：{article.cp_sign_begin} -{" "}
                          {article.cp_sign_end}
                        </div>
                        <div>
                          线上赛事时间：{article.cp_begin} - {article.cp_end}
                        </div>
                        {article.cp_begin_offline?
                        <div>
                          线下赛事时间：{article.cp_begin_offline} - {article.cp_end_offline}
                        </div>:null}
                       
                        {article.cp_address ?
                          <div>地址：{article.cp_city} {article.cp_address}</div>
                          : null}
                        <div>
                          赛事方式：{article.cp_is_online ? <Tag color="#f50">线上赛事（{parseFloat(article.cp_price) ? '收费' : '免费'}）</Tag> : null}
                          {article.cp_is_offline ? <Tag color="#87d068">线下赛事（{article.cp_price_offline!=='0.00' ? article.cp_price_offline : '免费'}）</Tag> : null}</div>
                      </div>
                    </div>
                  ) : null}
                  {this.props.children}
                </div>
              ) : null}
              {myurl ?
                <div className="mytuiguang" style={{ textAlign: 'center' }}>
                  <QRCode value={myurl} />
                  <div>本赛事二维码</div>
                </div> : null}
            </div>
          </Col>
          <Col md={7} xs={24}>
            <div className="right">
              <HotUser id={id} />
              <RightNews />
            </div>
          </Col>
        </Row>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
