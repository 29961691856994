import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/index";
import {Spin} from 'antd'

import SiteTop from "./Top";
import SiteMenu from "./Menu";
import SiteSearch from "./TopSearch";
import Footer from "./Footer";

class PublicIndex extends Component {

  componentDidMount() {
    
  }

  render() {
    let {siteMenu,siteInfo}=this.props.index;
    if(siteMenu && siteInfo){
     return (
       <div className="site-box">
         <SiteTop data={siteMenu}/>
         <SiteSearch />
         <SiteMenu data={siteMenu}/>
         <div className="site-body">
           <div className="wrapper">{this.props.children}</div>
         </div>
         <Footer data={siteMenu} siteInfo={siteInfo}/>
       </div>
     );
    }else{
      return <div className="flexCenter"><Spin tip="loading..."/></div>
    }
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(PublicIndex);
