
import moment from "moment";
import "moment/locale/zh-cn";
/*计算日期差*/
export function difTime(end) {
    let dif = moment(end).diff(moment(), 'minutes')
    let days = Math.floor(dif / (24 * 60))
    let hours = Math.floor(dif / 60) - days * 24
    let minutes = dif % 60
    return `${days}天${hours}小时${minutes}分后结束`
  }


  export function difSeconds(start, end) {
    let dif = end ? moment(end).diff(moment(), 'seconds') : moment(start).diff(moment(), 'seconds')
    if (dif < 0) return 'over';
    let days = Math.floor(dif / (24 * 60 * 60))
    let hours = Math.floor(dif / (60 * 60)) - days * 24
    let minutes = Math.floor(dif / 60) - days * 24 * 60 - hours * 60
    let seconds = dif % 60
    return `${days} 天 ${hours} 小时 ${minutes} 分 ${seconds} 秒`
  }
  
  /*计算时间差*/
  export function timeOut(end) {
    if (moment(end).diff(moment()) < 0) {
      return true
    }
    return false
  }
  
  /*在某段时间内*/
  export function timeIn(s, e) {
    let t = moment().format('YYYY-MM-DD')
    if (moment(t + ' ' + s).diff(moment()) < 0 && moment(t + ' ' + e).diff(moment()) > 0) {
      return true
    }
    return false
  }
  
  /** 在某段时间内 */
  export function timeInByDate(start, end) {
    if (moment(start).diff(moment()) < 0 && moment(end).diff(moment()) > 0) {
      return true
    }
    return false
  }
  
  /**
   * @param  {} timestamp: 时间戳：毫秒、December 17, 1995 03:24:00、1995-12-17T03:24:00
   */
  export function formatTime(timestamp) {
    // var date1 = new Date('December 17, 1995 03:24:00');
    // var date2 = new Date('1995-12-17T03:24:00');
    let date = new Date(timestamp);
    var mon = date.getMonth() + 1;
    var day = date.getDate();
    return {
      year: date.getFullYear(),
      month: mon < 10 ? '0' + mon : mon,
      day: day < 10 ? '0' + day : day,
      hour: date.getHours(),
      min: date.getMinutes(),
      sec: date.getSeconds()
    }
  }
  
  /** 倒计时 */
  export function untilStart(start) {
    return moment(start).diff(moment(), 'seconds')
  
  }



  export default {
    difSeconds:difSeconds
  }