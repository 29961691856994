import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions";
import { Select, Form } from "antd"
const {Option}=Select


class UserForm extends Component {
  constructor() {
    super()
    this.state = {
      value: null
    }
  }

  componentDidMount() {
    let { item } = this.props;
    this.setState({ value: item.value })
    this.props.onChange(item.field, item.value)
  }

  handleClick = (e) => {
    e.target.focus();
  }

  handleChange = (value) => {
    let { item } = this.props;
    this.setState({ value })
    this.props.onChange(item.field, value)
  }

  render() {
    let { item,index } = this.props;

    return <Form.Item
      label={item.title}
      key={item.field}
      name={item.field}
      rules={[
        {
          required: !!item.required,
          message: "请选择" + item.title + "!"
        }
      ]}
    >
      {item.options ? (
        <Select style={{ width: "100%" }}
          tabIndex={index}
          onChange={this.handleChange}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          showSearch
          disabled={!!item.disabled}
        >
          <Option value="">不限</Option>
          {item.options.map(item2 => (
            <Option value={item2.value} key={item2.value} disabled={item2.disabled ? true : false}>
              {item2.title}
            </Option>
          ))}
        </Select>
      ) : (
          <Select style={{ width: "100%" }} tabIndex={index}>
            <Option value="">不限</Option>
          </Select>
        )}
    </Form.Item>
  }
}

export default connect(state => state, dispatch => bindActionCreators(actionsCreators, dispatch))(UserForm);