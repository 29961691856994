import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/competition";
import Box from '../article'

class ArticlePage extends Component {

  componentDidMount() {
    let { id } = this.props.match.params;
    this.props.pingwei({ id })
  }


componentDidUpdate(props){
  let { id } = this.props.match.params;
  if(id && id!==props.match.params.id){
    this.props.pingwei({ id })
  }
}

  render() {
    let { id } = this.props.match.params;
    let { pingwei } = this.props.competition;
    return (
      <Box id={id} link={'judgy'}>
        <div className="comp-content">
          <h2>评委介绍</h2>
          <ul className="pingwei">
          {pingwei ?
            pingwei.map(ele => (
              <li key={ele.pw_name}>
                <img src={ele.pw_thumb} alt="头像" />
                <dl>
                  <dt>{ele.pw_name}</dt>
                  {ele.pw_desp.split('\n').map(ele2=>(
                    <dd>{ele2}</dd>
                  ))}
                </dl>
              </li>
            ))
            : null}
            </ul>
        </div>
      </Box>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(ArticlePage);
