const indexInit = {

}
const order = (state = indexInit, action) => {
  switch (action.type) {
    case "ORDER_LST":
      return Object.assign({}, state, {
        ...action
      });
    case "ORDER_INFO":
      return Object.assign({}, state, {
        ...action
      });
    case "PAY_URL":
      return Object.assign({}, state, {
        ...action
      });
    //
    case "ORDER_TYPE_LST":
      return Object.assign({}, state, {
        ...action
      });
    default:
      return state;
  }
}

export default order