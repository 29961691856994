import Type from "./type";

import Conf from "../common/config";
import FUNC from 'common/public'
import { message } from "antd";
import {wallet} from './user'
import {createPublicOrder} from './order'
import Axios from './request'
import {HashRouter} from 'react-router-dom'
const {PAGE_AD,ACTIVE_MENU, COMPETITION_LST,COMPETITION_HOT_USERS,COMPETITION_USER,GLOBAL_SHOW_MODAL,CP_RECORD,CITY_LST,COMPETITION_MENU,CP_TICKETS,COMPETITION_ARTICLE,REPORT_ARTICLE,CP_RANK,COMPETITION_GIFTS} = Type;

export function setActiveUrl(page){
  return({type:ACTIVE_MENU,activeUrl:page})
}

/**
 * 赛事列表页面广告
 * @param {*} arg 
 */
export function getAds(arg){
  return dispatch => {
    Axios.get(Conf.api + "/banner/lst?type="+arg).then(res => {
      if (!res.data.code) {
        dispatch({ type: PAGE_AD, cpLstAds: res.data.data });
      }
    });
  };
}

/**
 * 赛事报道
 * @param {*} args 
 */
export function reportArticle(args){
  return dispatch => {
    Axios.post(Conf.api + "/report/lst", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: COMPETITION_ARTICLE, competitionReport: res.data.data });
      }
    });
  };
}


/**
 * 获取赛事有哪些选项
 * id
 */
export function getCpMenu(args){
  return dispatch => {
    Axios.post(Conf.api + "/competition/menu", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: COMPETITION_MENU, competitionMenu: res.data.data });
      }
    });
  };
}

/**
 * 列表
 * @param {*} args 
 */
export function lst(args) {
  return dispatch => {
    Axios.post(Conf.api + "/competition/lst", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: COMPETITION_LST, competitionLst: res.data.data });
      }
    });
  };
}


/**
 * 人气选手
 * @param {*} args 
 */
export function daka(args) {
  return dispatch => {
    Axios.post(Conf.api + "/cp_user/daka", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: COMPETITION_LST, daka: res.data.data });
      }
    });
  };
}
/**
 * 评委列表 {id}
 * @param {*} args 
 */
export function pingwei(args) {
  return dispatch => {
    Axios.post(Conf.api + "/pingwei/lst", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: COMPETITION_LST, pingwei: res.data.data });
      }
    });
  };
}
/**
 * 新闻列表推荐新闻
 * @param {*} args 
 */
export function rec(args) {
  return dispatch => {
    Axios.post(Conf.api + "/competition/rec", {
      data: args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: COMPETITION_LST, competitionRec: res.data.data });
      }
    });
  };
}

/**
 * 文章详情
 * @param {*} args 
 */
export function article(args) {
  return dispatch => {
    dispatch({ type: COMPETITION_LST, article: null });
    Axios.post(Conf.api + "/competition/index",{
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: COMPETITION_LST, article: res.data.data });
      }
    });
  };
}

/**
 * 文章详情
 * @param {*} args 
 */
export function report(args) {
  return dispatch => {
    dispatch({ type: REPORT_ARTICLE, report: null });
    Axios.post(Conf.api + "/report/index",{
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: REPORT_ARTICLE, report: res.data.data });
      }
    });
  };
}


/**
 * 赛事人气选手
 * @param {*} id 
 */
export function cpHotUsers(id) {
  return dispatch => {
    dispatch({ type: COMPETITION_HOT_USERS, article: null });
    Axios.post(Conf.api + "/cp_user/hotusers",{
      id:id
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: COMPETITION_HOT_USERS, cpHotUsers: res.data.data });
      }
    });
  };
}
/**
 * 选手列表
 * @param {*} {id} 赛事
 */
export function cpusers(args) {
  return dispatch => {
    dispatch({ type: COMPETITION_HOT_USERS, article: null });
    Axios.post(Conf.api + "/cp_user/userlst",args).then(res => {
      if (!res.data.code) {
        dispatch({ type: COMPETITION_HOT_USERS, cpusers: res.data.data });
      }
    });
  };
}


/**
 * 参赛用户主页
 * @param {*} data  id赛事ID,uid用户ID
 */
export function cpUser(data) {
  return dispatch => {
    Axios.post(Conf.api + "/competition/cpuser",{
      ...data
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: COMPETITION_USER, cpUserData: res.data.data });
      }
    });
  };
}

/**
 * 此用户收到的礼物
 * @param {*} data 
 */
export function mygifts(data) {
  return dispatch => {
    Axios.post(Conf.api + "/gift_record/gifts",{
      ...data
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: COMPETITION_GIFTS, mygifts: res.data.data });
      }
    });
  };
}

/**
 * 投票 id,uid
 */
export function vote(data) {
  let that=this;
  return dispatch => {
    let uid=localStorage.getItem("uid")
    if(uid){
      data['uid_a']=uid;
    }else{
      that.showDialog('login')
      return false
    }
    Axios.post(Conf.api + "/competition/vote",{
      ...data
    }).then(res => {
      if (!res.data.code) {
        message.info('投票成功')
        window.location.reload()
      }
    });
  };
}

/**
 * 打赏弹窗
 * @param {*} data 
 */
export function dashangDialog(data){
  let that=this;
  return dispatch=>{
    let uid=localStorage.getItem("uid")
    if(uid){
      that.showDialog('dashang',data)
    }else{
      that.showDialog('login')
    }
  }
}


/**
 * 打赏金额提交
 * @param {*} data id,uid
 */
export function dashang(data) {
  let that=this;
  return dispatch => {
    let uid=localStorage.getItem("uid")
    if(uid){
      data['uid_a']=uid;
    }else{
      that.showDialog('login')
      return false
    }
    Axios.post(Conf.api + "/competition/shang",{
      ...data
    }).then(res => {
      if (!res.data.code) {
        message.info('打赏成功')
      }
    });
  };
}

/**
 * 送礼
 * @param {*} data id,uid
 */
export function sendGift(data) {
  let that=this;
  return dispatch => {
    let uid=localStorage.getItem("uid")
    if(uid){
      that.showDialog('gifts',data)
    }else{
      that.showDialog('login')
    }
  };
}


/**
 * 打赏弹窗
 * @param {*} arg =shang
 * 
 */
export function showDialog(arg,param){
  return { type: GLOBAL_SHOW_MODAL, showModal: arg,modalParams:param };
}


/**
 * 获取用户钱包信息
 * @param {*} data 
 */
export function getWallet(data){
  return wallet(data)
}

/**
 * 赛事报名
 * @param {*} data 
 */
export function baoming(data) {
  let that=this;
  return dispatch => {
    let uid=localStorage.getItem("uid")
    if(uid){
      data['uid']=uid;
    }else{
      that.showDialog('login')
      return false
    }
    Axios.post(Conf.api + "/competition/baoming",{
      ...data
    }).then(res => {
      if (!res.data.code) {
        message.info('赠送礼物成功')
      }
    });
  };
}


/**
 * 赛事报名操作
 */
export function cpSignUp(data) {
  return dispatch => {
    Axios.post(Conf.api + "/competition/cpsignup",{
      ...data
    }).then(res => {
      if (!res.data.code) {
        message.info(res.data.msg)
        let router=new HashRouter()
        router.history.push(`/com-users/${data.cpu_cp_fid}`)
      }
    });
  };
}


/**
 * 获取报名记录，填充表单
 */
export function getCpRecord(data){
  return dispatch => {
    Axios.post(Conf.api + "/competition/cprecord",data).then(res => {
      if (!res.data.code) {
        dispatch ({ type: CP_RECORD, cpRecord: res.data.data });
      }
    });
  };
}
//
export function getCitys(){
  return dispatch => {
    Axios.post(Conf.api + "/city/lst").then(res => {
      if (!res.data.code) {
        dispatch ({ type: CITY_LST, cityLst: res.data.data });
      }
    });
  };
}

/**
 * 
 * @param {*} params id
 */
export function tickets(params){
  return dispatch => {
    Axios.post(Conf.api + "/competition/tickets",params).then(res => {
      if (!res.data.code) {
        dispatch ({ type: CP_TICKETS, tickets: res.data.data });
      }
    });
  };
}

/**
 * 排行榜
 */
export function cpRank(args){
  return dispatch => {
    Axios.post(Conf.api + "/competition/rank",args).then(res => {
      if (!res.data.code) {
        dispatch ({ type: CP_RANK, cpRank: res.data.data });
      }
    });
  };
}

/**
 * 选手排行版前3
 * @param {*} args 
 */
export function rankTop3(args){
  return dispatch => {
    Axios.post(Conf.api + "/competition/rank3",args).then(res => {
      if (!res.data.code) {
        dispatch ({ type: CP_RANK, rankTop: res.data.data });
      }
    });
  };
}


/**
 * 生成报名订单
 * @param {*} param 
 */
export function createOrder(param){
  return createPublicOrder(param)
}