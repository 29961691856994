import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/competition";
import Box from '../article'

class ArticlePage extends Component {

  render() {
    let {id}=this.props.match.params;
    let { article } = this.props.competition;
    return (
      <Box id={id}  link={'sponsor'}>
        <div className="comp-content">
        <h2>赞助商</h2>
          {article && article.zanzhu?
          <div
            className="article-content article-zanzhu">
              {article.zanzhu.map((ele,index)=>(
                <div className="zanzhu-item">
                <img src={ele.zz_thumb} alt="赞助" key={index}/>
                <div className="company">{ele.zz_company}</div>
                </div>
              ))}
            </div>
            :null}
        </div>
      </Box>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(ArticlePage);
