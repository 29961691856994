import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/learn";
import PageBox from "../public/index";
import { Link } from 'react-router-dom'
import { Breadcrumb,Button} from "antd";
import {PlayCircleOutlined} from '@ant-design/icons';
import PageAd from './ad/index'

// import { HomeOutlined } from "@ant-design/icons";
class HotNews extends Component {

  componentDidMount() {
    this.props.setActiveUrl('/edu')
    this.props.channel()
    this.props.getAds2(8)
  }

  render() {
    let { learnChannel } = this.props.learn;
    let { eduLstAds } = this.props.index;
    return (
      <PageBox>
        {eduLstAds && eduLstAds.length ?
          <PageAd data={eduLstAds} />
          : null}
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/edu">知识</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="main">
          <div className="channel">
            <ul>
              {learnChannel ? learnChannel.map(ele => (
                <li key={ele.type_id}>
                  <Link to={`/edu/${ele.type_id}`}>
                    <div className="imgbox">
                      <img src={ele.type_thumb ? ele.type_thumb : require('common/img/nopic.png')} alt="缩略图" />
                    </div>
                    <div className="channelInfo">
                      <div className="title">{ele.type_name}</div>
                    </div>
                  </Link>
                  {ele.articles ?
                    <div className="articles">
                      {ele.articles.map(ele2 => (
                        <div className="articleItem" key={ele2.ln_id} style={{ textAlign: 'left' }}><Link to={`/course/${ele2.ln_id}`}><div>{ele2.ln_title && ele2.ln_title.length > 10 ? (ele2.ln_title.substr(0, 10) + '...') : ele2.ln_title}</div><div className="extra">￥{ele2.ln_price} <Button type="link">播放</Button></div></Link></div>
                      ))}
                    </div>
                    : null}
                  <div className="channelmore"><Link to={`/edu/${ele.type_id}`}>更多&gt;&gt;</Link></div>
                </li>
              )) : null}
            </ul>
          </div>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
