import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/index";
import {Card, Tag, Tabs } from "antd";
import moment from "moment";
import "moment/locale/zh-cn";
import { Link } from "react-router-dom";

const { TabPane } = Tabs;
moment.locale("zh-cn");

class CompetitionPage extends Component {
  constructor() {
    super();
    this.onChangeCompetition = this.onChangeCompetition.bind(this);
  }

  onChangeCompetition(key) {
    if(key==='1'){
      this.props.competition();
    }
    //收礼榜
    if(key==='2'){
      this.props.giftRank(0)
    }
    //送礼榜
    if(key==='3'){
      this.props.giftRank(1)
    }
  }

  render() {
      let { competition,giftRank}=this.props.index;

    return (
      <Card
        title="赛事"
        size="small"
        extra={<Link to="/competition">更多</Link>}
        style={{ width: "100%" }}
        className="cardTabsBox"
      >
        <Tabs onChange={this.onChangeCompetition}>
          <TabPane tab="最新赛事" key="1">
            <div className="cardBox">
              {competition
                ? competition.map(ele => (
                    <Link
                      to={`/cominfo/${ele.cp_id}`}
                      key={`competition-${ele.cp_id}`}
                      title={ele.cp_title}
                    >
                      <div>
                        {ele.cp_title.length>15?`${ele.cp_title.substr(0,15)}...`:ele.cp_title}
                      </div>
                      <div className="date">{moment(ele.cp_begin).format("YYYY-MM-DD")}</div>
                    </Link>
                  ))
                : null}
            </div>
          </TabPane>
          <TabPane tab="收礼榜" key="2">
            <div className="cardBox">
              {giftRank
                ? giftRank.map(ele => (
                    <Link
                      to={`/user/${ele.user_id}`}
                      key={`giftRank-${ele.user_id}`}
                    >
                      <div>
                        {ele.user_nickname}
                      </div>
                    </Link>
                  ))
                : null}
            </div>
          </TabPane>
          <TabPane tab="送礼榜" key="3">
            <div className="cardBox">
              {giftRank
                ? giftRank.map(ele => (
                    <Link
                      to={`/user/${ele.user_id}`}
                      key={`giftRank-${ele.user_id}`}
                    >
                      <div>
                        {ele.user_nickname}
                      </div>
                    </Link>
                  ))
                : null}
            </div>
          </TabPane>
        </Tabs>
      </Card>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(CompetitionPage);