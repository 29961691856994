import Type from "./type";
import Conf from "../common/config";
import Axios from './request'
import {message} from 'antd'
import {HashRouter} from 'react-router-dom'

const {
  GLOBAL_SITE_MENU,
  GLOBAL_SITE_INFO,
  GLOBAL_SITE_SEARCH,
  INDEX_BANNER,
  INDEX_AUTHOR,
  INDEX_COMPETITION,
  INDEX_EBOOK,
  INDEX_FLINK,
  INDEX_SALON,
  INDEX_QINIANG,
  INDEX_QIPAO,
  INDEX_RANK,
  INDEX_LEARN,
  INDEX_CONFERENCE,
  GLOBAL_SHOW_MODAL,
  USER_INFO,
  SITE_CITYS,
  INDEX_GIFT_RANK,
  INDEX_CITY,
  INDEX_READER,
  TOGGLE_MENU,
  INDEX_USERCARD,
  SALE_BOOK,
  LEARN_VIDEO,
  USER_LOGIN,
  PAGE_AD,
  FORM_FIELDS
} = Type;

export function logout() {
  return dispatch => {
    localStorage.removeItem("uid")
    dispatch({ type: USER_LOGIN, userInfo: null });
    let router = new HashRouter();
    router.history.push('/')
    window.location.reload()
  };
}


export function getAds(arg){
  return dispatch => {
    Axios.post(Conf.api + "/banner/lst",{type:arg}).then(res => {
      if (!res.data.code) {
        dispatch({ type: PAGE_AD, indexAdCenter: res.data.data });
      }
    });
  };
}

/**
 * 站点搜索
 */
export function siteSearch(keywords){
  return dispatch => {
    Axios.post(Conf.api + "/index/search",{keywords:keywords}).then(res => {
      if (!res.data.code) {
        message.success('查询成功')
        dispatch({ type: GLOBAL_SITE_SEARCH, siteSearch: res.data.data });
      }
    })
  }
}


export function saleBook(){
  return dispatch => {
    Axios.post(Conf.api + "/goods/index",{id:7}).then(res => {
      if (!res.data.code) {
        dispatch({ type: SALE_BOOK, shopBook: res.data.data });
      }
    });
  };
}

/**
 * 网站配置信息
 */
export function siteInfo() {
  return dispatch => {
    Axios.post(Conf.api + "/index/siteInfo").then(res => {
      if (!res.data.code) {
        dispatch({ type: GLOBAL_SITE_INFO, siteInfo: res.data.data });
      }
    });
  };
}

/**
 * 网站菜单
 */
export function siteMenu() {
  return dispatch => {
    Axios.post(Conf.api + "/menu/index").then(res => {
      if (!res.data.code) {
        dispatch({ type: GLOBAL_SITE_MENU, siteMenu: res.data.data });
      }
    });
  };
}

/**
 * 首页banner
 */
export function banner() {
  return dispatch => {
    Axios.post(Conf.api + "/banner/index").then(res => {
      if (!res.data.code) {
        dispatch({ type: INDEX_BANNER, banner: res.data.data });
      }
    });
  };
}

/**
 * 首页赛事
 */
export function competition(arg) {
  return dispatch => {
    Axios.post(Conf.api + "/index/competition", {
      ...arg
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: INDEX_COMPETITION, competition: res.data.data });
      }
    });
  };
}
/**
 * 收礼，送礼用户榜
 * @param {*} arg sign=0,收礼，1=送礼
 */
export function giftRank(sign) {
  return dispatch => {
    dispatch({ type: INDEX_GIFT_RANK, giftRank: null });
    Axios.post(Conf.api + "/index/giftRank", {
      sign:sign
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: INDEX_GIFT_RANK, giftRank: res.data.data });
      }
    });
  };
}
/**
 * 
 * @param arg 类型 2=文章，3=视频音频
 */
export function indexLearn(arg) {
  return dispatch => {
    dispatch({ type: INDEX_LEARN, learn: null });
    Axios.post(Conf.api + "/index/learn", {
      sign: arg
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: INDEX_LEARN, learn: res.data.data });
      }
    });
  };
}

/**
 * 沙龙
 */
export function salon() {
  return dispatch => {
    Axios.post(Conf.api + "/index/indexSalon").then(res => {
      if (!res.data.code) {
        dispatch({ type: INDEX_SALON, salon: res.data.data });
      }
    });
  };
}

/**
 * 发布会
 */
export function conference() {
  return dispatch => {
    Axios.post(Conf.api + "/index/indexFabuhui").then(res => {
      if (!res.data.code) {
        dispatch({ type: INDEX_CONFERENCE, fabuhui: res.data.data });
      }
    });
  };
}

/**
 * 空中书城
 */
export function ebook(arg) {
  return dispatch => {
    dispatch({ type: INDEX_EBOOK, ebook: null });
    Axios.post(Conf.api + "/index/indexBook",{
      ...arg
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: INDEX_EBOOK, ebook: res.data.data });
      }
    });
  };
}
/**
 * 作家
 */
export function author() {
  return dispatch => {
    Axios.post(Conf.api + "/index/indexAuthor").then(res => {
      if (!res.data.code) {
        dispatch({ type: INDEX_AUTHOR, author: res.data.data });
      }
    });
  };
}

/**
 * 排行榜
 */
export function rank() {
  return dispatch => {
    Axios.post(Conf.api + "/index/rank").then(res => {
      if (!res.data.code) {
        dispatch({ type: INDEX_RANK, rank: res.data.data });
      }
    });
  };
}

/**
 * 旗袍圈整个模块的内容
 */
export function qipao() {
  return dispatch => {
    Axios.post(Conf.api + "/index/qipao").then(res => {
      if (!res.data.code) {
        dispatch({ type: INDEX_QIPAO, qipao: res.data.data });
      }
    });
  };
}

/**
 * 娘娘风采
 */
export function qiniang() {
  return dispatch => {
    Axios.post(Conf.api + "/index/qiniang").then(res => {
      if (!res.data.code) {
        dispatch({ type: INDEX_QINIANG, qiniang: res.data.data });
      }
    });
  };
}

/**
 * 城市榜
 */
export function indexCity(arg) {
  return dispatch => {
    Axios.post(Conf.api + "/index/indexCity",{
      ...arg
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: INDEX_CITY, cityRank: res.data.data });
      }
    });
  };
}

/**
 * 读书榜
 */
export function indexReader(arg) {
  return dispatch => {
    Axios.post(Conf.api + "/index/indexReader",{
      ...arg
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: INDEX_READER, indexReader: res.data.data });
      }
    });
  };
}
/**
 * 友情链接
 */
export function flink() {
  return dispatch => {
    Axios.post(Conf.api + "/flink/lst").then(res => {
      if (!res.data.code) {
        dispatch({ type: INDEX_FLINK, flink: res.data.data });
      }
    });
  };
}

export function IndexInit() {
  let that = this;
  return () => {
    that.getAds(9)
    that.flink()
    that.qiniang()
    that.indexReader({sign:1})
    that.author()
    that.conference()
    that.ebook({sign:1})
    that.salon()
    that.indexCity({sign:1})
    that.indexLearn(1);
    that.competition(1);
    that.banner();
    that.qipao()
    that.usercard()
  };
}

/**
 * 首页会员卡介绍内容
 */
export function usercard() {
  return dispatch => {
    Axios.post(Conf.api + "/index/usercard").then(res => {
      if (!res.data.code) {
        dispatch({ type: INDEX_USERCARD, usercard: res.data.data });
      }
    });
  };
}

/**
 * 展示弹窗
 * @param {*} arg 弹窗识别参数
 */
export function showModal(arg,params) {
  return { type: GLOBAL_SHOW_MODAL, showModal: arg,modalParams:params?params:null };
}

/**
 * 用户信息
 */
export function userInfo() {
  return dispatch => {
    Axios.post(Conf.api + "/v2/user/info").then(res => {
      if (!res.data.code) {
        dispatch({ type: USER_INFO, userInfo: res.data.data });
      }
    });
  };
}

/**
 * 获取城市列表
 */
export function getCity() {
  return dispatch => {
    Axios.post(Conf.api + "/city/lst").then(res => {
      if (!res.data.code) {
        dispatch({ type: SITE_CITYS, allCity: res.data.data });
      }
    });
  };
}

/**
 * 手机端菜单切换
 * @param {*} status 
 */
export function toggleMenu(status){
  return({type:TOGGLE_MENU,show_menu:status})
}

export function nextAudio(args) {
  let uid=localStorage.getItem("uid")

  return dispatch => {
    args['uid']=uid;
    Axios.post(Conf.api + "/video/playnext",args).then(res => {
      if (!res.data.code) {
        if(res.data.data&&!res.data.data.video_file){
          message.info('文件不存在')
        }else{
          message.info('请求成功')
        }
        dispatch({ type: LEARN_VIDEO, videoInfo: res.data.data });
      }
    });
  };
}

//关闭音频
export function closeAudio(){
  return({type:LEARN_VIDEO,videoInfo:null})
}


export function getFields(args){
  return dispatch => {
    dispatch({type:FORM_FIELDS,formFields:null})
    Axios.post(Conf.api + "/form/fields", args).then(res => {
      if (!res.data.code) {
        dispatch({type:FORM_FIELDS,formFields:res.data.data})
      }
    })
  }
}

export function submitForm(args){
  return dispatch => {
    Axios.post(Conf.api + "/form/send", args).then(res => {
    })
  }
}


export function mch_shop_apply(args){
  return dispatch => {
    Axios.post(Conf.api + "/merchant_base/mch_shop_apply", args).then(res => {
      if (!res.data.code) {
        window.location.href='/#/order/'+res.data.data.out_trade_no
      }else{
        message.info(res.data.msg)
      }
    })
  }
}
