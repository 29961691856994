import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/user";
import PageBox from "components/public/userIndex";
import {Breadcrumb, Form, Input, Radio, Button } from "antd";
import UserPub from '../public'
import UserLeft from '../left'
import './setting.css'

class HotNews extends Component {
  constructor() {
    super()
    this.onFinish = this.onFinish.bind(this)
  }

  componentDidMount() {
    this.props.setting();
  }

  onFinish = values => {
    this.props.updateSetting(values)
  }

  render() {
    let { setting } = this.props.user;
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            会员设置
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="user-center">
          <div className="user-public">
            <UserPub />
          </div>
          <div className="user-left">
            <UserLeft path={this.props.history.location.pathname} />
          </div>
          <div className="user-right">
            <div className="main">
              {setting?
              <Form
                name="setting"
                layout="vertical"
                initialValues={setting}
                style={{ width: '60%',margin:'0 auto'}}
                onFinish={this.onFinish}
                {...layout}
              >
                <Form.Item
                  label="手机号"
                  name="user_phone"
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  name="user_nickname"
                  label="昵称"
                  rules={[
                    {
                      required: true,
                      message: "请输入昵称!"
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="user_desp"
                  label="会员简介"
                >
                  <Input.TextArea rows={4}/>
                </Form.Item>

                <Form.Item
                  name="user_sex"
                  label="性别"
                >
                  <Radio.Group>
                    <Radio value="男">男</Radio>
                    <Radio value="女">女</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item style={{paddingTop:15,textAlign:'right'}}>
                  <Button type="primary" htmlType="submit">保存</Button>
                </Form.Item>
              </Form>
              :null}
            </div>
          </div>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
