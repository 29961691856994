import  { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/user";

class Login extends Component {
 
  componentDidMount() {
    this.props.logout()
  }

  render() {
    return null
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(Login);
