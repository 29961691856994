import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/competition";
import RcUeditor from 'react-ueditor-wrap';
import {
  Form,
  Input,
  Select,
  Switch,
  Modal,
  message,
  Upload,
  Button,
  Breadcrumb,
  Radio,
  DatePicker
} from "antd";
import PageBox from "../public/index";
import { PlusOutlined } from "@ant-design/icons";
import FUNC from "common/until";
import Conf from "common/config";
import { Link } from 'react-router-dom'
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
const { Option } = Select;
const { TextArea } = Input;

class FormPage extends Component {
  formRef = React.createRef()
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      id:null,
      formPic: {},
      formData: null, //表单数据结构
      previewVisible: false,
      previewImage: "", //预览图像内容
      flag: true,
    };
  }



  handleChangeQuill(field, value) {
    let obj = {}
    obj[field] = value;
    this.formRef.current.setFieldsValue(obj)
  }

  //--------------------图片处理--------
  handleCancel = () => this.setState({ previewVisible: false });
  // 图片预览
  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await FUNC.getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true
    });
  };

  //图片删除，上传
  handleChange = async ({ file, fileList }, field) => {
    //把图片地址存放到上层组件
    if (file.status === "done") {
      this.changePhoto(field, file.uid, file.response);
    }
    if (file.status === "removed") {
      this.changePhoto(field, file.uid, "");
    }
    //本页面的图片展示
    let obj = {};
    obj[field] = fileList;
    this.setState(obj);
  };

  changePhoto = (field, uid, url) => {
    let { formPic } = this.state;
    if (url) {
      if (formPic[field]) {
        formPic[field][uid] = url;
      } else {
        formPic[field] = {};
        formPic[field][uid] = url;
      }
    } else {
      delete formPic[field][uid]
    }
    this.setState({ formPic: formPic });
  }
  //---------------图片处理 end------------------
  /* 普通字段更新 */
  onChange = (field, value) => {
    let { formData } = this.state;
    formData.forEach(ele => {
      if (ele.children) {
        ele.children.forEach(ele2 => {
          if (ele2.field === field) {
            ele2.value = value;
          }
        });
      }
    });
    this.setState({ formData });
  }
  componentDidMount() {
    let uid = localStorage.getItem("uid");
    if (!uid) {
      this.props.showDialog('login')
    } else {
      let { id } = this.props.match.params;
      this.setState({id})
      this.props.getCpRecord({ id })
    }
  }
  componentDidUpdate(props) {
    let that = this;
    let { cpRecord } = this.props.competition;
    if (cpRecord && cpRecord !== props.competition.cpRecord && this.state.flag) {
      /**
       * 存储图片
       */
      let obj = {};
      let forms = cpRecord.data
      forms.forEach(ele2 => {
            //单图
            if ((ele2.type === "photo" || ele2.type === "file") && ele2.value) {
              let uid = "a" + String(FUNC.randomNum(10000, 99999));
              obj[ele2.field] = [
                {
                  uid: uid,
                  status: "done",
                  name: "photo",
                  url: ele2.value
                }
              ];
              that.changePhoto(ele2.field, uid, ele2.value);
            }
            //多图
            if ((ele2.type === "photos" || ele2.type === "files") && ele2.value) {
              let valArr = ele2.value.split(",");
              let photoArr = []
              valArr.forEach(ele3 => {
                let uid = "a" + String(FUNC.randomNum(10000, 99999));
                let obj2 = {
                  uid: uid,
                  status: "done",
                  name: "photo",
                  url: ele3
                };
                photoArr.push(obj2)
                //存到上层组件
                that.changePhoto(ele2.field, uid, ele3);
              });
              //存到本组件state
              obj[ele2.field] = photoArr;
            }
        })
      this.setState(obj);
      this.setState({ formData: forms, flag: false });
    }

  }


  onFinish = (values) => {
    let pics = this.state.formPic;
      let picReset = FUNC.getStatePhotos2(pics);
      if(!picReset['cpu_xing_thumb']){
        message.info('形象照不能为空')
      }else if(!picReset['cpu_thumbs']){
        message.info('参赛作品不能为空')
      }else if(values['cpu_xuanyan'].length<3 || values['cpu_xuanyan'].length>15){
      message.info('参赛宣言内容长度为3-15个字符')
    }else if(values['cpu_content'].length<30 || values['cpu_content'].length>500){
      message.info('自我介绍内容长度为30-500个字符')
    }else{
      let formatValues = FUNC.formatSubmit(values);
      let submitValues = { ...formatValues, ...picReset };
      submitValues['cpu_cp_fid']=this.state.id;
      this.props.cpSignUp(submitValues);
    }

  }



  render() {
    let { formData, previewVisible, previewImage } = this.state;
    let { cpRecord } = this.props.competition;

    const state = this.state;
    return <PageBox>
      <Breadcrumb style={{ paddingTop: 20 }}>
        <Breadcrumb.Item>
          <a href="/">首页</a>
        </Breadcrumb.Item>
        {cpRecord ?
          <Breadcrumb.Item>
            <Link to={`/cominfo/${cpRecord.competition.cp_id}`}>{cpRecord.competition.cp_title}</Link>
          </Breadcrumb.Item>
          :
          <Breadcrumb.Item>
            <Link to="/cominfo/">赛事</Link>
          </Breadcrumb.Item>
        }

        <Breadcrumb.Item>个人展示主页编辑</Breadcrumb.Item>
      </Breadcrumb>
      <div className="main">
        
        {formData ?
          <Form onFinish={this.onFinish} initialValues={FUNC.formatInitial(cpRecord.data)} ref={this.formRef} name="cpform">
            {formData.map(item => {
              if (item.type === "number") {
                return (
                  <Form.Item
                    label={item.title}
                    key={item.field}
                    name={item.field}
                    rules={[
                      {
                        required: !!item.required,
                        message: "请输入" + item.title + "!"
                      }
                    ]}
                  >
                    <Input
                      placeholder={item.placeholder}
                      disabled={!!item.disabled}
                      onClick={(e)=>{e.target.focus()}}
                    />
                  </Form.Item>
                );
              } else if (item.type === "text") {
                return (
                  <Form.Item
                    label={item.title}
                    key={item.field}
                    name={item.field}
                    rules={[
                      {
                        required: !!item.required,
                        message: "请输入" + item.title + "!"
                      }
                    ]}
                  >
                    <Input
                      placeholder={item.placeholder}
                      disabled={!!item.disabled}
                      onClick={(e)=>{e.target.focus()}}
                    />
                  </Form.Item>
                );
              } else if (item.type === "radio") {
                return (
                  <Form.Item
                    label={item.title}
                    key={item.field}
                    name={item.field}
                  >
                    <Radio.Group>
                      {item.options.map(ele3 => (
                        <Radio value={ele3} key={ele3}>{ele3}</Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                );
              } else if (item.type === "select") {
                return (
                  <Form.Item
                    label={item.title}
                    key={item.field}
                    name={item.field}
                    rules={[
                      {
                        required: !!item.required,
                        message: "请选择" + item.title + "!"
                      }
                    ]}
                  >
                    {item.options ? (
                      <Select style={{ width: "100%" }} filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      } showSearch>
                        {item.options.map(item2 => (
                          <Option value={item2.value} key={item2.value} disabled={item2.disabled ? true : false}>
                            {item2.title}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                        <Select style={{ width: "100%" }}>
                          <Option value="0">不限</Option>
                        </Select>
                      )}
                  </Form.Item>
                );
              } else if (item.type === "date") {
                return (
                  <Form.Item
                    label={item.title}
                    key={item.field}
                    name={item.field}
                    rules={[
                      {
                        required: !!item.required,
                        message: "请输入" + item.title + "!"
                      }
                    ]}
                  >
                    <DatePicker
                      placeholder={item.placeholder}
                      disabled={!!item.disabled}
                    />
                  </Form.Item>
                );
              } else if (item.type === "datetime") {
                return (
                  <Form.Item
                    label={item.title}
                    key={item.field}
                    name={item.field}
                    rules={[
                      {
                        required: !!item.required,
                        message: "请输入" + item.title + "!"
                      }
                    ]}
                  >
                    <DatePicker
                      placeholder={item.placeholder}
                      showTime
                      disabled={!!item.disabled}
                    />
                  </Form.Item>
                );
              } else if (item.type === "switch") {
                return (
                  <Form.Item
                    label={item.title}
                    key={item.field}
                    name={item.field}
                  >
                    <Switch
                      checked={item.value ? true : false}
                      onChange={e => {
                        this.onChange(item.field, e);
                      }}
                      disabled={!!item.disabled}
                    />
                  </Form.Item>
                );
              }
              return null;
            })}
            <div className="formThumb">
              {formData && formData.map(item => {
                if (item.type === "photo" || item.type === "file") {
                  return (
                    <div key={item.field} className="thumbBox">
                      <Upload
                        action={Conf.api + "/public_upload/upload"}
                        listType="picture-card"
                        onChange={e => {
                          this.handleChange(e, item.field);
                        }}
                        fileList={
                          state[item.field] ? state[item.field] : []
                        }
                        onPreview={this.handlePreview}
                      >
                        {state[item.field] &&
                          state[item.field].length >= 1 ? null : (
                            <div>
                              <PlusOutlined />
                              <div className="ant-upload-text">上传</div>
                            </div>
                          )}
                      </Upload>
                      <div style={{ textAlign: "center", paddingBottom: 15, width: 110 }}>
                        {item.title}
                        <p className="tip">请上传一张形象照!</p>
                      </div>
                      <Modal
                        visible={previewVisible}
                        footer={null}
                        onCancel={this.handleCancel}
                      >
                        <img
                          alt="example"
                          style={{ width: "100%" }}
                          src={previewImage}
                        />
                      </Modal>
                    </div>
                  );
                } else if (
                  item.type === "photos" ||
                  item.type === "files"
                ) {
                  return (
                    <div key={item.field} className="thumbBox">
                      <Upload
                        action={Conf.api + "/public_upload/upload"}
                        listType="picture-card"
                        onChange={e => {
                          this.handleChange(e, item.field);
                        }}
                        fileList={
                          state[item.field] ? state[item.field] : []
                        }
                        onPreview={this.handlePreview}
                      >
                        {!state[item.field] || state[item.field].length<3?
                        <div>
                          <PlusOutlined />
                          <div className="ant-upload-text">上传</div>
                        </div>:null}
                      </Upload>
                      <div style={{ textAlign: "center", paddingBottom: 15, width: 110 }}>
                        {item.title}
                        <p className="tip">请上传三张参赛照片！</p>
                      </div>
                      <Modal
                        visible={previewVisible}
                        footer={null}
                        onCancel={this.handleCancel}
                      >
                        <img
                          alt="example"
                          style={{ width: "100%" }}
                          src={previewImage}
                        />
                      </Modal>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
            {/* 富媒体 */}
            <div className="textarea">
              {formData && formData.map(item => {
                if (item.type === "textarea") {
                  return (
                    <Form.Item
                      label={item.title}
                      className="textarea"
                      key={item.field}
                      name={item.field}
                      rules={[
                        {
                          required: !!item.required,
                          message: "请输入" + item.title + "!"
                        }
                      ]}
                    >
                      <TextArea
                        rows={parseInt(item.length/80)}
                        placeholder={item.placeholder}
                        disabled={!!item.disabled}
                        onClick={(e)=>{e.target.focus()}}
                      />
                    </Form.Item>
                  );
                }
                return null;
              })}
            </div>
            
            <div className="formRichMedia">
              {formData && formData.map(item => {
                if (item.type === "richMedia") {
                  return (
                    <Form.Item
                      label={item.title}
                      key={item.field}
                      name={item.field}
                      rules={[
                        {
                          required: !!item.required,
                          message: "请输入" + item.title + "!"
                        }
                      ]}
                    >
                      <div>
                        <RcUeditor
                          value={item.value}
                          onChange={(v) => { this.handleChangeQuill(item.field, v) }} editorConfig={{
                            initialFrameWidth: '100%', // 初始化编辑器宽度
                            initialFrameHeight: 300,
                            autoFloatEnabled:false
                          }}
                          ueditorUrl={`/ueditor/ueditor.all.js`}
                          ueditorConfigUrl={`/ueditor/ueditor.config.js`}
                        />
                      </div>
                    </Form.Item>
                  );
                } else {
                  return null;
                }
              })}
            </div>
            <Form.Item style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit">提交</Button>
            </Form.Item>
          </Form> : null}
          <div className="tishi">温馨提示：所有信息填写完整，才能报名成功！</div>
      </div>
    </PageBox>

  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(FormPage);
