import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/cate";
import PageBox from "../public/index";
// import {Link} from 'react-router-dom'
import { Row, Col, Breadcrumb} from "antd";

// import { HomeOutlined } from "@ant-design/icons";
class HotNews extends Component {

 componentDidMount(){
   this.props.getContact()
 }

  render() {
    let {contact}=this.props.article;
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            联系我们
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col span={24}>
            <div className="main">
              {contact?
              <div dangerouslySetInnerHTML={{__html:contact.cate_content}}></div>
              :null}
            </div>
          </Col>
        </Row>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
