import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/ebook";
import PageBox from "../public/index";
import { Row, Col, Breadcrumb, Button, Tag, message, Space, Modal } from "antd";
import { Link, HashRouter } from 'react-router-dom'
import Axios from 'actions/request'
import {getToken} from 'common/env'



class HotNews extends Component {
  constructor() {
    super()
    this.state = {
      visible: false,
      actions: []
    }
  }

  buy = () => {
    let uid = localStorage.getItem("uid")
    let { ebookInfo } = this.props.ebook;
    if (!uid) {
      message.info('请先登录再购买')
      return false;
    }
    this.props.buy({ uid: uid, id: ebookInfo.ebook_id, type: 3 })
  }

  toChapter = (ele) => {
    let { ebookInfo } = this.props.ebook;
    let { userInfo } = this.props.user;
    let free = false;
    let price = parseInt(ebookInfo.ebook_price)

    if (price) {
      let uid = localStorage.getItem("uid")
      if (!uid && ele.chapter_is_fee) {
        message.info('请先登录再阅读')
        return false;
      }
      if (!ele.chapter_is_fee || ebookInfo.payed) {
        free = true;
      }
    } else {
      free = true;
    }
    let router = new HashRouter()
    if (free) {
      router.history.push('/chapter/' + ele.chapter_no)
    } else if (userInfo && userInfo.user_level_fid !== 1 && ebookInfo.ebook_is_vipfree) {
      router.history.push('/chapter/' + ele.chapter_no)
    } else {
      message.info('请先购买后再阅读')
    }
  }


  componentDidMount() {
    let { id } = this.props.match.params;
    this.props.ebookInfo({ id: id })
    this.props.chapters({ id })
    this.getActions()
  }


  /**
   * 按钮列表
   */
  getActions = () => {
    let { id } = this.props.match.params;
    Axios.post('/api/v2/ebook/getActions', { id}).then(res => {
      this.setState({ actions: res.data.data })
    })
  }


  /**
   * 201 事件操作成功，收藏功能，刷新后重新请求新的页面数据
   * @param {*} ele 
   */
  onClick2 = (ele) => {
    let { params } = this.props.match;
    //人工添加参数，覆盖原有参数
    if(ele['params']){
      params={...params,...ele['params']}
    }
    let {location}=this.props;
    Axios.post(ele['api'], { ...params,location}).then(res => {
      //收藏成功后，刷新页面重新请求数据
      const { data } = res.data;
      if (res.data.code === 201) {
        //其他类型事件
        message.success(res.data.msg)
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      } else if (res.data.code === 200) {
        this.props.showDialog("pay",data)
      }
    })
  }



  componentDidUpdate(props) {
    let { ebookInfo } = this.props.ebook;
    if (props.learn.ebookInfo !== ebookInfo && ebookInfo) {
      document.title = ebookInfo.ebook_title + ' - ' + this.props.index.siteInfo.sitename;
    }
  }

  /**
   * http://www.qiniangniang.com/
   * @param {*} ele 
   */
  toRead=(ele)=>{
    if(!getToken()){
      this.props.login()
      return false;
    }
    window.location.href="/#/chapter/"+ele.chapter_no
  }


  render() {
    let { ebookInfo, chapters } = this.props.ebook;
    const { actions } = this.state;

    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/ebook">空中书城</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {ebookInfo ? ebookInfo.ebook_title : null}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col span={24}>
            <div className="main" id="ebookInfo">
              {ebookInfo ?
                <div>
                  <div className="bookTitle">
                    <img src={ebookInfo.ebook_thumb} alt="封面" />
                    <dl>
                      <dt>{ebookInfo.ebook_title}</dt>
                      <dd>{ebookInfo.ebook_author}</dd>
                      {ebookInfo.author_name ?
                        <dd><Link to={`/authorbook/${ebookInfo.author_id}`}>作者：{ebookInfo.author_name}</Link></dd>
                        : null}
                      {ebookInfo.ebook_desp ?
                        <dd>简介：{ebookInfo.ebook_desp}</dd>
                        : null}
                      {/* <dd className="bookmore">
                        <span>{ebookInfo.ebook_com_sum} 点评</span>
                        <span>{ebookInfo.ebook_views}人 已读</span>
                      </dd> */}
                      {chapters && chapters.length ?
                        <dd className="price">价格：<span>￥{ebookInfo.ebook_price}</span>{ebookInfo.ebook_is_vipfree ? <p>VIP价格：<span>￥0.00</span></p> : null}</dd>
                        : null}
                      <dd className="bookaction">
                        <Space>
                          {Array.isArray(actions) ?actions.map((ele, i) => (
                            <Button key={i} disabled={ele['disabled']?true:false} type={ele['type']} onClick={() => { this.onClick2(ele) }}>{ele['text']}</Button>
                          )):<Button type="primary" onClick={()=>{this.props.login()}}>登录</Button>}
                        </Space>
                      </dd>
                    </dl>
                  </div>
                  <div className="ebook-xuyan">
                    <h2>{ebookInfo.ebook_title}序言</h2>
                    <div dangerouslySetInnerHTML={{ __html: ebookInfo.ebook_content }}></div>
                    <div className="chapters">
                      {chapters && chapters.length ?
                        <div>
                          <h2>章节列表</h2>
                          {chapters.map(ele => (
                            <a onClick={()=>{this.toRead(ele)}}>{ele.chapter_title}{!ele.chapter_is_fee ? <Tag color="red" style={{ marginLeft: 5 }}>免费</Tag> : null}</a>
                          ))}
                        </div> : null}
                    </div>
                  </div>
                </div>
                : null}
            </div>
          </Col>
        </Row>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
