import Type from "./type";
import {
  HashRouter
} from 'react-router-dom'
import Conf from "../common/config";
import {
  message,Modal
} from "antd";
import Axios from './request'
import {
  showModal
} from './index'
// import FUNC from 'common/public'

const {
  ORDER_TYPE_LST,
  ORDER_INFO,
  ORDER_LST,
  PAY_URL,
  GLOBAL_SHOW_MODAL
} = Type;

/**
 * 订单类型
 */
export function orderType() {
  return dispatch => {
    Axios.post(Conf.api + "/order/orderType").then(res => {
      if (!res.data.code) {
        dispatch({
          type: ORDER_TYPE_LST,
          orderType: res.data.data
        });
      }
    });
  };
}

/**
 * 列表
 * @param {*} args 
 */
export function lst(args) {
  return dispatch => {
    dispatch({
      type: ORDER_LST,
      orderLst:null
    });
    Axios.post(Conf.api + "/order/lst", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({
          type: ORDER_LST,
          orderLst: res.data.data
        });
      }
    });
  };
}


/**
 * 删除地址
 * @param {*} args 
 */
export function del(args) {
  let that = this;
  return dispatch => {
    Axios.post(Conf.api + "/order/del", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        message.info("操作成功");
        that.lst({
          uid: args.addr_uid
        });
      } else {
        message.info("操作失败");
      }
    });
  };
}

/**
 * 订单详情
 * @param {*} args 
 */
export function orderInfo(args) {
  return dispatch => {
    Axios.post(Conf.api + "/order/index", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({
          type: ORDER_INFO,
          orderInfo: res.data.data
        });
      }
    });
  };
}

/**
 * 获取支付二维码
 * @param {*} param 
 */
export function getPayUrl(param) {
  let time=3600;
  let t=setInterval(() => {
    time--;
    Axios.post(Conf.api + "/order/index", {id:param['out_trade_no']}).then(res=>{
      if(res.data.data.order_status===4){
        clearInterval(t)
        Modal.success({
          content: '支付成功',
          onOk() {
            window.location.reload()
          }
        })
      }
    })
  }, 1000);

  return dispatch => {
    Axios.post(Conf.api + "/order/pay", {
      ...param
    }).then(res => {
      if (!res.data.code) {
        let data=res.data.data;
        dispatch({
          type: PAY_URL,
          payUrl: data
        });
      }
    });
  };
}

// appId: "wxf9770f0d25a2c31a"
// nonceStr: "iHYaMHhhgDTOF1Zy"
// package: "prepay_id=wx02173221597840d47cdcdc7c1264844500"
// paySign: "29080E007AE977CEEED926861D0DE174"
// signType: "MD5"
// timeStamp: "1596360741"
export function onBridgeReady(data) {
  return dispatch=>{
    window.WeixinJSBridge.invoke(
      'getBrandWCPayRequest', {
        "appId": data.appId, //公众号名称，由商户传入     
        "timeStamp": data.timeStamp, //时间戳，自1970年以来的秒数     
        "nonceStr": data.nonceStr, //随机串     
        "package": data.package,
        "signType": data.signType, //微信签名方式：     
        "paySign": data.paySign //微信签名 
      },
      function (res) {
        let router = new HashRouter()
        if (res.err_msg === "get_brand_wcpay_request:ok") {
          alert("支付成功");
          // 使用以上方式判断前端返回,微信团队郑重提示：
          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
        } else {
          alert("支付失败,请重试");
        }
        router.history.replace('/member/order');
      });
  }

}

/**
 * 获取支付的openid
 * @param {*} params 
 * 通过返回的openid，订单id，发起支付请求
 */
export function wx_pay(params) {
  let that=this;
  return dispatch => {
    Axios.get(Conf.api + "/order/wx_getopenid_pay?"+params).then(res => {
      if(res.data.code){
        message.info(res.data.msg)
        let router=new HashRouter()
        router.history.push('/')
      }
      that.onBridgeReady(res.data.data)
    });
  };
}

export function delOrder() {
  return ({
    type: ORDER_INFO,
    orderInfo: null
  });
}

export function showLogin(args) {
  return showModal(args)
}

/**
 * 创建统一订单
 * 所有页面的订单，都经过此方法
 */
export function createPublicOrder(args) {
  return dispatch => {
    Axios.post(Conf.api + "/order/create", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        window.location.href='/#/order/'+res.data.data.out_trade_no
      }else{
        message.info(res.data.msg)
      }
    });
  };
}

export function showDialog(arg,param){
  return { type: GLOBAL_SHOW_MODAL, showModal: arg,modalParams:param };
}