import Type from "./type";

import Conf from "../common/config";
import Axios from './request'
const { USER_CP_LST,GLOBAL_SHOW_MODAL,CP_RECORD_INFO} = Type;

/**
 * 列表
 * @param {*} args 
 */
export function lst(args) {
  return dispatch => {
    Axios.post(Conf.api + "/cp_user/lst", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: USER_CP_LST, cpUserLst: res.data.data });
      }
    });
  };
}

/**
 * 报名记录详情
 * @param {*} args 
 */
export function info(args) {
  return dispatch => {
    dispatch({ type: CP_RECORD_INFO, cpResInfo: null});
    Axios.post(Conf.api + "/cp_user/info", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: CP_RECORD_INFO, cpResInfo: res.data.data });
      }
    });
  };
}

export function showModal(arg,params) {
  return { type: GLOBAL_SHOW_MODAL, showModal: arg?arg:null,modalParams:params?params:null };
}