const indexInit = {
    
}
const news = (state = indexInit, action) => {
    switch (action.type) {
      case "USER_CP_LST":
        return Object.assign({}, state, {
          ...action
        });
        //
        case "CP_RECORD_INFO":
          return Object.assign({}, state, {
            ...action
          });
      default:
        return state;
    }
}

export default news