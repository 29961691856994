import React from "react";
import './banner.less'

export default (props) => {
  let ads=props.data.filter(ele=>ele.banner_type_fid===2)
return (
  <div className="indexad2">
    {ads.map(ele=>(
      <a href={ele.banner_link} key={ele.banner_id} target="_blank" rel="noopener noreferrer">
        <img src={ele.banner_thumb} alt="图"/>
      </a>
    ))}
  </div>
);
}
