import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/user";
import { Link } from "react-router-dom";
import { Card, Upload,Button } from "antd";
import {getToken} from "common/env"

class UserPublic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: "",
      user_thumb: []
    };
    this.handleChange = this.handleChange.bind(this);
  }


  componentDidMount() { }
  //--------------------图片处理--------
  handleChange = async ({ file, fileList }) => {
    if (file.status === "done") {
      this.props.myUserInfo();
    }
  };
  //---------------图片处理 end------------------
  render() {
    let { userInfo } = this.props.user;
    return (
      <Card className="userInfoCard">
        <div className="userPub">
          <div className="userAvatar" id="topic">
            <Upload
              listType="picture-card"
              className="userThumb"
              showUploadList={false}
              withCredentials={true}
              headers={{token:getToken()}}
              accept="image/*"
              name="file"
              action="/api/v2/upload/avatar"
              onChange={e => {
                this.handleChange(e);
              }}
            >
              {userInfo && userInfo.user_thumb ? (
                <div className="user-avatar">
                  <img src={userInfo.user_thumb} alt="upload" />
                  <div className="ant-upload-text" style={{ color: '#999' }}>点击更换</div>
                </div>
              ) : (
                  <div className="avatar-box">
                    <img src={require("common/img/upload.jpg")} alt="upload" />
                    <div className="ant-upload-text">点击上传头像</div>
                  </div>
                )}
            </Upload>
            {userInfo ? (
              <div className="userNameBox">
                <div className="title">{userInfo.user_nickname}</div>
                <div className="level">
                  {userInfo.user_level_fid === 2 ? <i className="iconfont icon-vip"></i> : null}
                  {userInfo.user_level_fid === 3 ? <i className="iconfont icon-85"></i> : null}
                  <span style={{ paddingRight: 5 }}>{userInfo.user_level}</span>
                  <Button type="primary"><Link to="/member/upgrade" className="red">升级或续费VIP</Link></Button>
                  </div>
                <div className="procode">欢迎分享给更多朋友,分享码：{userInfo.user_code}</div>
              </div>
            ) : null}
          </div>
          {userInfo ? (
            <div className="userMsg">
              <Link to="/member/msg">
                <dl>
                  <dt>{userInfo.user_msg}</dt>
                  <dd>未读消息</dd>
                </dl>
              </Link>
              <Link to="/member/wallet">
                <dl>
                  <dt>{userInfo.user_money}</dt>
                  <dd>我的余额</dd>
                </dl>
              </Link>
              <Link to="/member/medal">
                <dl>
                  <dt>{userInfo.user_medal}</dt>
                  <dd>我的勋章</dd>
                </dl>
              </Link>
              <Link to="/member/scores">
                <dl>
                  <dt>{userInfo.user_score}</dt>
                  <dd>我的旗币</dd>
                </dl>
              </Link>
              <Link to="/mcomp/gift">
                <dl>
                  <dt>{userInfo.score_get}</dt>
                  <dd>收礼总数</dd>
                </dl>
              </Link>
              <Link to="/mcomp/gift">
                <dl>
                  <dt>{userInfo.score_send}</dt>
                  <dd>送礼总数</dd>
                </dl>
              </Link>

            </div>
          ) : null}
          <div></div>
        </div>
      </Card>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(UserPublic);
