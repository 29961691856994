import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/goods";
import PageBox from "../public/index";
import { Link } from "react-router-dom";
import { Row, Col, Breadcrumb } from "antd";
import GoodsType from "./type";

import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

class Ebook extends Component {
  componentDidMount() {
    this.props.lst();
  }

  render() {
    let { goodsLst } = this.props.goods;

    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href="/shop">商城</a>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col span={6}>
            <GoodsType />
          </Col>
          <Col span={18}>
            <div className="main shop">
              <ul>
                {goodsLst ?
                  goodsLst.map(ele => (
                    <li>
                      <Link to={`/goods/${ele.gd_id}`}>
                        <img src={ele.gd_thumb} alt="商品" />
                        <div className="title">{ele.gd_title.substr(0,20)}...</div>
                        <div style={{textDecoration:'line-through'}} className="marketPrice">价格：{ele.gd_price2}</div>
                        <div className="price">促销价：{ele.gd_price}</div>
                      </Link>
                      </li>
                  ))
                  : null}
              </ul>
            </div>
          </Col>
        </Row>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(Ebook);
