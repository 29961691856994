const indexInit = {
    
}
const news = (state = indexInit, action) => {
    switch (action.type) {
      case "NEWS_LST":
        return Object.assign({}, state, {
          ...action
        });
//NEWS_TYPE
case "NEWS_TYPE":
  return Object.assign({}, state, {
    ...action
  });
      default:
        return state;
    }
}

export default news