import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/user";
import PageBox from "./user";
import { List, Modal, Card } from "antd";
import FUNC from 'common/public'
// import { Link } from 'react-router-dom'
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

/**
 * 用户的公开个人主页，放在好友里面，展示旗袍圈的内容
 */
class UserHomePage extends Component {
  constructor() {
    super()
    this.handleCancel = this.handleCancel.bind(this);
    this.handlePreview = this.handlePreview.bind(this);
    this.showImg = this.showImg.bind(this);
    this.addFriend = this.addFriend.bind(this)
    this.sendMsg = this.sendMsg.bind(this)
    this.state = {
      previewVisible: false,
      previewImage: "",
      fileList: [],
      uid: null,
      id: null
    };
  }

  addFriend() {
    let { id, uid } = this.state;
    if (this.state.uid) {
      this.props.addFriend({ id, uid })
    } else {
      this.props.showLogin('login')
    }
  }

  sendMsg() {
    if (this.state.uid) {

    } else {
      this.props.showLogin('login')
    }
  }

  componentDidMount() {
    let { id } = this.props.match.params;
    this.props.userHomePage({ id })
  }


  handleCancel = () => this.setState({ previewVisible: false });
  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await FUNC.getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true
    });
  };

  showImg(img) {
    this.setState({
      previewImage: img,
      previewVisible: true
    });
  }


  render() {
    let { uHomePage} = this.props.user;
    let { previewImage, previewVisible } = this.state;
    let { id } = this.props.match.params;
    return (
      <PageBox id={id} keyName="topic">
        <Card title="朋友圈" size="small" style={{ marginTop: 15 }}>
          {uHomePage ? (
            <List
              dataSource={uHomePage}
              className="userTopics"
              pagination={{
                pageSize: 10
              }}
              renderItem={item => (
                <List.Item>
                  <div>
                    <div>{item.topic_datetime}</div>
                    <div dangerouslySetInnerHTML={{ __html: item.topic_content }}></div>
                    {item.topic_thumbs
                      ? item.topic_thumbs.map((ele, index) => (
                        <img
                          src={ele}
                          alt="图片"
                          key={`img${index}`}
                          onClick={() => {
                            this.showImg(ele);
                          }}
                        />
                      ))
                      : null}
                  </div>
                </List.Item>
              )}
            />
          ) : null}
          <Modal
                visible={previewVisible}
                footer={null}
                onCancel={this.handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
        </Card>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(UserHomePage);
