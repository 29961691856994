import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/ebook";
import PageBox from "components/public/userIndex";
import {Breadcrumb,List } from "antd";
import UserPub from "../public";
import UserLeft from "../left";
import { Link } from 'react-router-dom'

class LearnRecord extends Component {

  componentDidMount() {
    this.props.record({ pageSize: 5, current: 1 });
  }

  pages = (page) => {
    this.props.record({ pageSize: 5, current: page})
  }

  render() {
    let { ebookRecordLst } = this.props.ebook;
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            会员中心
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            空中书城读书记录
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="user-public">
          <UserPub />
        </div>
        <div className="user-center" id="medalpage">

          <div className="user-left">
            <UserLeft path={this.props.history.location.pathname} />
          </div>
          <div className="user-right">
            <div className="main">
              <div className="lst orderlst">
                <List
                  itemLayout="vertical"
                  size="large"
                  pagination={{
                    onChange: this.pages,
                    pageSize: 5,
                    total: ebookRecordLst ? ebookRecordLst.total : 0
                  }}
                  dataSource={ebookRecordLst ? ebookRecordLst.data : []}
                  renderItem={item => (
                    <List.Item
                      key={item.rec_fid}
                    >
                      <List.Item.Meta
                        title={<Link to={`/ebook/${item.rec_fid}`}>{item.ebook_title}</Link>}
                        description={item.rec_datetime}
                      />
                    </List.Item>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(LearnRecord);
