import Type from "./type";

import Conf from "../common/config";
import Axios from './request'
const { PROMOTE_LST,PROMOTE_PIC,QRCODE_BG } = Type;

/**
 * 列表
 * @param {*} args 
 */
export function lst(args) {
  return dispatch => {
    Axios.post(Conf.api + "/promote/lst", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: PROMOTE_LST, promoteLst: res.data.data });
      }
    });
  };
}
//

/**
 * 生成推广码
 */
export function createQrcode(args) {
  return dispatch => {
    Axios.post(Conf.api + "/qrcodes/create", {
      ...args
    }).then(res => {
      dispatch({ type: PROMOTE_PIC, myQrcodes: res.data });
    });
  };
}

/**
 * 获取二维码背景
 * @param {*} args 
 */
export function getqrbg(args) {
  return dispatch => {
    Axios.post(Conf.api + "/qrbg/lst", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: QRCODE_BG, qrcodeBg: res.data.data });
      }
    });
  };
}
