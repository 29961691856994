import Axios from "axios";
import FUNC from "../common/public";
import { message } from "antd";
import { HashRouter } from "react-router-dom";
// import qs from 'qs';
// import Conf from 'common/config'
import { getToken,clearToken } from 'common/env'

message.config({
    duration: 2,
    maxCount: 1
});
const codeMessage = {
    200: "服务器成功返回请求的数据。",
    201: "新建或修改数据成功。",
    202: "一个请求已经进入后台排队（异步任务）。",
    204: "删除数据成功。",
    400: "发出的请求有错误，服务器没有进行新建或修改数据的操作。",
    401: "用户没有权限（令牌、用户名、密码错误）。",
    403: "用户得到授权，但是访问是被禁止的。",
    404: "发出的请求针对的是不存在的记录，服务器没有进行操作。",
    406: "请求的格式不可得。",
    410: "请求的资源被永久删除，且不会再得到的。",
    422: "当创建一个对象时，发生一个验证错误。",
    500: "服务器发生错误，请检查服务器。",
    502: "网关错误。",
    503: "服务不可用，服务器暂时过载或维护。",
    504: "网关超时。"
};

const instance = Axios.create({
    timeout: 30000, // 请求超时时间30秒
    withCredentials: true, // 全局默认配置设置cookies
    headers: {
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        'X-Token': FUNC.getCookie('token'), // headers塞token
        'uid': localStorage.getItem("uid") ? localStorage.getItem("uid") : "",
        'token': getToken()
    }
});


instance.interceptors.request.use(config => {
    document.title = '旗娘娘 - 让生活更品质更美好'
    return config;
});

instance.interceptors.response.use(
    response => {
        if (response.data && response.data.code) {
            switch (response.data.code) {
                
                case 499:
                    // 清除登陆信息
                    message.info(response.data.msg)
                    localStorage.clear()
                    // setTimeout(() => {
                    //     window.location.reload()
                    // }, 1000);
                    break;
                case 498:
                    // 清除登陆信息
                    message.info(response.data.msg)
                    clearToken()
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                    break;
                case 301:
                    if (response.data.data) {
                        window.location.href = response.data.data;
                        return false;
                    }
                    break;
                case 302:
                    if (response.data.data) {
                        let router = new HashRouter()
                        router.history.push(response.data.data)
                    }
                    break;
                case 201:
                    message.success(response.data.msg);
                    if (response.data.data) {
                        response.data.code = 200;
                    }
                    break;
                case 200:
                    break;
                default:
                    message.info(response.data.msg);
                    break;
            }
        }

        return response;
    },
    err => {
        // 请求错误处理
        const { response } = err;
        if (response && response.status) {
            const errorText = codeMessage[response.status] || response.statusText;
            const { status, url } = response;
            message.info(errorText)
            // notification.error({
            //     message: `请求错误 ${status}: ${url}`,
            //     description: errorText
            // });
        } else if (!response) {
            message.info('网络异常')
            // notification.error({
            //     description: "您的网络发生异常，无法连接服务器",
            //     message: "网络异常"
            // });
        }
        return Promise.reject(err);
    }
);

export default instance
