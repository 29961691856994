import Type from "./type";

import Conf from "../common/config";
// import { message } from "antd";
import Axios from './request'
const { SCORE_LST} = Type;

/**
 * 列表
 * @param {*} args 
 */
export function lst(args) {
  return dispatch => {
    Axios.post(Conf.api + "/msg/lst", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: SCORE_LST, msgLst: res.data.data });
      }
    });
  };
}
