const indexInit = {

}
const author = (state = indexInit, action) => {
  switch (action.type) {
    case "AUTHORS":
      return Object.assign({}, state, {
        ...action
      });
      case "AUTHOR_INDEX":
        return Object.assign({}, state, {
          ...action
        });
        case "AUTHOR_BOOKS":
          return Object.assign({}, state, {
            ...action
          });
    default:
      return state;
  }
}

export default author