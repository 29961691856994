import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/salon";
import PageBox from "components/public/userIndex";
import { Link } from 'react-router-dom'
import { Row, Col, Breadcrumb, List,Spin } from "antd";
import UserPub from '../public'
import UserLeft from '../left'

class HotNews extends Component {

  componentDidMount() {
    
    this.props.userSalonRecord();
  }

  render() {
    let { salonRecord } = this.props.salon;

    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link href="/member">会员中心</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>我的活动沙龙</Breadcrumb.Item>
        </Breadcrumb>
        <div className="user-center">
          <div className="user-public">
            <UserPub />
          </div>
          <div className="user-left">
            <UserLeft path={this.props.history.location.pathname} />
          </div>
          <div className="user-right">
            <div className="main">
              {salonRecord ? (
                <div className="mycplst">
                  <List
                    dataSource={salonRecord}
                    pagination={{
                      pageSize: 10
                    }}
                    renderItem={item => (
                      <List.Item>
                        <dl>
                          <dt>
                            <Link
                              to={`/salon/${item.salon_id}`}
                            >
                              <span>{item.salon_title}</span>
                    <span>举办时间：{item.salon_begin}-{item.salon_end}</span>
                            </Link>
                          </dt>
                          <dd>
                    <span>报名时间：{item.slr_dateline}</span>
                          </dd>
                        </dl>
                      </List.Item>
                    )}
                  />
                </div>
              ) : (
                <Spin/>
                )}
            </div>
          </div>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
