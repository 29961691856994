import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/qipao";
import PageBox from "../public/index";
import { Row, Col, Breadcrumb, List } from "antd";
import './topic.less';
import { Link } from 'react-router-dom';
import PageAd from './ad/index'
import Types from './type'

class Ebook extends Component {
  constructor(){
    super()
    this.state={
      type:null
    }
  }

  componentDidMount() {
    const {params}=this.props.match;
    this.setState({type:params.id})
    this.props.brankContentLst({ pageSize: 18, current: 1,type:params.id })
    this.props.getAds(10)
    this.props.getTopicType()
  }

  componentDidUpdate(props){
    const {params}=this.props.match;
    if(params.id!==props.match.params.id && params.id){
      this.setState({type:params.id})
      this.props.brankContentLst({ pageSize: 18, current: 1,type:params.id })
    }
  }

  pages = (page) => {
    const {type}=this.state;
    this.props.brankContentLst({ pageSize: 18, current: page,type })
  }


  render() {
    let { brankContentLst } = this.props.qipao;
    let { topicAds } = this.props.index;

    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            旗袍圈
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col span={4}>
            <Types id={this.state.type}/>
            {topicAds && topicAds.length ?
              <PageAd data={topicAds} />
              : null}
          </Col>
          <Col span={20}>

            <div className="main qnngroup">
              <List
                dataSource={brankContentLst ? brankContentLst.data : []}
                pagination={{
                  onChange: this.pages,
                  pageSize: 18,
                  total: brankContentLst ? brankContentLst.total : 0
                }}
                renderItem={item => (
                  <List.Item>
                    <a
                      href={`/qnarticle/${item.qp_id}`}
                      className="articleItem"
                    >
                      <img src={item.qp_thumb} alt="图片" />
                      <div className="title">{item.qp_title.substr(0, 16)}</div>
                    </a>
                  </List.Item>
                )}
              />
            </div>
          </Col>
        </Row>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(Ebook);
