const indexInit = {

}
const user = (state = indexInit, action) => {
  switch (action.type) {
    case "USER_LOGIN":
      return Object.assign({}, state, {
        ...action
      });
    case "USER_INFO":
      return Object.assign({}, state, {
        ...action
      });
    case "USER_PROFIT":
      return Object.assign({}, state, {
        ...action
      });
    case "ORDER_INFO":
      return Object.assign({}, state, {
        ...action
      });
    //
    case "FRIEND_USER_LST":
      return Object.assign({}, state, {
        ...action
      });
    case "USER_HOME":
      return Object.assign({}, state, {
        ...action
      });
    //
    case "USER_PUBLIC":
      return Object.assign({}, state, {
        ...action
      });
    case "USER_RELATION":
      return Object.assign({}, state, {
        ...action
      });
    case "USER_TEAM":
      return Object.assign({}, state, {
        ...action
      });
    case "USER_GAME":
      return Object.assign({}, state, {
        ...action
      });
    case "SCORE_LST":
      return Object.assign({}, state, {
        ...action
      });
      case "SCORE_TYPE":
      return Object.assign({}, state, {
        ...action
      });
    case "PROMOTE_LST":
      return Object.assign({}, state, {
        ...action
      });
    case "VIP_CARD":
      return Object.assign({}, state, {
        ...action
      });
    case "PROMOTE_PIC":
      return Object.assign({}, state, {
        ...action
      });
    case "QRCODE_BG":
      return Object.assign({}, state, {
        ...action
      });
      case "PROFIT_MONTH":
        return Object.assign({}, state, {
          ...action
        });
        case "OPENID":
        return Object.assign({}, state, {
          ...action
        });
    default:
      return state;
  }
}

export default user