import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/index";
import { Spin } from 'antd'
import "./style.css"
class PublicIndex extends Component {

  componentDidMount() {
    this.props.getCity()
  }

  render() {
    let { allCity,siteInfo} = this.props.index;
    return <div id="city">
      <a className="iconfont icon-fork"></a>
      {allCity ?
      <ul>
        <li>
          <a href="http://www.qiniangniang.com" style={{fontSize:20}}>返回总站</a>
        </li>
        {allCity.map(ele=>(
          <li key={ele.city}>
            <div className="province">{ele.city}</div>
            {ele.child?
            <div className="son">
              {ele.child.map(ele2=>(
              <a className={ele2.city===siteInfo.city?'red':null} href={ele2.domain} key={ele2.city}>{ele2.city}</a>
              ))}
            </div>
            :null}
          </li>
        ))}
      </ul>
      : <Spin />}
    </div>
  }
}



export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(PublicIndex);

