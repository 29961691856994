import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/merchant";
import PageBox from "../public/index";
import { HashRouter, Link } from 'react-router-dom'
import { Breadcrumb, Card, Button, Rate, Divider, Tag } from "antd";

class HotNews extends Component {

  componentDidMount() {
    this.props.merchant_salon();
    this.props.types();
    this.props.typeAll();
  }

  publish = () => {
    let uid = localStorage.getItem("uid")
    if (!uid) {
      this.props.showLogin('login')
    } else {
      let router = new HashRouter()
      router.history.push("/publish")
    }
  }

  render() {
    let { merchant_salon, merchant_types, merchantTypeAll } = this.props.index;

    return (
      <PageBox>
        <div className="main">
          <Breadcrumb style={{ padding: '5px 0' }}>
            <Breadcrumb.Item>
              <a href="/">首页</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/merchants">商家营</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="merHead">
            <div></div>
            <Button onClick={this.publish} type="primary">快速开店</Button>
          </div>
          <Card title="全部分类" size="small" style={{ marginBottom: 15 }} className="mchType">
            {merchantTypeAll ? merchantTypeAll.map(ele => (
              <dl>
                <dt><Link to={`/mch_type/${ele.id}`}>{ele.title}</Link></dt>
                {ele.children ? ele.children.map(ele2 => (
                  <dd className="title"><Link to={`/mch_type/${ele2.id}`}>{ele2.title}</Link></dd>
                )) : null}
              </dl>
            )) : null}
          </Card>
          <div className="merchants">
            <div className="merchantsIndex">
              {merchant_types ? merchant_types.map((ele) => (
                ele.showType === 'full' ?
                  <Card title={<dl>
                    <dt><span>{ele.title}</span></dt>
                    <dd>{ele.subType && ele.subType.length ? ele.subType.map((ele3) => (
                      <Link to={`/mch_type/${ele3.id}`} key={ele3.id}>{ele3.title}</Link>
                    )) : null}</dd>
                  </dl>}
                    className="fullCard"
                    extra={<Link to={`/mch_type/${ele.id}`}>全部 &gt;</Link>}
                  >
                    <div className="items">
                      {ele.subType && ele.subType.map((ele6,index6) => (
                        <Link className="cardItem" to={`/mch_type/${ele6.id}`} key={index6}>
                          {ele6.thumb ?
                            <img src={ele6.thumb} alt="缩略图" />
                            : <img src={require('common/img/deflogo.png')} alt="缩略图" className="defaultlogo" />}
                          <dl>
                            <dt>{ele6.title}</dt>
                          </dl>
                        </Link>
                      ))}
                    </div></Card>
                  :
                  <Card title={<dl>
                    <dt>{ele.title}</dt>
                    <dd>{ele.subType && ele.subType.length ? ele.subType.map((ele3) => (
                      <Link to={`/mch_type/${ele3.id}`} key={ele3.id}>{ele3.title}</Link>
                    )) : null}</dd>
                  </dl>}
                    extra={<Link to={`/mch_type/${ele.id}`}>全部 &gt;</Link>}
                  >
                    <div className="items">
                      {ele.children && ele.children.map((ele2) => (
                          <div className="cardItem">
                          {ele2.thumb ?
                              <img src={ele2.thumb} alt="缩略图" />
                              : <img src={require('common/img/deflogo.png')} alt="缩略图" className="defaultlogo" />}
                            <dl>
                              <dt><span>{ele2.title}</span></dt>
                              <dd className="rate"><Rate disabled defaultValue={0} /></dd>
                              <dd style={{ textAlign: 'right' }}>
                                <Button type="primary" size="small"><a href={`tel:${ele2.tel}`}>电话</a></Button>
                                <Divider type="vertical" />
                                <Button size="small"><Link to={`/merchant/${ele2.id}`}>详情</Link></Button>
                              </dd>
                            </dl>
                            {ele2.discount?
                            <Tag color="rgba(255, 85, 0,0.7)" className="mchVipTag">本站VIP {ele2.discount}折</Tag>
                            :null}
                          </div>
                        
                      ))}
                    </div></Card>
              )) : null}
            </div>
            {/* 沙龙 */}
            {merchant_salon ?
              <Card title="沙龙" size="small">
                <div className="items">
                  {merchant_salon.map((ele) => (
                    <a className="cardItem" href={`/#/salon/${ele.id}`}>
                      <img src={ele.thumb} alt="缩略图" />
                      <dl>
                        <dt><span>{ele.title}</span></dt>
                        <dd>{ele.desp}</dd>
                      </dl>
                    </a>
                  ))}
                </div>
              </Card> : null}
          </div>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
