import Type from "./type";

import Conf from "../common/config";
import Axios from './request'
const { QIPAO_LST,QIPAO_ARTICLE,PAGE_AD,TOPIC_TYPE} = Type;

export function getAds(arg){
  return dispatch => {
    Axios.get(Conf.api + "/banner/lst?type="+arg).then(res => {
      if (!res.data.code) {
        dispatch({ type: PAGE_AD, topicAds: res.data.data });
      }
    });
  };
}
export function book(args) {
  return dispatch => {
    Axios.post(Conf.api + "/qipao/book", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: QIPAO_LST, book: res.data.data });
      }
    });
  };
}

export function lady(args) {
  return dispatch => {
    Axios.post(Conf.api + "/qipao/lady", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: QIPAO_LST, lady: res.data.data });
      }
    });
  };
}

export function pinjian(args) {
  return dispatch => {
    Axios.post(Conf.api + "/qipao/pinjian", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: QIPAO_LST, pinjian: res.data.data });
      }
    });
  };
}

export function video(args) {
  return dispatch => {
    Axios.post(Conf.api + "/qipao/video", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: QIPAO_LST, video: res.data.data });
      }
    });
  };
}

export function zhuanfang(args) {
  return dispatch => {
    Axios.post(Conf.api + "/qipao/zhuanfang", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: QIPAO_LST, zhuanfang: res.data.data });
      }
    });
  };
}

export function article(args) {
  return dispatch => {
    Axios.post(Conf.api + "/qipao/article", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: QIPAO_ARTICLE, article: res.data.data });
      }
    });
  };
}


export function getTopicType(data) {
  let that = this;
  return dispatch => {
    Axios.post(Conf.api + "/topic/topicType", data).then(res => {
      if (!res.data.code) {
        dispatch({type:TOPIC_TYPE,topicType:res.data.data})
      }
    });
  };
}

/**
 * 品牌荟内容列表
 * @param {*} args 
 */
export function brankContentLst(args) {
  return dispatch => {
    Axios.post(Conf.api + "/qipao/lst", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: QIPAO_LST, brankContentLst: res.data.data });
      }
    });
  };
}