import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/user";
import PageBox from "../public/userIndex";
import { Breadcrumb, Button, Card, Divider, Table } from "antd";
import UserPub from './public'
import UserLeft from './left'
import { Link } from 'react-router-dom'

class HotNews extends Component {
  componentDidMount() {
    this.props.getUserLevels()
  }
  render() {
    let { userInfo, userLevels } = this.props.user;
    const columns = [
      {
        key: 'level_name',
        dataIndex: 'level_name',
        title: '等级'
      },
      {
        key: 'level_price2',
        dataIndex: 'level_price2',
        title: '原价',
        render:(text)=>(
          <div className="crossText">{text}</div>
        )
      },
      {
        key: 'level_price',
        dataIndex: 'level_price',
        title: '活动价'
      },
      {
        key: 'actions',
        dataIndex: 'actions',
        title: '操作',
        width:200,
        align:'right',
        render: (text, record) => (
          <div>
            <Button type="primary" onClick={()=>{this.props.upgradeVip({ type: record.id })}}>立即升级</Button>
            <Divider type="vertical" />
            <a href={`/user_level/${record.id}`} target="_blank" style={{color:'#666'}}>权益说明</a>
          </div>
        )
      }
    ];

    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            会员中心
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            会员VIP
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="user-center">
          <div className="user-public">
            <UserPub />
          </div>
          <div className="user-left">
            <UserLeft path={this.props.history.location.pathname} />
          </div>
          <div className="user-right">
            <div className="main">
              <div className="login">
                {userInfo ?
                  <Card title="会员升级" size="small" className="vipStatus">
                    {userInfo.user_level_fid === 2 ?
                      <img src={userInfo.user_vip_thumb} alt="VIP会员卡" />
                      : null}
                    {userInfo.user_level_fid === 4 ?
                      <img src={userInfo.user_yyg_thumb} alt="运营官" />
                      : null}
                    {userInfo.user_level_fid === 3 ?
                      <p style={{ textAlign: 'center', paddingBottom: 15 }}><img src={require('common/img/zuan.png')} alt="vip" style={{ margin: '0 auto' }} /></p>
                      : null}
                    <p>当前等级：{userInfo.user_level}</p>
                    <p>{userInfo.user_level_fid !== 1 ? <span>到期时间：{userInfo.user_vip_end}</span> : null}</p>
                    <p style={{ textAlign: 'center' }}><Button type="primary"><Link to="/vipcard">实体会员卡激活</Link></Button></p>
                  </Card> : null}
                {userLevels ?
                <div className="vipFee">
                  <Table columns={columns} dataSource={userLevels} title={() => ("会员等级费用说明")} pagination={false} style={{ width: '100%' }} />
                </div>
                  : null}
                {/* <Card title="会员费用说明" size="small" className="vipFee">
                  <Card.Grid style={gridStyle} hoverable={false}>VIP会员</Card.Grid>
                  <Card.Grid style={gridStyle} hoverable={false}>198元</Card.Grid>
                  <Card.Grid style={gridStyle} hoverable={false}><Button type="primary" size="small" onClick={() => { this.props.upgradeVip({ type: 1 }) }}>立即升级</Button> (<Link to="/vip" style={{ color: '#ccc' }}>权益说明</Link>)</Card.Grid>
                  <Card.Grid style={gridStyle} hoverable={false}>运营官</Card.Grid>
                  <Card.Grid style={gridStyle} hoverable={false}>299元</Card.Grid>
                  <Card.Grid style={gridStyle} hoverable={false}><Button type="primary" size="small" onClick={() => { this.props.upgradeVip({ type: 2 }) }}>立即升级</Button>  (<Link to="/yunyingguan" style={{ color: '#ccc' }}>权益说明</Link>)</Card.Grid>
                </Card> */}
              </div>
            </div>
          </div>
        </div>
      </PageBox>
    )
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
