const indexInit = {

}
const article = (state = indexInit, action) => {
  switch (action.type) {
    case "MERCHANT":
      return Object.assign({}, state, {
        ...action
      });
    default:
      return state;
  }
}

export default article