import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/merchant";
import { List, Avatar} from "antd";
import {LikeOutlined, StarOutlined } from '@ant-design/icons';

class Merchant extends Component {
  constructor(){
    super()
    this.state={
      page:1
    }
  }
  componentDidMount() {
    const {id}=this.props.data;
    const {page}=this.state;
    this.props.getComments({merchant:id,page})
  }

  changePage=(page)=>{
    this.setState({page})
    const {id}=this.props.data;
    this.props.getComments({merchant:id,page})
  }




  render() {
    let { comments } = this.props.merchant;
    const that=this;
    const IconText = ({ icon, text }) => (
      <div>
        {React.createElement(icon)}
        {text}
      </div>
    );
    return (
      <List
        itemLayout="vertical"
        size="large"
        pagination={{
          onChange: page => {
            that.changePage(page)
          },
          pageSize: 10,
        }}
        dataSource={comments}
        footer={null}
        renderItem={item => (
          <List.Item
            key={item.title}
            actions={[
              <IconText icon={StarOutlined} text={item.stars} key="list-vertical-star-o" />,
              <IconText icon={LikeOutlined} text={item.thumbs} key="list-vertical-like-o" />
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar src={item.avatar} />}
              title={null}
              description={item.username}
            />
            {item.content}
          </List.Item>
        )}
      />
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(Merchant);
