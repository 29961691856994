import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions";
import { Form } from "antd"
import RcUeditor from 'react-ueditor-wrap';

class UserForm extends Component {
  constructor() {
    super()
    this.state = {
      value: null
    }
  }

  componentDidMount() {
    let { item } = this.props;
    this.setState({ value: item.value })
  }

  handleClick = (e) => {
    e.target.focus();
  }


  handleChangeQuill = (field, value) => {
    this.setState({ text: value })
    this.props.onChange(field, value)
  }

  render() {
    let { item } = this.props;

    return <Form.Item
      label={item.title}
      key={item.field}
      className="formRichMedia"
      name={item.field}
      rules={[
        {
          required: !!item.required,
          message: "请输入" + item.title + "!"
        }
      ]}
    >
      <div>
        <RcUeditor
          value={item.value}
          onChange={(v) => { this.handleChangeQuill(item.field, v) }} editorConfig={{
            initialFrameWidth: '100%', // 初始化编辑器宽度
            initialFrameHeight: 300,
            autoFloatEnabled: false,
            toolbars:[['undo', 'redo', 'bold', 'italic',
            'underline','fontborder', 'backcolor', 'fontsize', 'fontfamily',
            'justifyleft', 'justifyright','justifycenter', 'justifyjustify',
            'strikethrough','superscript', 'subscript', 'removeformat',
            'formatmatch','autotypeset', 'blockquote', 'pasteplain', '|',
            'forecolor', 'backcolor','insertorderedlist', 'insertunorderedlist',
            'selectall', 'cleardoc']]
          }}
          ueditorUrl={`/ueditor/ueditor.all.js`}
          ueditorConfigUrl={`/ueditor/ueditor.config.js`}
        />
      </div>
    </Form.Item>
  }
}

export default connect(state => state, dispatch => bindActionCreators(actionsCreators, dispatch))(UserForm);