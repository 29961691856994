import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/index";

class App extends Component {


  render() {
    let { qipao } = this.props.index;
    let {pinjian, pinjian2 } = qipao;
    return (
        <div className="pinjian">
        <h3>
            <Link to="/pinpaihui/4">品鉴</Link>
          </h3>
          {pinjian?
          <div className="pinjianbox">
            <a href={`/qnarticle/${pinjian.qp_id}`} className="wordInpic pinjianrec">
              <img src={pinjian.qp_thumb} alt="专访" />
            </a>
            <div className="pinjianlst">
              <ul>
              {pinjian2.map(ele => (
                <li key={ele.qp_id}>
                <a href={`/qnarticle/${ele.qp_id}`}  title={ele.qp_title}>
                  <span>▪ {ele.qp_title.length>12?ele.qp_title.substr(0,12)+'...':ele.qp_title}</span>
                </a>
                </li>
              ))}
              </ul>
            </div>
          </div>:null}
        </div>
    )
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(App);
