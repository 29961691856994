import Type from "./type";
import Conf from "../common/config";
import {message} from 'antd'
import Axios from './request'
const { GIFT_LST,COMPETITION_USER } = Type;

//礼物列表
export function lst() {
  return dispatch => {
    Axios.post(Conf.api + "/gift/lst").then(res => {
      if (!res.data.code) {
        dispatch({ type: GIFT_LST, giftLst: res.data.data });
      }
    });
  };
}

/**
 * 赠送礼物
 */
export function add(params) {
  let that=this
  return dispatch => {
    Axios.post(Conf.api + "/gift/add",params).then(res => {
      if (!res.data.code) {
        message.info(res.data.msg)
        that.cpUser({id:params.gift_cp_fid,uid:params.gift_user_bid})
      }
    });
  };
}

export function cpUser(data) {
  return dispatch => {
    Axios.post(Conf.api + "/competition/cpuser",{
      ...data
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: COMPETITION_USER, cpUserData: res.data.data });
      }
    });
  };
}

