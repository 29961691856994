import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/competition";
import { Form, Button, InputNumber, message } from "antd";
import FUNC from 'common/public'
import {Link} from 'react-router-dom'

class Login extends Component {
  constructor() {
    super()
    this.onFinish = this.onFinish.bind(this)
  }
  componentDidMount() {
    let uid=localStorage.getItem("uid")
    this.props.getWallet({uid:uid})
  }

  onFinish = values => {
    if(!values.money){
      message.info('打赏金额不能为空')
      return false;
    }
    let {modalParams}=this.props.index
    let send={...values,...modalParams}
    this.props.dashang(send)
  }

  render() {
    let {wallet}=this.props.user;

    return (
      <div className="login">
        <Form
          name="login"
          layout="vertical"
          style={{ width: 300 }}
          onFinish={this.onFinish}
        >
          {wallet?
          <div className="accountInfo">
            <div>您的账户余额：{wallet.user_money}元</div>
          </div>
          :null}
          <Form.Item
            name="money"
            rules={[
              {
                required: true,
                message: "请输入打赏金额!"
              }
            ]}
          >
            <InputNumber placeholder="请输入打赏金额" style={{ width: '100%' }} min={1}/>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              提交
            </Button>
            <div style={{textAlign:'right',paddingTop:10}}>
              <Link to="/user/pay">账户充值</Link>
            </div>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(Login);
