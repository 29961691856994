import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/salon";
import { Card } from "antd";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

class RightNews extends Component {

  componentDidMount() {
    this.props.rec();
  }

  render() {
    let { salonRec } = this.props.salon;
    return (
        <Card title="热门活动" size="small">
          <ul className="topicrec">
            {salonRec
              ? salonRec.map(item => (
                  <li key={item.salon_id}>
                    <Link to={`/salon/${item.salon_id}`}>
                      <span>{item.salon_title.length>14?item.salon_title.slice(0,14)+'... ':item.salon_title}</span>
                      <span>{moment(item.salon_datetime).format('YY-MM-DD')}</span>
                    </Link>
                  </li>
                ))
              : null}
          </ul>
        </Card>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
) (RightNews);
