import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/competition";
import Box from '../article'

class ArticlePage extends Component {


  render() {
    let {id}=this.props.match.params;
    let { article } = this.props.competition;
    return (
      <Box id={id}  link={'video'}>
        <div className="comp-content">
        <h2>视频回放</h2>
          {article && article.cp_hf_file?
          <div
            className="article-content">
              <video src={article.cp_hf_file} controls style={{width:'100%'}}></video>
            </div>
            :null}
        </div>
      </Box>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(ArticlePage);
