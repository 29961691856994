import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/topic";
import PageBox from "components/public/userIndex";
import Conf from 'common/config'
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Form,
  Button,
  Upload,
  message,
  Modal
} from "antd";

import UserPub from "./public";
import UserLeft from "./left";
import FUNC from "common/public";
import moment from "moment";
import "moment/locale/zh-cn";
import RcUeditor from 'react-ueditor-wrap';
import { PlusOutlined, DeleteFilled } from "@ant-design/icons";
moment.locale("zh-cn");

class HotNews extends Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.onFinish = this.onFinish.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handlePreview = this.handlePreview.bind(this);
    this.changePhoto = this.changePhoto.bind(this);
    this.showImg = this.showImg.bind(this);
    this.beforeUpload = this.beforeUpload.bind(this)
    this.handleChangeQuill = this.handleChangeQuill.bind(this)
    this.state = {
      previewVisible: false,
      previewImage: "",
      time: 0,
      fileList: [],
      formPic: {}
    };
  }
  handleChangeQuill(field, value) {
    let obj = {}
    obj[field] = value;
    this.formRef.current.setFieldsValue(obj)
  }
  beforeUpload(val) {
    let accept = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'image/svg']
    if (!accept.find(ele => ele === val.type)) {
      message.info('此上传格式不支持，请重新上传')
      return false;
    }
  }

  componentDidMount() {
    let uid = localStorage.getItem("uid");
    this.props.lst({ uid: uid });
  }

  //-----------------------图片处理--------------------
  changePhoto(field, uid, url) {
    let { formPic } = this.state;
    if (url) {
      if (formPic[field]) {
        formPic[field][uid] = url;
      } else {
        formPic[field] = {};
        formPic[field][uid] = url;
      }
    } else {
      delete formPic[field][uid];
    }
    this.setState({ formPic: formPic });
  }

  handleChange = async ({ file, fileList }, field) => {
    // ${Conf.host}/api/topic/upload`
    //把图片地址存放到上层组件
    if (file.status === "done") {
      this.changePhoto(field, file.uid, file.response);
    }
    if (file.status === "removed") {
      this.changePhoto(field, file.uid, "");
    }
    //本页面的图片展示
    let obj = {};
    obj[field] = fileList;
    this.setState(obj);
  };

  handleCancel = () => this.setState({ previewVisible: false });

  // 图片预览
  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await FUNC.getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true
    });
  };

  showImg(img) {
    this.setState({
      previewImage: img,
      previewVisible: true
    });
  }
  //-----------------图片处理----------------------------

  onFinish(values) {
    let pics = this.state.formPic;
    let picReset = FUNC.getStatePhotos(pics);
    if(!picReset['topic_thumbs']){
      message.info('暂不支持发布纯文字朋友圈')
      return false;
    }
    let send = { ...values, ...picReset };
    send["topic_uid"] = localStorage.getItem("uid");

    let time = 3;
    let t = setInterval(() => {
      if (time <= 0) {
        clearInterval(t);
        this.formRef.current.resetFields();
        this.setState({ formPic: {} });
      }
      this.setState({ time: time-- });
    }, 1000);
    this.props.add(send);
  }

  render() {
    let { topicUserLst } = this.props.topic;
    const { previewVisible, previewImage, fileList } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">相册</div>
      </div>
    );
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/user/index">会员中心</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>我的旗袍圈</Breadcrumb.Item>
        </Breadcrumb>
        <div className="user-public">
            <UserPub />
          </div>
        <div className="user-center"  id="medalpage">

          <div className="user-left">
            <UserLeft path={this.props.history.location.pathname} />
          </div>
          <div className="user-right">
            <div className="main">
              <div className="sendqnn">
                <Form
                  name="topic"
                  layout="vertical"
                  ref={this.formRef}
                  style={{ width: "100%" }}
                  onFinish={this.onFinish}
                >
                  <Form.Item
                    valuePropName="fileList"
                    className="topicUpload"
                  >
                    <Upload
                      accept="image/*"
                      action={`${Conf.host}/api/topic/upload`}
                      beforeUpload={this.beforeUpload}
                      listType="picture-card"
                      onPreview={this.handlePreview}
                      onChange={e => {
                        this.handleChange(e, "topic_thumbs");
                      }}
                    >
                      {fileList.length > 9 ? null : uploadButton}
                    </Upload>
                    <Modal
                      visible={previewVisible}
                      footer={null}
                      onCancel={this.handleCancel}
                    >
                      <img
                        alt="example"
                        style={{ width: "100%" }}
                        src={previewImage}
                      />
                    </Modal>
                  </Form.Item>
                  <Form.Item
                    name="topic_content"
                    rules={[
                      {
                        required: true,
                        message: "内容不能为空"
                      }
                    ]}
                  >
                    <div>
                      <RcUeditor
                        onChange={(v) => { this.handleChangeQuill('topic_content', v) }} editorConfig={{
                          initialFrameWidth: '100%', // 初始化编辑器宽度
                          initialFrameHeight: 100,
                          toolbars: [['underline', 'bold', 'removeformat']],
                          maximumWords: 355
                        }}
                        ueditorUrl={`/ueditor/ueditor.all.js`}
                        ueditorConfigUrl={`/ueditor/ueditor.config.js`}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item style={{ textAlign: "right" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={this.state.time ? true : false}
                    >
                      发表{this.state.time ? this.state.time : null}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
              {topicUserLst ? (
                <div className="qnngroup">
                  <ul>
                    {topicUserLst.map(item => (
                      <li key={item.topic_id}>
                        <div className="topicLeft">
                          <div className="topicDate">
                            {moment(item.topic_datetime).format("YYYY-MM-DD")}
                          </div>
                        </div>
                        <div className="topicContent">
                          <div dangerouslySetInnerHTML={{ __html: item.topic_content }}></div>
                          <div className="topicBottom">
                            <div className="topicThumbs">
                              {item.topic_thumbs
                                ? item.topic_thumbs.map((ele, index) => (
                                  <img
                                    src={ele}
                                    alt="图片"
                                    key={`img${index}`}
                                    onClick={() => {
                                      this.showImg(ele);
                                    }}
                                  />
                                ))
                                : null}
                            </div>
                            
                          </div>
                        </div>
                        <div
                              className="delBtn"
                              style={{textAlign:'right'}}
                              onClick={() => {
                                this.props.del(item.topic_id);
                              }}
                            >
                              <DeleteFilled />
                            </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                  <div className="empty">
                    <div>没有记录</div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
