import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/friend";
import PageBox from "../public/index";
import { Link } from 'react-router-dom'
import { Row, Col, Breadcrumb, List,Select } from "antd";

import moment from "moment";
import "moment/locale/zh-cn";
import All from 'common/city'
moment.locale("zh-cn");
const { Option } = Select;

class HotNews extends Component {
  constructor() {
    super()
    this.state = {
      provinces: [],
      privince:"",
      citys:[],
      city:""
    }
    this.onChange1=this.onChange1.bind(this)
    this.onChange2=this.onChange2.bind(this)
  }

  onChange1(val){
    if(val){
      //没有三级
      if(All[val][val]){
        this.setState({citys:All[val][val]})
      }else{
        //有三级
        let citys=[]
        for (const k in All[val]) {
          citys.push(k)
        }
        this.setState({citys:citys})
      }
    }else{
      this.setState({citys:[]})
    }
    this.setState({province:val,city:""},function(){
      let {province,city}=this.state;
      this.props.frUserLst({ city: [province,city] })
    })
  }

  onChange2(val){
    let pro=this.state.province
    if(Object.keys(All[pro]).length===1){
      //直辖市
      this.setState({city:val})
    }
    this.setState({city:val},function(){
      let {province,city}=this.state;
      this.props.frUserLst({  city: [province,city] })
    })
  }

  componentDidMount() {
    this.props.setActiveUrl('/friends')
    this.props.frUserLst();
    let pros = []
    for (const province in All) {
      pros.push(province)
    }
    this.setState({ provinces: pros })
  }

  render() {
    let { frUserLst,userInfo } = this.props.user;
    let { provinces,citys,city } = this.state;

    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            交友列表
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col span={24}>
            <div className="main" id="friendpage">
              <div className="options">
                <ul>
                {provinces.length ?
                    <li>
                      <div className="label">地区：</div>
                      <div className="optionsItem">
                        <Select name="province" style={{ width: 200 }} placeholder="选择省份" onChange={this.onChange1}>
                          <Option value="">不限</Option>
                          {provinces.map((ele) => (
                            <Option value={ele} key={ele}>{ele}</Option>
                          ))}
                        </Select>
                        <Select name="province" style={{ width: 200 }} placeholder="选择城市"  onChange={this.onChange2} value={city}>
                          <Option value="">不限</Option>
                          {citys.map((ele) => (
                            <Option value={ele} key={ele}>{ele}</Option>
                          ))}
                        </Select>
                      </div>
                    </li>
                    : null}
                </ul>
              </div>
              {frUserLst ? (
                <List
                  dataSource={frUserLst}
                  pagination={{
                    pageSize: 16
                  }}
                  renderItem={item => (
                    <List.Item>
                      <Link
                        to={`/user/${item.user_id}`}
                        className="oneuser"
                      >
        
                        <img
                        src={item.user_thumb?item.user_thumb:(item.user_sex==='女')?require('common/img/user.png'):require('common/img/male.jpg')}
                        alt="图片"
                      />
                    
                       
                        
                            {item.user_level_fid===2?
                               <img src={require('common/img/vip.png')} alt="VIP" className="vipico"/>
                              :null}
                          {item.user_level_fid===3?
                               <img src={require('common/img/zuan.png')} alt="VIP"  className="vipico"/>
                              :null}
                          <div className="name">{item.user_nickname}</div>
                      </Link>
                    </List.Item>
                  )}
                />
              ) : null}
            </div>
          </Col>
        </Row>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
