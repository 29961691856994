import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/msg";
import PageBox from "components/public/userIndex";
import {List, Breadcrumb } from "antd";
import UserPub from "../public";
import UserLeft from "../left";

class EbookBuy extends Component {

  componentDidMount() {
    this.props.lst({pageSize:5,current:1 });
  }

  pages=(page)=>{
    this.props.lst({pageSize:5,current:page})
  }

  render() {
    let { msgLst } = this.props.user;
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>我的消息</Breadcrumb.Item>
        </Breadcrumb>
        <div className="user-center">
          <div className="user-public">
            <UserPub />
          </div>
          <div className="user-left">
            <UserLeft path={this.props.history.location.pathname} />
          </div>
          <div className="user-right">
            <div className="main">
              <div className="lst">
              <List
                  itemLayout="vertical"
                  size="large"
                  pagination={{
                    onChange: this.pages,
                    pageSize: 5,
                    total:msgLst?msgLst.total:0
                  }}
                  dataSource={msgLst?msgLst.data:[]}
                  renderItem={item => (
                    <List.Item
                      key={item.title}
                    >
                      <List.Item.Meta
                        title={<div>{item.msg_title}</div>}
                        description={item.msg_datetime}
                      />
                      <div>{item.msg_content}</div>
                    </List.Item>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(EbookBuy);
