import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/learn";
import PageBox from "../public/index";
import { Link } from 'react-router-dom'
import { Row, Col, Breadcrumb, Button, Space, Tabs, Tag, message } from "antd";
import RightLearnRec from './right'
import Axios from 'actions/request'

const { TabPane } = Tabs;
class HotNews extends Component {
  constructor() {
    super()
    this.onClick = this.onClick.bind(this)
    this.state = {
      video: null,
      audio: null,
      audioInfo: null,
      actions: []
    }
  }

  onClick(ele) {
    let uid = localStorage.getItem("uid")
    if (!uid) {
      this.props.login()
      return false;
    }
    message.info('请求中...')
    this.props.play(ele)

  }




  componentDidUpdate(props) {
    let { videoInfo } = this.props.learn;
    let id = this.props.match.params.id;
    let id2 = props.match.params.id;
    if (id !== id2) {
      this.props.course({ id });
      this.props.courseLst({ id });
      this.setState({ audio: null, video: null })
    }
    let { learnArticle } = this.props.learn;
    if (props.learn.learnArticle !== learnArticle && learnArticle) {
      document.title = learnArticle.ln_title + ' - ' + this.props.index.siteInfo.sitename;
    }
    if (props.learn.videoInfo !== videoInfo && videoInfo) {
      if (videoInfo['video_file'].indexOf('.mp4') >= 0) {
        this.setState({ video: videoInfo['video_file'], audio: null, audioInfo: videoInfo })
      } else {
        this.setState({ audio: videoInfo['video_file'], video: null, audioInfo: videoInfo })
      }
      document.title = learnArticle.ln_title + ' - ' + this.props.index.siteInfo.sitename;
    }
  }

  componentDidMount() {
    let id = this.props.match.params.id
    this.props.course({ id });
    this.props.courseLst({ id });
    this.getActions()
  }

  /**
  * 按钮列表
  */
  getActions = () => {
    let { id } = this.props.match.params;
    Axios.post('/api/v2/course/getActions', { id }).then(res => {
      this.setState({ actions: res.data.data })
    })
  }


  /**
   * 201 事件操作成功，收藏功能，刷新后重新请求新的页面数据
   * @param {*} ele 
   */
  onClick2 = (ele) => {
    let { params } = this.props.match;
    //人工添加参数，覆盖原有参数
    if (ele['params']) {
      params = { ...params, ...ele['params'] }
    }
    let { location } = this.props;
    Axios.post(ele['api'], { ...params, location }).then(res => {
      //收藏成功后，刷新页面重新请求数据
      const { data } = res.data;
      if (res.data.code === 201) {
        //其他类型事件
        message.success(res.data.msg)
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      } else if (res.data.code === 200) {
        this.props.showDialog("pay", data)
      }
    })
  }

  onLogin=()=>{
    this.props.login()
  }

  render() {
    let { learnChapters, learnArticle } = this.props.learn;
    let { audioInfo, actions } = this.state;
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/edu">学习频道</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            课程列表
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col md={24}>
            {learnArticle ?
              <div className="course">
                <img src={learnArticle.ln_thumb ? learnArticle.ln_thumb : require('common/img/nopic.png')} alt="缩略图" />
                <div className="courseinfo">
                  <div className="title">{learnArticle.ln_title}</div>
                  <div className="author">作者：{learnArticle.author ? learnArticle.author.author_name : null}</div>
                  <div className="desp">简介：{learnArticle.ln_desp}</div>
                  <div className="price">价格：<span>￥{learnArticle.ln_price}</span></div>
                  {learnArticle.ln_is_vipfree ?
                    <div className="price">VIP价格：<span>￥0</span></div>
                    : null}
                  <div className="actions playact">
                    <div className="act">
                      <Space>
                        {Array.isArray(actions) ? actions.map((ele, i) => (
                          <Button key={i} disabled={ele['disabled'] ? true : false} type={ele['type']} onClick={() => { this.onClick2(ele) }}>{ele['text']}</Button>
                        )):<Button type="primary" onClick={this.onLogin}>登录</Button>}
                      </Space>
                    </div>
                  </div>
                </div>
              </div>
              : null}
          </Col>
          <Col style={{ paddingTop: 30 }} md={17} xs={24}>
            {this.state.video ?
              <video autoPlay src={this.state.video} controls style={{ width: '100%' }}></video>
              : null}
            {learnArticle ?
              <Tabs type="card">
                <TabPane tab="课程介绍" key="1">
                  <div dangerouslySetInnerHTML={{ __html: learnArticle.ln_content }} />
                </TabPane>
                <TabPane tab="课程目录" key="2">
                  <ul className="videolst">
                    {learnChapters ? learnChapters.map(ele => (
                      <li key={ele.video_id} onClick={() => { this.onClick(ele) }} className={audioInfo && audioInfo['video_id'] === ele.video_id ? 'active' : null}>
                        <div className="titlebox">
                          <i className="iconfont icon-yuanhuan"></i>
                          <div className="title">{ele.video_is_free ? <Tag color="#87d068">免费</Tag> : null}{ele.video_title}</div>
                        </div>
                        <div className="courseact">
                          <i className="iconfont icon-play"></i>
                        </div>
                      </li>
                    )) : null}
                  </ul>
                </TabPane>
                <TabPane tab="老师介绍" key="3">
                  {learnArticle && learnArticle.author ?
                    <div dangerouslySetInnerHTML={{ __html: learnArticle.author.author_content }}></div>
                    : null}
                </TabPane>
              </Tabs>
              : null}
          </Col>
          <Col md={7} xs={24}>
            <RightLearnRec />
          </Col>
        </Row>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
