import Type from "./type";

import Conf from "../common/config";
import Axios from './request'
const { CP_FLOWER_LST } = Type;

/**
 * 列表
 * @param {*} args 
 */
export function lst(args) {
  return dispatch => {
    dispatch({ type: CP_FLOWER_LST, cpPayLst: null });
    Axios.post(Conf.api + "/cp_flower/lst", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: CP_FLOWER_LST, cpFlowerLst: res.data.data });
      }
    });
  };
}

/**
 * 文章详情
 * @param {*} args 
 */
export function article(id) {
  return dispatch => {
    dispatch({ type: CP_FLOWER_LST, article: null });
    Axios.post(Conf.api + "/cp_flower/index?id="+id).then(res => {
      if (!res.data.code) {
        dispatch({ type: CP_FLOWER_LST, cpFlowerInfo: res.data.data });
      }
    });
  };
}
