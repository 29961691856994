const indexInit = {
    
}
const medal = (state = indexInit, action) => {
    switch (action.type) {
      case "MEDAL_LST":
        return Object.assign({}, state, {
          ...action
        });
      default:
        return state;
    }
}

export default medal