import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions";
import { Cascader, Form } from "antd"


class UserForm extends Component {
  constructor() {
    super()
    this.state = {
      value: []
    }
  }

  componentDidMount() {
    let { item } = this.props;
    this.setState({ value: item.value})
    this.props.onChange(item.field, item.value)
  }

  handleClick = (e) => {
    e.target.focus();
  }

  handleChange = (value) => {
    let { item } = this.props;
    this.setState({ value })
    this.props.onChange(item.field, value)
  }

  filter = (inputValue, path) => {
    return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  }

  render() {
    let { item, index } = this.props;

    let options = item.options
    if (item.options === 'pca') {
      let opt = require('common/pca.json')
      let arr=[]
      for (const k in opt) {
        // console.log(k)//省
        let province={
          label:k,
          value:k,
          children:[]
        }
        for (const k2 in opt[k]) {
          let city={
            label:k2,
            value:k2,
            children:[]
          }
          if (opt[k][k2]) {
            opt[k][k2].forEach(ele => {
              let town={
                label:ele,
                value:ele
              }
              city.children.push(town)
            });
          }
          province.children.push(city)
        }
      arr.push(province)
      }
      options=arr
    }


    return <Form.Item
      label={item.title}
      key={item.field}
      name={item.field}
      rules={[
        {
          required: !!item.required,
          message: "请选择" + item.title + "!",
        }
      ]}
    >
      {item.options ? (
        <Cascader style={{ width: "100%" }}
          tabIndex={index}
          onChange={this.handleChange}
          changeOnSelect
          showSearch={{ filter: this.filter }}
          disabled={!!item.disabled}
          options={options}
        >
        </Cascader>
      ) : (
          <Cascader style={{ width: "100%" }} tabIndex={index}></Cascader>
        )}
    </Form.Item>
  }
}

export default connect(state => state, dispatch => bindActionCreators(actionsCreators, dispatch))(UserForm);