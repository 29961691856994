import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/index";

class App extends Component {


  render() {
    let { qipao } = this.props.index;
    let {book } = qipao;
    return (
        <div className="indexbook">
        <h3>
            <Link to="/pinpaihui/5">书籍</Link>
          </h3>
          <ul>
          {book ? book.map((ele, index) => (
            !index||index===1 ?<li className="indexBookItem" key={`sj${ele.qp_id}`}> <a href={`/qnarticle/${ele.qp_id}`} key={ele.qp_id}>
              <img src={ele.qp_thumb} alt="书刊" />
            </a></li> :
              <li key={`sj${ele.qp_id}`}><a href={`/qnarticle/${ele.qp_id}`}>
                ▪ <span>{ele.qp_title}</span>
              </a></li>
          )) : null}
          </ul>
        </div>
    )
  }
}


export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(App);
