import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/user";
import PageBox from "../public/index";
import { Link } from 'react-router-dom'
import { Breadcrumb, Button, Card, Input, message } from "antd";

class VipIntroduction extends Component {
  constructor() {
    super()
    this.state = {
      cardCode: null
    }
  }
  componentDidMount() {
    this.props.vipCardInfo()
  }

  handleChange = (e) => {
    this.setState({ cardCode: e.currentTarget.value })
  }

  active = () => {
    const { cardCode } = this.state;
    if (!cardCode) {
      message.info('卡号不能为空')
      return false;
    }
    this.props.activeCard({no:cardCode})
  }

  render() {
    let { userInfo } = this.props.user;
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            实体会员卡激活
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="wrapper">
          <h2 style={{textAlign:'center'}}>旗娘娘会员卡</h2>
          <div className="vipcard">
          {userInfo ?
            <Card style={{ width: 380 }}>
              <p>您好，{userInfo.user_nickname} ({userInfo.user_phone})</p>
              <p>激活码：<Input name="cardCode" onChange={this.handleChange} value={this.state.cardCode} /></p>
              <p>请输入13位数激活码，不区分大小写</p>
              <Button block type="primary" onClick={this.active}>立即激活</Button>
            </Card> : null}
          <Card style={{ width: 380 }}>
            <p>1. 登录网站账号后，输入激活码即可完成激活</p>
            <p>2. 激活成功后，可享受对应权益与服务</p>
            <p>3. 此卡严禁倒卖、转让，不可兑换现金，请妥善保管，遗失泄露等不补</p>
            <p>4. 更多相关问题，点击 <Link to="/vip">使用帮助</Link> 查看</p>
          </Card>
          </div>
         
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(VipIntroduction);
