import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/news";
import { Card } from "antd";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

class RightNews extends Component {

  componentDidMount() {
    this.props.helpRec();
  }

  render() {
    let { helpRec } = this.props.news;
    return (
      <div className="right">
        <Card title="热门问题" size="small">
          <ul className="topicrec">
            {helpRec
              ? helpRec.map(item => (
                  <li key={item.article_id}>
                    <Link to={`/article/${item.article_id}`}>
                      <span>{item.article_title.length>14?item.article_title.slice(0,14)+'...':item.article_title}</span>
                      <span>{moment(item.article_datetime).format('YY-MM-DD')}</span>
                    </Link>
                  </li>
                ))
              : null}
          </ul>
        </Card>
      </div>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
) (RightNews);
