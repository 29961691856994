import Type from "./type";

import Conf from "../common/config";
import { message } from "antd";
import FUNC from 'common/public'
import {showModal} from './index'
import {createOrder} from './goods'

import Axios from './request'
const {PAGE_AD, LEARN_RECORD_LST, LEARN_BUY_LST,LEARN_LST,LEARN_TYPE,LEARN_REC,LEARN_ARTICLE,LEARN_CHANNEL,LEARN_CHAPTER_LST,LEARN_VIDEO,ACTIVE_MENU,GLOBAL_SHOW_MODAL } = Type;




export function getAds2(arg){
  return dispatch => {
    Axios.get(Conf.api + "/banner/lst?type="+arg).then(res => {
      if (!res.data.code) {
        dispatch({ type: PAGE_AD, eduLstAds: res.data.data });
      }
    });
  };
}
export function setActiveUrl(page){
  return({type:ACTIVE_MENU,activeUrl:page})
}

/**
 * 教育频道
 */
export function channel(args) {
  return dispatch => {
    Axios.post(Conf.api + "/learn/channel", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: LEARN_CHANNEL, learnChannel: res.data.data });
      }
    });
  };
}

/**
 * 单个频道下的课程列表
 * @param {*} args 
 */
export function lst(args) {
  return dispatch => {
    Axios.post(Conf.api + "/learn/lst", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: LEARN_LST, learnLst: res.data.data });
      }
    });
  };
}

/**
 * 一个课程下的章节列表
 * @param {*} args 
 */
export function course(args) {
  return dispatch => {
    let uid=localStorage.getItem("uid")
    if(uid){
      args['uid']=uid;
    }
    Axios.post(Conf.api + "/learn/index", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: LEARN_ARTICLE, learnArticle: res.data.data });
      }
    });
  };
}

export function courseLst(args) {
  return dispatch => {
    Axios.post(Conf.api + "/video/lst", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: LEARN_CHAPTER_LST, learnChapters: res.data.data });
      }
    });
  };
}

export function learnRec(args) {
  return dispatch => {
    Axios.post(Conf.api + "/article/rec", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: LEARN_REC, learnRec: res.data.data });
      }
    });
  };
}
export function edurec(args) {
  return dispatch => {
    Axios.post(Conf.api + "/learn/rec", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: LEARN_REC, learnRec: res.data.data });
      }
    });
  };
}
//learnType
export function learnType() {
  return dispatch => {
    Axios.post(Conf.api + "/learn/learnType").then(res => {
      if (!res.data.code) {
        dispatch({ type: LEARN_TYPE, learnType: res.data.data });
      }
    });
  };
}

/**
 * 列表
 * @param {*} args 
 */
export function record(args) {
  return dispatch => {
    Axios.post(Conf.api + "/learn/readrecord", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: LEARN_RECORD_LST, learnRecordLst: res.data.data });
      }
    });
  };
}

export function buyRecord(args) {
  return dispatch => {
    Axios.post(Conf.api + "/learn/buyrecord", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: LEARN_BUY_LST, learnBuyLst: res.data.data });
      }
    });
  };
}



/**
 * 设置浏览记录
 * @param {*} args 
 */
export function setRead(args) {
  return dispatch => {
    Axios.post(Conf.api + "/learn_record/setread",args).then(res => {
      if (!res.data.code && res.data.data) {
        message.info(res.data.data)
      }
    });
  };
}

/**
 * 收藏与取消收藏
 * @param {*} args 
 */
export function collect(args) {
  let uid=localStorage.getItem("uid")
    if(!uid){
      return showModal('login')
    }

  return dispatch => {
    args['uid']=uid;
    Axios.post(Conf.api + "/learn_collect/collect",args).then(res => {
      if (!res.data.code) {
        window.location.reload()
        message.info(res.data.msg)
      }
    });
  };
}

/**
 * 购买课程
 * @param {*} args 
 */
export function buy(args) {
  let uid=localStorage.getItem("uid")
    if(!uid){
      return showModal('login')
    }
    args['uid']=uid
    return createOrder(args)
}

/**
 * 播放
 * @param {*} args 
 */
export function play(args) {
  let uid=localStorage.getItem("uid")

  return dispatch => {
    args['uid']=uid;
    Axios.post(Conf.api + "/video/play",args).then(res => {
      if (!res.data.code) {
        if(res.data.data&&!res.data.data.video_file){
          message.info('文件不存在')
        }else{
          if((res.data.data.video_file.indexOf('.mp3')==-1 &&  res.data.data.video_file.indexOf('.mp4')) || res.data.data.video_file.indexOf(",")>=0){
            message.info('非标准音频文件')
          }else{
            message.info('请求成功')
          }
        }
        dispatch({ type: LEARN_VIDEO, videoInfo: res.data.data });
      }
    });
  };
}

export function nextAudio(args) {
  let uid=localStorage.getItem("uid")

  return dispatch => {
    args['uid']=uid;
    Axios.post(Conf.api + "/video/playnext",args).then(res => {
      if (!res.data.code) {
        if(res.data.data&&!res.data.data.video_file){
          message.info('文件不存在')
        }
        if((res.data.data.video_file.indexOf('.mp3')==-1 &&  res.data.data.video_file.indexOf('.mp4')) || res.data.data.video_file.indexOf(",")>=0){
          message.info('非标准音频文件')
        }
        dispatch({ type: LEARN_VIDEO, videoInfo: res.data.data });
      }
    });
  };
}

export function login(){
  return showModal('login')
}


export function showDialog(arg,param){
  return { type: GLOBAL_SHOW_MODAL, showModal: arg,modalParams:param };
}