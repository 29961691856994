import React, { Component } from "react";
import QRCode from 'qrcode.react'
import { Card } from 'antd';

class PayPage extends Component {

  render() {
    let { params } = this.props.match

    return (
      <div className="wxqrcodepay">
        <Card title={params.title} extra={<span>订单号：{params.no}</span>}>
            <div className="paycode">
              <QRCode value={`weixin://wxpay/bizpayurl?pr=${params.qrcode}`} />
              <div>订单金额：{params.price}</div>
              <div className="paytip">微信扫码 立即支付</div>
              </div>
        </Card>
      </div>
    )
  }
}

export default PayPage;
