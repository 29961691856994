import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/learn";
import PageBox from "../public/index";
import {Link} from 'react-router-dom'
import { Row, Col, Breadcrumb, List,Tag,Button} from "antd";
import RightNews from './right'
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
// import { HomeOutlined } from "@ant-design/icons";
class HotNews extends Component {

  componentDidMount() {
    let {id}=this.props.match.params;
    this.props.lst({id});
  }
 
  render() {
    let { learnLst } = this.props.learn;
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/edu">知识频道</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            课程列表
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col md={17} xs={24}>
            <div className="main">
              {learnLst ?
              <List
              dataSource={learnLst}
              pagination={{
                pageSize: 10
              }}
              renderItem={item => (
                <List.Item>
                  <Link
                    to={`/course/${item.ln_id}`}
                    className="articleItem listenLst"
                  >
                    <div className="articleTitle">
                      {item.ln_thumb ? (
                        <img
                          src={item.ln_thumb}
                          alt="图片"
                        />
                      ) : (
                          <img
                            src={require('common/img/nopic.png')}
                            alt="图片"
                          />
                        )}
                      <div className="listTitleAndDesp">
                      <div className="articleTitle">{item.ln_title}</div>
                      <div className="articleDesp">{item.ln_desp}</div>
                      <div className="moreAndEvent">
                        <div className="tags">
                          <span className="price">￥{item.ln_price}</span>
                          {item.ln_price==='0.00'?
                          <Tag color="green">免费播放</Tag>
                          :(item.ln_is_vipfree?<Tag color="red">会员免费</Tag>:null)}
                          </div>
                        <div className="events"><Button type="primary">播放</Button></div>
                      </div>
                      </div>
                    </div>
                  </Link>
                </List.Item>
              )}
            />
              :null}
              </div>
          </Col>
          <Col md={7} xs={24}>
            <RightNews />
          </Col>
        </Row>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
