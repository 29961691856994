import Type from "./type";

import Conf from "../common/config";
import Axios from './request'
import {
  showModal
} from './index'
import { HashRouter } from "react-router-dom";
const { RECORD_LIST,MERCHANT,GLOBAL_SHOW_MODAL} = Type;



export function merchant_salon(args) {
  return dispatch => {
    Axios.post(Conf.api + "/merchant/salon", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: RECORD_LIST, merchant_salon: res.data.data });
      }
    });
  };
}

export function types() {
  return dispatch => {
    Axios.post(Conf.api + "/merchant/types").then(res => {
      if (!res.data.code) {
        dispatch({ type: RECORD_LIST, merchant_types: res.data.data });
      }
    });
  };
}

export function getMerchant(args) {
  return dispatch => {
    Axios.post(Conf.api + "/merchant/index",args).then(res => {
      if (!res.data.code) {
        dispatch({ type: MERCHANT, merchant: res.data.data });
      }
    });
  };
}

export function typeAll(args) {
  return dispatch => {
    Axios.post(Conf.api + "/merchant/allTypes",args).then(res => {
      if (!res.data.code) {
        dispatch({ type: RECORD_LIST, merchantTypeAll: res.data.data });
      }
    });
  };
}

/**
 * 指定分类的商家
 */
export function mchType(args) {
  return dispatch => {
    Axios.post(Conf.api + "/merchant/mchType",args).then(res => {
      if (!res.data.code) {
        dispatch({ type: RECORD_LIST, mchType: res.data.data });
      }
    });
  };
}


/**
 * 指定类型的商户列表
 */
export function getTypeMerchants(args) {
  return dispatch => {
    Axios.post(Conf.api + "/merchant/mchTypeMerchants",args).then(res => {
      if (!res.data.code) {
        dispatch({ type: RECORD_LIST, mchTypeMerchants: res.data.data });
      }
    });
  };
}

export function getCommentForm(args) {
  return dispatch => {
    Axios.post(Conf.api + "/merchant/commentForm",args).then(res => {
      if (!res.data.code) {
        dispatch({ type: RECORD_LIST, commentForm: res.data.data });
      }
    });
  };
}

/**
 * 一个商家的评论信息
 */
export function getComments(args) {
  return dispatch => {
    Axios.post(Conf.api + "/merchant/comments",args).then(res => {
      if (!res.data.code) {
        dispatch({ type: MERCHANT, comments: res.data.data });
      }
    });
  };
}


/**
 * 弹窗
 * @param {*} arg =shang
 * 
 */
 export function showDialog(arg,param){
  return { type: GLOBAL_SHOW_MODAL, showModal: arg,modalParams:param };
}

export function showLogin(args) {
  return showModal(args)
}

export function submitForm(args){
  return dispatch => {
    Axios.post(Conf.api + "/merchant/commentsave",args).then(res => {
      if (res.data.code===201) {
        let router=new HashRouter()
        router.history.goBack()
      }
    });
  };
}