import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/topic";
import PageBox from "../public/index";
import { Row, Col, Breadcrumb, Radio,List } from "antd";
import './topic.less';
import { Link } from 'react-router-dom';
import moment from "moment";
import "moment/locale/zh-cn";
import PageAd from './ad/index'
import Types from './type'

moment.locale("zh-cn");

class Ebook extends Component {
  constructor(){
    super()
    this.state={
      sign:1
    }
  }

  componentDidMount() {
    this.props.setActiveUrl('/topic')
    this.props.albumFlow({ sign: 1,pageSize: 18, current:1 });
    this.props.getAds(10)
    this.props.getTopicType()
  }

  pages = (page) => {
    const {sign}=this.state;
    this.props.albumFlow({ pageSize: 18, current: page,sign})
  }

  handleChange=(e)=>{
    let id = e.target.value;
    this.setState({sign:id})
    this.props.albumFlow({ sign: id,pageSize: 18,current:1 })
  }

  getAutoResponsiveProps = () => {
    return {
      itemMargin: 10,
      itemClassName: 'item',
      gridWidth: 20,
      transitionDuration: '.5'
    };
  };

  render() {
    let { topicLst } = this.props.topic;
    let { topicAds } = this.props.index;
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            旗袍圈
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col span={4} className="menuLeftBox">
            <div>
              <Types/>
              {topicAds && topicAds.length ?
                <PageAd data={topicAds} />
                : null}
            </div>
          </Col>
          <Col span={20}>
            <div className="topichead">
              <Radio.Group onChange={this.handleChange} style={{ padding: '10px 0' }} defaultValue={1}>
                <Radio.Button value={1}>最新</Radio.Button>
                <Radio.Button value={2}>人气</Radio.Button>
                <Radio.Button value={3}>关注</Radio.Button>
                <Radio.Button value={4}>推荐</Radio.Button>
              </Radio.Group>
              <Link to="/mtopic/lst" className="publish">发布我的旗袍圈</Link>
            </div>

            <div className="main qnngroup">
            <List
                dataSource={topicLst ? topicLst.data : []}
                pagination={{
                  onChange: this.pages,
                  pageSize: 18,
                  total: topicLst ? topicLst.total : 0
                }}
                renderItem={item => (
                  <List.Item>
                    <Link
                      to={`/subject/${item.topic_id}`}
                      className="articleItem"
                      key={item.topic_id}
                    >
                      <img src={item.topic_thumbs} alt="图片" />
                      <div className="title">{item.topic_content.substr(0, 16)}</div>
                    </Link>
                  </List.Item>
                )}
              />
            </div>
          </Col>
        </Row>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(Ebook);
