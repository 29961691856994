import React, { Component } from "react";
import { connect } from "react-redux";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/index";
import "common/css/app.css";
import Index from 'components/index/'
import { Spin, Modal, message } from "antd";
// import News from 'components/news/index'
//修身
import Learn from 'components/learn/index'
import Channel from 'components/learn/channel'
import LearnContent from 'components/learn/article'
//知识付费
import EduChannel from 'components/edu/channel'
import Edu from 'components/edu/index'
import Course from 'components/edu/course'

import Article from "components/news/article";
import UserLogin from 'components/user/login'
import UserLoginByPage from 'components/user/loginPage'
import UserLogout from "components/user/logout";
import UserReg from 'components/user/reg'
import UserForget from 'components/user/forget'
import Competition from 'components/competition/lst'
import CompetitionInfo from "components/competition/content/cominfo";
import Judgy from "components/competition/content/judgy";
import Master from "components/competition/content/master";
import Promotion from "components/competition/content/promotion";
import Rank from "components/competition/content/rank";
import CpUsers from "components/competition/content/users";
import CpVideo from "components/competition/content/video";
import Cpzanzhu from "components/competition/content/zanzhu";
import CpUserHomePage from 'components/competition/user';
import ComReportLst from 'components/competition/content/reportlst'
import ComReport from 'components/competition/content/report'
import CompetitionForm from 'components/competition/baomingForm'
//沙龙
import Salon from 'components/salon/lst';
import SalonInfo from 'components/salon/article';

// import Ebook from "components/ebook/index";
import OneBook from "components/ebook/onebook";
import Chapter from "components/ebook/chapter";
import AuthorBook from "components/author/index";
import AuthorLst from "components/author/lst";
// import TypeBooks from "components/ebook/typeBooks";
import Shop from "components/shop/lst";
import Goods from "components/shop/goods";
import Order from "components/order/order";
import City from 'components/city'

//会员中心
//个人相关
import UserSetting from "components/user/personal/setting";
import UserMedal from 'components/user/personal/medal';
import UserMsg from 'components/user/personal/msg';
import UserOrder from "components/user/personal/orders";
import Shouyi from "components/user/personal/shouyi";
import UserPromoter from "components/user/personal/promoter";
import UserPromoterRes from "components/user/personal/promoteRecord";
import UserScores from "components/user/personal/scoreRecord";
import UserUpgrade from "components/user/upgrade";
import Wallet from "components/user/personal/wallet";
import UserHome from 'components/friends/topic'
import UserGame from 'components/friends/game'
import UserTeam from 'components/friends/team'
import Vip from 'components/user/vipIntro'
import Yunyingguan from 'components/user/yunyingguan'
import MobileMenu from 'components/user/mmenu'
import VipCard from 'components/user/vipcard'
import RegSuccess from 'components/user/regsuccess'


//会员中心 商城
import UserAddr from "components/user/shop/addr";
import UserCart from "components/user/shop/cart";
import UserTicket from "components/user/shop/ticket";

//会员中心 空中书城
import UserEbookBuy from "components/user/ebook/buy";
import UserEbookRead from "components/user/ebook/read";
import UserEbookCollect from "components/user/ebook/collect";

//会员中心 旗袍圈
import UserFriend from "components/user/friend";
import UserTopic from "components/user/topic";
import Topic from "components/topic/topic";
import SUBJECT from "components/topic/subject";
import PinpaiHui from 'components/topic/lst';
// import QnView from 'components/topic/view';
// import QnVideo from 'components/topic/video';
// import QnLady from 'components/topic/lady';
// import QnVisit from 'components/topic/visit';

import QnArticle from 'components/topic/article';

//会员中心 赛事
import UserCp from "components/user/cp/lst";
import UserFlower from "components/user/cp/flower";
import UserSalon from "components/user/cp/salon";
import Dashang from 'components/competition/shang'
import SaishiTicket from 'components/competition/ticket'

//会员中心 学习
import UserLearnBuy from "components/user/learn/buy";
import UserLearnRecord from "components/user/learn/record";
import Gifts from 'components/gift/gift'
import WxQrcodePay from 'components/public/wxqrcodepay'
import CpRecord from 'components/layer/cpRecord'

//公共内容
import Maps from 'components/public/map'
import Contact from 'components/contact/index'
import Help from 'components/help/lst'

//商家
import Jiameng from 'components/form/agent'
import Peixun from 'components/form/training'
import CompetionForm from 'components/form/competition'
import Street from 'components/form/niangniangjie'
import Merchants from 'components/merchants/index'//商家营
import Publish from 'components/merchants/publish'
//交友
import Friends from 'components/friends/lst'
import ChangeCity from 'components/city.js'
import Cate from 'components/articles/index'
//单独的注册页面
import Reg from 'components/user/regpage'
import RegYunying from 'components/user/regYunying'
import Auth from 'components/order/auth'

import SearchPage from 'components/search/search'
import Yunying from "components/form/yunying";

import Doudouwen from "components/doudouwen/index";
import Merchant from "components/merchants/merchant";
import MchComment from 'components/merchants/comment'
import MchCommentForm from 'components/merchants/commentForm'
import MchType from 'components/merchants/types'

import Pay from 'components/pay/pay'
import PayCallback from 'components/pay/callback'


class App extends Component {

  componentDidMount() {
    this.props.siteMenu();
    this.props.siteInfo();
    // setToken("eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2ODg1NjM4NzcsIm5iZiI6MTY4ODU2Mzg3NywiZGF0YSI6eyJ1aWQiOjI3fX0.AZrAGWMACiIaU6cvVjdVnIq-jIKtjl5ofWNrgIekOig")
  }

  end = () => {
    let { videoInfo } = this.props.learn
    message.info('请求中...')
    this.props.nextAudio({ id: videoInfo['video_id'] })
  }

  componentDidUpdate(props) {
    const { videoInfo } = this.props.learn
    if (props.learn.videoInfo !== videoInfo && videoInfo) {
      var promise = document.querySelector("audio").play();
      if (promise !== undefined) {
        promise
          .catch(error => {
            Modal.confirm({
              title: videoInfo['video_title'],
              content: "当前环境不支持自动播放，请点击播放",
              onOk() {
                document.querySelector("audio").play()
              }
            })
          })
          .then(() => {
            // Auto-play started
          });
      }
    }
  }


  request = (url) => {
    return new Promise(resolve => {
      let xhr = new XMLHttpRequest();
      xhr.open('GET', url);
      // set response Type arraybuffer
      xhr.responseType = 'arraybuffer';
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          resolve(xhr.response);
        }
      };
      xhr.send();
    });
  }

  render() {
    let { siteMenu, showModal} = this.props.index;
    let { videoInfo } = this.props.learn
    let modalTitle = null;
    let modalWidth = 'auto'
    if (showModal === 'dashang') {
      modalTitle = "选手打赏"
    }else if(showModal === 'gifts') {
      modalTitle = "选择礼物"
      modalWidth = "70vw"
    }else if(showModal === 'pay') {
      modalTitle = "订单支付"
    }

    if (siteMenu) {
      return (
        <div>
          <Router>
            <Switch>
              <Route exact path="/" component={Index} />
              <Route path="/search" component={SearchPage} />

              {/* h5支付回调地址 */}
              <Route exact path="/h5pay-callback/:out_trade_no" component={PayCallback} />

              <Route exact path="/yunying" component={Yunying} />
              <Route exact path="/doudouwen" component={Doudouwen} />
              <Route exact path="/course/:id" component={Course} />
              <Route exact path="/auth" component={Auth} />
              <Route exact path="/help" component={Help} />
              <Route exact path="/edu" component={EduChannel} />
              <Route exact path="/edu/:id" component={Edu} />
              {/* 赛事个人资料编辑 */}
              <Route exact path="/competition-user-form/:id" component={CompetitionForm} />
              {/* <Route exact path="/news" component={News} /> */}
              <Route exact path="/vip" component={Vip} />
              <Route exact path="/yunyingguan" component={Yunyingguan} />
              <Route exact path="/mmenu" component={MobileMenu} />
              <Route exact path="/reg/:promoter?" component={Reg} />
              <Route exact path="/reg-yunying/:promoter?" component={RegYunying} />
              <Route exact path="/cate/:id" component={Cate} />
              <Route exact path="/changecity" component={ChangeCity} />
              <Route exact path="/user/:id" component={UserHome} />
              <Route exact path="/user/com/:id" component={UserGame} />
              <Route exact path="/user/team/:id" component={UserTeam} />
              <Route exact path="/map" component={Maps} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/competition" component={Competition} />
              <Route exact path="/cominfo/:id" component={CompetitionInfo} />
              <Route exact path="/cpuser/:id/:uid" component={CpUserHomePage} />
              {/* <Route exact path="/ebooktype/:id?" component={Ebook} /> */}
              <Route exact path="/ebook/:id" component={OneBook} />
              <Route exact path="/chapter/:id" component={Chapter} />
              <Route exact path="/author/:id" component={AuthorBook} />
              <Route exact path="/authors" component={AuthorLst} />
              <Route exact path="/shop" component={Shop} />
              <Route exact path="/goods/:id" component={Goods} />
              <Route exact path="/order/:id" component={Order} />
              <Route exact path="/article/:id" component={Article} />
              <Route exact path="/learn/:id" component={Learn} />
              <Route exact path="/learn" component={Channel} />
              <Route exact path="/read/:id" component={LearnContent} />
              <Route exact path="/friends" component={Friends} />

              {/* 赛事 */}
              <Route exact path="/com-judgy/:id" component={Judgy} />
              <Route exact path="/com-master/:id" component={Master} />
              <Route exact path="/com-promote/:id" component={Promotion} />
              <Route exact path="/com-rank/:id" component={Rank} />
              <Route exact path="/com-users/:id" component={CpUsers} />
              <Route exact path="/com-video/:id" component={CpVideo} />
              <Route exact path="/com-sponsor/:id" component={Cpzanzhu} />
              <Route exact path="/comreportlst/:id" component={ComReportLst} />
              <Route exact path="/comreport/:id" component={ComReport} />

              {/* 沙龙 */}
              <Route exact path="/salon/:id" component={SalonInfo} />
              <Route exact path="/salon" component={Salon} />


              {/* //会员中心 */}
              <Route exact path="/member/setting" component={UserSetting} />
              <Route exact path="/member/medal" component={UserMedal} />
              <Route exact path="/member/promoter" component={UserPromoter} />
              <Route exact path="/member/sytj" component={Shouyi} />
              <Route exact path="/member/promoterres" component={UserPromoterRes} />
              <Route exact path="/member/scores" component={UserScores} />
              <Route exact path="/member/msg" component={UserMsg} />
              <Route exact path="/member/upgrade" component={UserUpgrade} />
              <Route exact path="/member/order" component={UserOrder} />
              <Route exact path="/member/wallet" component={Wallet} />
              <Route exact path="/reg-success" component={RegSuccess} />
              <Route exact path="/wxqrcodepay/:title/:price/:no/:qrcode" component={WxQrcodePay} />

              {/* 商家 */}
              <Route exact path="/form/agent" component={Jiameng} />
              <Route exact path="/form/training" component={Peixun} />
              <Route exact path="/form/competition" component={CompetionForm} />
              <Route exact path="/street" component={Street} />
              <Route exact path="/merchants" component={Merchants} />
              <Route exact path="/publish" component={Publish} />
              <Route exact path="/merchant/:id?" component={Merchant} />
              <Route exact path="/mch_comment/:id" component={MchComment} />
              <Route exact path="/mch_type/:id" component={MchType} />
              <Route exact path="/mch_comment_form/:id" component={MchCommentForm} />

              {/* 会员商城 */}
              <Route exact path="/mshop/addr" component={UserAddr} />
              <Route exact path="/mshop/cart" component={UserCart} />
              <Route exact path="/mshop/ticket" component={UserTicket} />

              {/* 旗袍圈 */}
              <Route exact path="/topic" component={Topic} />
              <Route exact path="/pinpaihui/:id" component={PinpaiHui} />
              {/* <Route exact path="/qnview" component={QnView} />
              <Route exact path="/qnvideo" component={QnVideo} />
              <Route exact path="/qnlady" component={QnLady} />
              <Route exact path="/qnbook" component={QnBook} /> */}
              <Route exact path="/qnarticle/:id" component={QnArticle} />

              {/* SUBJECT */}
              <Route exact path="/subject/:id" component={SUBJECT} />
              {/* 我的好友 */}
              <Route
                exact
                path="/mtopic/friend"
                component={UserFriend}
              />
              {/* 我的旗袍圈 */}
              <Route
                exact
                path="/mtopic/lst"
                component={UserTopic}
              />
              {/* 会员赛事 */}
              <Route exact path="/mcomp/lst" component={UserCp} />
              <Route exact path="/mcomp/gift" component={UserFlower} />
              <Route exact path="/mcomp/salon" component={UserSalon} />
              <Route exact path="/cpticket/:id" component={SaishiTicket} />
              {/* <Route exact path="/ebooktype/:id" component={TypeBooks} /> */}


              {/* 会员空中书城 */}
              <Route exact path="/mbook/buy" component={UserEbookBuy} />
              <Route
                exact
                path="/mbook/collect"
                component={UserEbookCollect}
              />
              <Route exact path="/mbook/read" component={UserEbookRead} />

              {/* 会员学习 */}
              <Route exact path="/mlearn/buy" component={UserLearnBuy} />
              <Route exact path="/vipcard" component={VipCard} />
              <Route exact path="/mlearn/record" component={UserLearnRecord} />
              <Route exact path="/user/logout" component={UserLogout} />
              <Route exact path="/welogin" component={UserLoginByPage} />
              <Route path="*" component={Index} />
            </Switch>
          </Router>
          <Modal
            title={modalTitle}
            open={!!showModal}
            footer={null}
            destroyOnClose={true}
            width={modalWidth}
            centered={true}
            className={`modal-${showModal}`}
            onOk={this.handleOk}
            onCancel={() => {
              this.props.showModal(0);
            }}
          >
            <Router>
              {/* 登录 */}
              {showModal === "login" ? <UserLogin /> : null}
              {/* 注册 */}
              {showModal === "reg" ? <UserReg /> : null}
              {/* 忘记密码 */}
              {showModal === "forget" ? <UserForget /> : null}
              {/* 选择城市 */}
              {showModal === "city" ? <City /> : null}
              {/* 打赏 */}
              {showModal === "dashang" ? <Dashang /> : null}
              {/* 充值 */}
              {showModal === "gifts" ? <Gifts /> : null}
              {/* 赛事记录 */}
              {showModal === "cpRecord" ? <CpRecord /> : null}
              {/* 支付 */}
              {showModal === "pay" ? <Pay /> : null}
            </Router>
          </Modal>
          {videoInfo ?
            <div className="playedvideo">
              <div className="title">{videoInfo['video_title']}</div>
              <audio src={videoInfo['video_file']} controls onEnded={this.end} controlsList="nodownload"></audio>
              <i className="iconfont icon-guanbi1" onClick={this.props.closeAudio}></i>
            </div>
            : null}
        </div>
      );
    } else {
      return (
        <div className="flexCenter"><Spin tip="获取网站配置..." /></div>
      );
    }
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(App);
