import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/index";
import { Tabs, Spin, Button } from "antd";
//字段组件
import InputField from './form/input'
import CheckboxField from './form/checkbox'
import DateField from './form/date'
import FileField from './form/file'
import RichMediaField from './form/media'
import NumberField from './form/number'
import RadioField from './form/radio'
import SelectField from './form/select'
import SwitchField from './form/switch'
import TextareaField from './form/textarea'
import TimeField from './form/time'
import RangePickers from './form/rangePicker'
import CascaderField from './form/cascader'

import "./form/form.css"

const { TabPane } = Tabs;

class FormPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      fields: {},
      data: null, //表单数据结构
      previewVisible: false,
      previewImage: "", //预览图像内容
      submitStay: false
    };
  }


  /* 普通字段更新 */
  onChange = (field, value) => {
    let { data, fields } = this.state;
    data.forEach(ele => {
      if (ele.children) {
        ele.children.forEach(ele2 => {
          if (ele2.field === field) {
            ele2.value = value;
            fields[field] = value;
          }
        });
      }
    });

    this.setState({ data, fields });
    this.props.changeField(field, value);
  }

  componentDidMount() {
    this.props.onRef(this);
    const { data } = this.props;
    this.setState({ data });
  }

  render() {
    let { data} = this.state;

    //PC端表单构造
    if (data) {
      let hasGroup = data.find(ele => ele.children) ? true : false;
      let formComponents = (item, index) => {
        // 数字类型
        if (item.type === 'number') {
          return <NumberField item={item} key={item.field} onChange={this.onChange} index={index} />
        } else if (item.type === 'radio') {
          //单选
          return <RadioField item={item} key={item.field} onChange={this.onChange} index={index} />
        } else if (item.type === 'select') {
          //下拉列表
          return <SelectField item={item} key={item.field} onChange={this.onChange} index={index} />
        } else if (item.type === 'cascader') {
          //级联列表
          return <CascaderField item={item} key={item.field} onChange={this.onChange} index={index} />
        } else if (item.type === 'date' || item.type === 'datetime') {
          //日期类型
          return <DateField item={item} key={item.field} onChange={this.onChange} index={index} />
        } else if (item.type === 'RangePicker') {
          //日期范围
          return <RangePickers item={item} key={item.field} onChange={this.onChange} index={index} />
        } else if (item.type === 'time') {
          //时间类型
          return <TimeField item={item} key={item.field} onChange={this.onChange} index={index} />
        } else if (item.type === 'switch') {
          //开关类型
          return <SwitchField item={item} key={item.field} onChange={this.onChange} index={index} />
        } else if (item.type === 'checkbox') {
          //多选
          return <CheckboxField item={item} key={item.field} onChange={this.onChange} index={index} />
        } else if (item.type === 'textarea') {
          //多行文本
          return <TextareaField item={item} key={item.field} onChange={this.onChange} index={index} />
        } else if (item.type === 'file' || item.type === 'photo' || item.type === 'photos' || item.type === 'files') {
          //图片类型
          return <FileField item={item} key={item.field} onChange={this.props.changePhoto} index={index} />
        } else if (item.type === 'richMedia') {
          //富媒体
          return <RichMediaField item={item} key={item.field} onChange={this.props.changeField} index={index} />
        } else {
          //单行文本
          return <InputField item={item} key={item.field} onChange={this.onChange} index={index} />
        }
      }
      return (
        <div className="formComponent">
          {hasGroup ?
            <Tabs defaultActiveKey="1">
              {data.map((ele, index) => (
                <TabPane tab={ele.name} key={index + 1} forceRender={true}>
                  <div className={`formInline`}>
                    {ele.children.map((item, index) => {
                      return formComponents(item, index)
                    })}
                  </div>
                </TabPane>
              ))}
            </Tabs>
            :
            <div className={`formInline`}>
              {data.map((item) => {
                return formComponents(item)
              })}
            </div>
          }
        </div>
      );
    } else {
      return (
        <div style={{ textAlign: "center" }}>
          <Spin tip="获取数据中..." />
        </div>
      );
    }
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(FormPage);
