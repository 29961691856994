const indexInit = {

}
const learn = (state = indexInit, action) => {
  switch (action.type) {
    case "LEARN_RECORD_LST":
      return Object.assign({}, state, {
        ...action
      });
    case "LEARN_BUY_LST":
      return Object.assign({}, state, {
        ...action
      });
    case "LEARN_LST":
      return Object.assign({}, state, {
        ...action
      });
    case "LEARN_REC":
      return Object.assign({}, state, {
        ...action
      });
    case "LEARN_TYPE":
      return Object.assign({}, state, {
        ...action
      });
      case "LEARN_ARTICLE":
        return Object.assign({}, state, {
          ...action
        });
      case 'LEARN_CHANNEL':
        return Object.assign({}, state, {
          ...action
        });
      case 'LEARN_CHAPTER_LST':
        return Object.assign({}, state, {
          ...action
        });
      case 'LEARN_VIDEO':
        return Object.assign({}, state, {
          ...action
        });
    default:
      return state;
  }
}

export default learn