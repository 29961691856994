import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/user";
import PageBox from "../public/index";
import { Row, Col, Breadcrumb, Button } from "antd";
import { Link } from 'react-router-dom'
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

/**
 * 用户的公开个人主页，放在好友里面，展示旗袍圈的内容
 */
class UserHomePage extends Component {
  constructor() {
    super()
    this.state = {
      uid: null,
      id: null,
    };
  }

  addFriend = () => {
    let { id, uid } = this.state;
    if (uid) {
      this.props.addFriend({ id, uid })
    } else {
      this.props.showLogin('login')
    }
  }

  componentDidMount() {
    let { id } = this.props;
    this.props.userPublic({ id })
    let uid = localStorage.getItem("uid")
    if (uid) {
      this.setState({ uid, id })
    }
  }


  render() {
    let { user_public } = this.props.user;
    let { id, keyName } = this.props;

    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            个人主页
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col span={4}>
            <ul className="menuleft">
              <li className={keyName === "topic" ? "active" : null}><Link to={`/user/${id}`}><i className="iconfont icon-iconfontzhizuobiaozhunbduan36"></i><span className="title">朋友圈</span></Link></li>
              <li className={keyName === "game" ? "active" : null}><Link to={`/user/com/${id}`}><i className="iconfont icon--lady"></i><span className="title">赛事</span></Link></li>
              <li className={keyName === "team" ? "active" : null}><Link to={`/user/team/${id}`}><i className="iconfont icon--lady"></i><span className="title">组团</span></Link></li>
            </ul>
          </Col>
          <Col span={20}>
            <div className="main" id="userhome">
              {user_public ?
                <div className="userpub">
                  <div className="album">
                    <img src={user_public.user_thumb ? user_public.user_thumb : require('common/img/user.png')} alt="相册" />
                  </div>
                  <dl>
                    <dt>昵称：{user_public.user_nickname}</dt>
                    <dd>等级：{user_public.level_id === 2 ? <i className="iconfont icon-vip"></i> : null}{user_public.level_id === 3 ? <i className="iconfont icon-85"></i> : null}{user_public.level_name}</dd>
                    <dd style={{ paddingTop: 50 }}>
                      {user_public.guanzhu ?
                        <Button onClick={this.addFriend} type="default">取消关注</Button>
                        :
                        <Button onClick={this.addFriend} type="primary">关注</Button>}
                    </dd>
                  </dl>
                </div>
                : null}
              {this.props.children}
            </div>
          </Col>
        </Row>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(UserHomePage);
