import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/index";
import { Card } from "antd";
import { Link } from "react-router-dom";

class EbookModal extends Component {

  render() {
    let { author } = this.props.index;

    return (
      <Card
        title="作家专栏"
        size="small"
        extra={<Link to="/authors">更多</Link>}
        style={{ width: "100%" }}
        className="cardTabsBox cardAuthor"
      >
        <div className="cardBox authorLst">
          {author
            ? author.map(ele => (
              <div
                key={`author-${ele.author_id}`}
                className="author"
              >
                <Link to={`/author/${ele.author_id}`}>
                  <img src={ele.author_thumb} alt="作家" />
                  <div className="desp">{ele.author_desp ? ele.author_desp.substr(0, 70)+'...' : ele.author_desp}</div>
                </Link>
              </div>
            ))
            : null}
        </div>
      </Card>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(EbookModal);