import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/learn";
import PageBox from "components/public/userIndex";
import { Row, Col, Breadcrumb } from "antd";
import UserPub from "../public";
import UserLeft from "../left";
import FUNC from "common/public";

class LearnRecord extends Component {

  componentDidMount() {
    let uid = localStorage.getItem("uid");
    this.props.record({ uid: uid});
  }

  render() {
    let { learnRecordLst } = this.props.learn;
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href="/news">会员中心</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            浏览记录
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="user-center">
          <div className="user-public">
            <UserPub />
          </div>
          <div className="user-left">
            <UserLeft path={this.props.history.location.pathname} />
          </div>
          <div className="user-right">
            <div className="main">
              <div className="lst">
                <ul>
                  {learnRecordLst &&
                    learnRecordLst.map(item => (
                      <li>
                        <div>
                          <a href={`/learn/${item.ln_fid}`}>
                            <span>{item.learn_title}</span>
                          </a>
                        </div>
                        <div className="btnGroup">{item.ln_datetime}</div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(LearnRecord);
