import React from "react";

export default (props) => {
  let ads = props.data
  return (
    <div id="qipao-ads">
      {ads.map(ele => (
        <a href={ele.banner_link} rel="noopener noreferrer">
          <img src={ele.banner_thumb} alt="图片" />
        </a>
      ))}
    </div>
  );
}
