
// 获取cookie
function getCookie(key) {
    const name = key + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        const c = ca[i].trim();
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

// 设置cookie,默认是30天
function setCookie(key, value) {
    const d = new Date();
    d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toGMTString();
    document.cookie = key + "=" + value + "; " + expires+";path=/";
}

//删除cookie
function delCookie(name)
{
var exp = new Date();
exp.setTime(exp.getTime() - 1);
var cval=getCookie(name);
if(cval!=null)
document.cookie= name + "="+cval+";expires="+exp.toGMTString()+";path=/";
}



//生成从minNum到maxNum的随机数
function randomNum(minNum,maxNum){ 
    switch(arguments.length){ 
        case 1: 
            return parseInt(Math.random()*minNum+1,10); 

        case 2: 
            return parseInt(Math.random()*(maxNum-minNum+1)+minNum,10); 

            default: 
                return 0; 
    } 
}


function convertBase64UrlToBlob(urlData){  

    var bytes=window.atob(urlData.split(',')[1]);        //去掉url的头，并转换为byte  

    //处理异常,将ascii码小于0的转换为大于0  
    var ab = new ArrayBuffer(bytes.length);  
    var ia = new Uint8Array(ab);  
    for (var i = 0; i < bytes.length; i++) {  
        ia[i] = bytes.charCodeAt(i);  
    }  

    return new Blob( [ab] , {type : 'image/png'});  
}

//文件转为base64
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

/**
 * 获取state中的图片
 * @param {*} pics 
 */
function getStatePhotos(pics){
  let all={}
  for (const k in pics) {
    if (pics.hasOwnProperty(k)) {
      if(pics[k]){
        let picArr=[]
        for (const k2 in pics[k]) {
          if (pics[k].hasOwnProperty(k2)) {
            if(pics[k][k2].includes('upload')){
              let val=pics[k][k2].split('upload/')
              picArr.push(val[1])
            }else{
              picArr.push(pics[k][k2]);
            }
          }
        }
        all[k]=picArr.join(',')
      }
    }
  }
  return all;
}

/**
 * 根据文章参数，设置推广员
 */
function setPromoter(){
  let url=window.location.href
  if(url.includes('?uid=')){
    let urlArr=url.split('=')
    let promoter=urlArr[1]
    if(promoter){
      setCookie('promoter',promoter)
    }
  }
}

/**
 * 判断S是否为微信环境
 */
function is_wexin(){  
  var ua = navigator.userAgent.toLowerCase();  
  if(ua.match(/MicroMessenger/i)==="micromessenger") {  
      return 1;  
  } else {  
      return 0;  
  }  
}

//从 canvas 提取图片 image
function convertCanvasToImage(canvas) {
  //新Image对象，可以理解为DOM
  var image = new Image();
  // canvas.toDataURL 返回的是一串Base64编码的URL，当然,浏览器自己肯定支持
  // 指定格式 PNG
  image.src = canvas.toDataURL("image/png");
  return image;
}

export default {
  setCookie: setCookie,
  getCookie: getCookie,
  delCookie: delCookie,
  randomNum: randomNum,
  convertBase64UrlToBlob: convertBase64UrlToBlob,
  getBase64: getBase64,
  getStatePhotos: getStatePhotos,
  setPromoter:setPromoter,
  is_wexin:is_wexin,
  convertCanvasToImage:convertCanvasToImage
};
