import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/user";
import { Form, Button, Input, InputNumber } from "antd";
// import { Link } from "react-router-dom";
import FUNC from 'common/public'
class Login extends Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      time: 0
    };
  }

  handleClick = (e) => {
    e.target.focus();
  }

  getCode=()=>{
    this.formRef.current.validateFields(["phone"]).then(value => {
      this.props.sendSms({ phone: value.phone,sign:'reg' });
      let time = 60;
      let t = setInterval(() => {
        if (time <= 0) {
          clearInterval(t);
        }
        this.setState({ time: time-- });
      }, 1000);
    });
  }



  onFinish = values => {
    let uid=FUNC.getCookie('promoter')
    if(uid){
      values['promoter_uid']=uid
    }
    this.props.reg(values)
  };



  
  render() {
    return (
      <div className="login">
        <div className="login-tip">会员注册</div>
        <Form
          name="login"
          ref={this.formRef}
          layout="vertical"
          style={{ width: 300 }}
          onFinish={this.onFinish}
        >
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: "请输入正确格式手机号!",
                pattern: /^1[3456789]\d{9}$/
              }
            ]}
          >
            <Input placeholder="请输入手机号"  onClick={this.handleClick}/>
          </Form.Item>
          <Form.Item
            name="code"
            rules={[
              {
                required: true,
                message: "请输入验证码!"
              }
            ]}
          >
            <div style={{ display: "flex", justifyContent: "spaceBetween" }}>
              <InputNumber
                placeholder="请输入验证码"
                style={{ flexGrow: 1, marginRight: 10 }}
                onClick={this.handleClick}
              />
              {this.state.time ? (
                <Button type="ghost" disabled>
                  {this.state.time} s
                </Button>
              ) : (
                <Button type="ghost" onClick={this.getCode}>
                  获取验证码
                </Button>
              )}
            </div>
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "请输入密码"
              }
            ]}
          >
            <Input.Password placeholder="请输入密码" autoComplete="new-password"  onClick={this.handleClick}/>
          </Form.Item>
          {/* <Form.Item
            name="confirmpwd"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "请输入确认密码"
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject("密码不一致!");
                }
              })
            ]}
          >
            <Input.Password placeholder="请输入确认密码" />
          </Form.Item> */}

          {/* <Form.Item
            name="confirm"
            valuePropName="checked"
            rules={[
              {
                required: true,
                message: "同意协议才能注册"
              }
            ]}
          >
            <Checkbox checked={true}>已经阅读并同意</Checkbox>
            <Link to="item/service">旗娘娘服务条款</Link>
          </Form.Item> */}

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              立即注册
            </Button>
          </Form.Item>
        </Form>

        <div style={{ textAlign: "right", width: "100%" }}>
          <Button
            type="link"
            onClick={() => {
              this.props.showLogin("login");
            }}
          >
            已有账号? 立即登录
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(Login);
