import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/index";
import {Card, Tabs } from "antd";
import moment from "moment";
import "moment/locale/zh-cn";
import { Link } from "react-router-dom";

const { TabPane } = Tabs;
moment.locale("zh-cn");

class LearnPage extends Component {
  constructor() {
    super();
    this.onChangeLearn = this.onChangeLearn.bind(this);
  }

  onChangeLearn(key) {
    this.props.indexLearn(key);
  }

  render() {
      let { learn }=this.props.index;

    return (
      <Card
        title="在线学院"
        size="small"
        extra={<Link to="/edu">更多</Link>}
        style={{ width: "100%" }}
        className="cardTabsBox"
      >
        <Tabs onChange={this.onChangeLearn}>
          <TabPane tab="最新专辑" key="1">
            <div className="cardBox">
            {learn && learn[1]
                ? learn[1].map(ele => (
                    <Link
                      to={`/course/${ele.ln_id}`}
                      key={`learn-${ele.ln_id}`}
                    >
                      <div>
                        {ele.ln_title}
                      </div>
                      <div className="date">{moment(ele.ln_datetime).format("YYYY-MM-DD")}</div>
                    </Link>
                  ))
                : null}
            </div>
          </TabPane>
          <TabPane tab="推荐专辑" key="2">
            <div className="cardBox">
              {learn && learn[2]
                ? learn[2].map(ele => (
                    <Link
                      to={`/course/${ele.ln_id}`}
                      key={`learn-${ele.ln_id}`}
                    >
                      <div>
                        {ele.ln_title}
                      </div>
                      <div className="date">{moment(ele.ln_datetime).format("YYYY-MM-DD")}</div>
                    </Link>
                  ))
                : null}
            </div>
          </TabPane>
        </Tabs>
      </Card>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(LearnPage);