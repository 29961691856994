import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/user";
import PageBox from "./user";
import { List, Card } from "antd";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

/**
 * 用户的公开个人主页，放在好友里面，展示旗袍圈的内容
 */
class UserHomePage extends Component {


  componentDidMount() {
    let { id } = this.props.match.params;
    this.props.userTeam({ id })
  }



  render() {
    let { userTeam } = this.props.user;
    let { id } = this.props.match.params;
    return (
      <PageBox id={id} keyName="team">
        <Card title="组团记录" size="small" style={{ marginTop: 15 }}>
          {userTeam ? (
            <List
              dataSource={userTeam}
              className="userTopics"
              pagination={{
                pageSize: 10
              }}
              renderItem={item => (
                <List.Item>
                  <div>
                    <div>队名：{item.team_title}</div>
                    <div>创建人：{item.user_nickname}</div>
                    <div>成员数量：{item.team_user_count}</div>
                    <div>创建时间：{item.team_datetime}</div>
                  </div>
                </List.Item>
              )}
            />
          ) : null}
        </Card>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(UserHomePage);
