import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/index";
import { DownOutlined } from "@ant-design/icons";
import {Dropdown, Row, Col, Menu, Button } from "antd";
import { Link } from "react-router-dom";
import { getToken,clearToken} from "common/env";

class PublicTop extends Component {

  componentDidMount(){
    let token=getToken();
    if(token){
      this.props.userInfo()
    }
  }

  setMenu=(item)=>{
    if(item.menu_is_static){
      return <a href={item.menu_link}>{item.menu_name}</a>
    }
    return <Link to={item.menu_link}>{item.menu_name}</Link>
  }


  logout=()=>{
    clearToken();
    window.location.reload();
  }




  render() {
    let data = this.props.data.filter(item => item.menu_is_top);
    let token=getToken();
    let {userInfo}=this.props.user;
    return (
      <div className="site-top">
        <div className="wrapper">
          <Row>
            <Col span={24}>
              <div className="site-top-wrapper">
                <div className="site-top-section">
                  {token ? (
                    <div className="topuserinfo">
                      {userInfo?
                      <Link to="/user/index">
                      {userInfo.user_nickname}
                    </Link>
                      :null}
                      <span onClick={this.logout} style={{cursor:'pointer'}}>退出</span>
                    </div>
                  ) : (
                    <div>
                      <Button
                       size="small"
                        type="link"
                        style={{ color: "#333" }}
                        onClick={()=>{this.props.showModal("login")}}
                      >
                        登录|注册
                      </Button>
                    </div>
                  )}
                </div>
                {data.map(item => (
                  <div className="site-top-section top-menu" key={item.menu_id}>
                    {item.sub ? (
                      <Dropdown
                        overlay={
                          <Menu selectable={false}>
                            {item.sub.map(item2 => (
                              <Menu.Item key={item2.menu_id}>
                                <Link to={item2.menu_link}>
                                  {item2.menu_name}
                                </Link>
                              </Menu.Item>
                            ))}
                          </Menu>
                        }
                        placement="bottomLeft"
                        overlayClassName="site-top-sub"
                      >
                        <Link to={item.menu_link}>
                          {item.menu_name} <DownOutlined className="topIcon" />
                        </Link>
                      </Dropdown>
                    ) : (
                      this.setMenu(item)
                    )}
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(PublicTop);
