import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/agent";
import PageBox from "../public/agentindex";
import { Form, Input, Button } from "antd";

class HotNews extends Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      time: 0
    };
  }
  componentDidMount() {
    this.props.getFormContent({ sign: 'yunying' })
  }

  onFinish=(values)=>{
    let send = { ...values, f_type: 0 };
    let time = 3;
    let t = setInterval(() => {
      if (time <= 0) {
        clearInterval(t);
        this.formRef.current.resetFields();
      }
      this.setState({ time: time-- });
    }, 1000);
    send['f_type'] = '运营官申请';
    this.props.apply(send);
  }


  render() {
    return (
      <PageBox>
        <div id="jiamengimg">
          <img src={require('common/img/yunying1.jpg')} alt="图" />
          <div className="wrapper wrapper2">
            <img src={require('common/img/yunying2.jpg')} alt="图" />
            <img src={require('common/img/yunying3.jpg')} alt="图" />
            <img src={require('common/img/yunying4.jpg')} alt="图" />
            <img src={require('common/img/yunying5.jpg')} alt="图" />
            <img src={require('common/img/yunying6.jpg')} alt="图" />
          </div>
          <div className="wrapper">
            <img src={require('common/img/yunying7.jpg')} alt="图" />
            <Form
              name="apply"
              layout="vertical"
              ref={this.formRef}
              onFinish={this.onFinish}
            >
              <Form.Item
                name="f_name"
                rules={[
                  {
                    required: true,
                    message: "姓名不能为空"
                  }
                ]}
              >
                <Input placeholder="请输入姓名" autoComplete="off" />
              </Form.Item>
              <Form.Item
                name="f_phone"
                rules={[
                  {
                    required: true,
                    message: "手机号不能为空"
                  }
                ]}
              >
                <Input placeholder="请输入手机号" autoComplete="off" />
              </Form.Item>
              <Form.Item
                name="f_desp"
              >
                <Input.TextArea placeholder="请输入申请理由" autoComplete="off" rows={4} />
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  disabled={this.state.time ? true : false}
                >
                  提交{this.state.time ? this.state.time : null}
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="wrapper wrapper2">
            <img src={require('common/img/yunying8.jpg')} alt="图" />
          </div>
          <img src={require('common/img/yunying9.jpg')} alt="图" />
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
