import Type from "./type";
import Conf from "../common/config";
import Axios from './request'
const { XIUSHEN ,CONTACT,ACTIVE_MENU,PAGE_AD} = Type;



export function getAds(arg){
  return dispatch => {
    Axios.get(Conf.api + "/banner/lst?type="+arg).then(res => {
      if (!res.data.code) {
        dispatch({ type: PAGE_AD, learnLstAds: res.data.data });
      }
    });
  };
}

export function getAds2(arg){
  return dispatch => {
    Axios.get(Conf.api + "/banner/lst?type="+arg).then(res => {
      if (!res.data.code) {
        dispatch({ type: PAGE_AD, learnLstAds2: res.data.data });
      }
    });
  };
}
export function xiushen(args) {
  return dispatch => {
    Axios.post(Conf.api + "/cate/xiushen", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: XIUSHEN, xiushen: res.data.data });
      }
    });
  };
}

export function setActiveUrl(page){
  return({type:ACTIVE_MENU,activeUrl:page})
}

/**
 * 联系我们页面
 */
export function getContact() {
  return dispatch => {
    Axios.post(Conf.api + "/cate/contact").then(res => {
      if (!res.data.code) {
        dispatch({ type: CONTACT, contact: res.data.data });
      }
    });
  };
}

