import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/competition";
import PageBox from "../public/index";
import { Row, Col, Breadcrumb, Button, Card, Divider, Modal } from "antd";
import RightNews from "./right";
import HotUser from './hotUser'
import Album from './album/index'
import { Link, HashRouter } from 'react-router-dom'
import Baoming from './baomingForm'
class HotNews extends Component {
  constructor() {
    super()
    this.state = {
      visible: false,
      visibleBaoming: false,
      id: null
    }
    this.handleOk = this.handleOk.bind(this)
  }

  handleOk() {
    console.log(1)
  }

  editMy = () => {
    let { params } = this.props.match;
    let { id } = params;
    let router = new HashRouter()
    router.history.push('/competition-user-form/' + id)

  }


  componentDidMount() {
    let { params } = this.props.match;
    let { uid, id } = params;
    if (uid && id) {
      this.setState({ id })
      this.props.cpUser(params)
      this.props.mygifts(params)
    }
  }

  componentDidUpdate(props) {
    let { params } = this.props.match;
    let { id, uid } = params;
    if ((id && id !== props.match.params.id) || (uid && uid !== props.match.params.uid)) {
      this.props.cpUser(params)
      this.props.mygifts(params)
    }
  }

  render() {
    let { cpUserData,mygifts } = this.props.competition;
    let { params } = this.props.match;
    let { visibleBaoming, id } = this.state;
    let baoming = null;
    if (cpUserData) {
      document.title = `我是${cpUserData.cpu_id}号参赛选手${cpUserData.cpu_name}，正在参加${cpUserData.cp_title}比赛，请为我投票和礼物打榜！`
      if (cpUserData.bmButton.status === 1) {
        baoming = <Button type="primary" size="small" onClick={() => { this.props.createOrder({ id: id, type: 4 }) }}>选手报名</Button>
      } else if (cpUserData.bmButton.status === 2) {
        baoming = <Link to={`/competition-user-form/${id}`}>编辑我的报名信息</Link>
      } else if (cpUserData.bmButton.status === 3) {
        baoming = <Link to={`/competition-user-form/${id}`} style={{ color: 'red' }}>待完善报名信息</Link>
      } else {
        baoming = <Button size="small" disabled>{cpUserData.bmButton.msg}</Button>
      }
    }
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/competition">赛事</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>个人主页</Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col md={17} xs={24}>
            {cpUserData ?
              <div className="main">
                <div className="cp-user-info">
                  <div className="user-info-left">
                    <img src={cpUserData.cpu_xing_thumb ? cpUserData.cpu_xing_thumb : cpUserData.user_thumb} alt="头像" />
                    <div className="cp-user-detail">
                      <dl>
                        <dt>{cpUserData.cp_title}</dt>
                        <dd><span>选手：</span>{cpUserData.cpu_name ? cpUserData.cpu_name : cpUserData.user_nickname}</dd>
                        <dd><span>得票数：</span>{cpUserData.cpu_vote}</dd>
                        <dd><span>礼物旗币：</span>{cpUserData.cpu_gift_num}</dd>
                      </dl>
                      <div className="userActions">
                        <Button type="primary" onClick={() => { this.props.vote(params) }} disabled={!!cpUserData.vote.disabled}>{cpUserData.vote.text}</Button>
                        <Divider type="vertical" />
                        <Button type="primary" onClick={() => { this.props.sendGift(params) }}>送礼</Button>
                      </div>
                    </div>
                  </div>
                  <div className="user-right-actions">
                    {baoming}
                  </div>

                </div>
                <div className="user-content">
                  <Card title="基本资料" bordered={false}>
                    <dl>
                      <dt>姓名：{cpUserData.cpu_name}</dt>
                      <dd>性别：{cpUserData.cpu_sex}</dd>
                      <dd>身高：{cpUserData.cpu_height}</dd>
                      <dd>体重：{cpUserData.cpu_weight}</dd>
                      <dd>年龄：{cpUserData.cpu_age}</dd>
                      <dd>参赛宣言：{cpUserData.cpu_xuanyan}</dd>
                    </dl>
                  </Card>
                  <Card title="风采展示" bordered={false}>
                    {cpUserData.cpu_thumbs ?
                      <Album data={cpUserData.cpu_thumbs} />
                      : null}
                  </Card>
                  <Card title="选手介绍" bordered={false}>
                    <div dangerouslySetInnerHTML={{ __html: cpUserData.cpu_content }}></div>
                  </Card>
                  {mygifts?
                  <Card title="收到的礼物" className="mygifts" bordered={false} extra={<Link to="/mcomp/gift">收礼送礼记录</Link>}>
                    {mygifts.map(ele=>(
                      <div className="gift">
                        <img src={ele.gift_big_thumb} alt="礼物"/>
                    <p>数量：<span>{ele.total}</span></p>
                        </div>
                    ))}
                  </Card>:null}
                </div>
                <Modal
                  visible={visibleBaoming}
                  onCancel={() => { this.setState({ visibleBaoming: false }) }}
                  footer={null}
                >
                  <Baoming id={params.id} />
                </Modal>
              </div>
              : null}
          </Col>
          <Col md={7} xs={24}>
            <div className="right">
              <HotUser id={params.id} />
              <RightNews />
            </div>
          </Col>
        </Row>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
