import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/user";
import { Form, Button, Input, InputNumber, message } from "antd";

class Login extends Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      time: 0,
      loginType: 1,
      isWechat:false
    };
  }

  handleClick = (e) => {
    e.target.focus();
  }

  changeLoginType=()=>{
    let {loginType}=this.state;
    if(loginType===1){
      loginType=2
    }else{
      loginType=1
    }
    this.setState({loginType:loginType})
  }


  getCode=()=>{
    this.formRef.current.validateFields(["phone"]).then(value => {
      this.props.sendSms({ phone: value.phone, sign: 'login' });
      let time = 60;
      let t = setInterval(() => {
        if (time <= 0) {
          clearInterval(t);
        }
        this.setState({ time: time-- });
      }, 1000);
    });
  }
  onFinish = values => {
    let {loginType}=this.state;
    values['loginType']=loginType;
    this.props.login(values)
  };

  /**
   * 电脑扫码登陆
   */
  loginByWechat = () => {
    const callback = encodeURIComponent(`http://www.qiniangniang.com/#/welogin`)
    let url = `http://open.weixin.qq.com/connect/qrconnect?appid=wx30921e85b104780f&redirect_uri=${callback}&response_type=code&scope=snsapi_login&state=state#wechat_redirect`
    window.open(url)
  }


  componentDidMount(){
    var useragent = navigator.userAgent.toLowerCase();
    if (useragent.match(/MicroMessenger/i) == 'micromessenger') {
      this.setState({isWechat:true})
    }
  }

/**
 * 微信环境授权登陆
 */
  login=()=>{
    let {isWechat}=this.state;
    //微信环境登陆
    if (isWechat) {
      const callback = encodeURIComponent(`http://www.qiniangniang.com/#/welogin`)
      let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf9770f0d25a2c31a&redirect_uri=${callback}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
      window.location.href=url;
    }else{
      //pc登陆
      message.info('非微信环境')
    }
  }


  
  render() {
    let { loginType,isWechat} = this.state;
    return (
      <div className="login">
        <div className="login-tip">会员登录</div>
        <Form
          name="login"
          ref={this.formRef}
          layout="vertical"
          style={{ width: 300 }}
          onFinish={this.onFinish}
        >
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: "请输入正确格式手机号!",
                pattern: /^1[3456789]\d{9}$/
              }
            ]}
          >
            <Input placeholder="请输入手机号" onClick={this.handleClick} />
          </Form.Item>
          {loginType === 1 ?
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "请输入密码!"
                }
              ]}
            >
              <Input.Password placeholder="请输入密码" onClick={this.handleClick} />
            </Form.Item>
            :
            <Form.Item
              name="code"
              rules={[
                {
                  required: true,
                  message: "请输入验证码!"
                }
              ]}
            >
              <div style={{ display: "flex", justifyContent: "spaceBetween" }}>
                <InputNumber
                  placeholder="请输入验证码"
                  style={{ flexGrow: 1, marginRight: 10 }}
                  onClick={this.handleClick}
                />
                {this.state.time ? (
                  <Button type="ghost" disabled>
                    {this.state.time} s
                  </Button>
                ) : (
                    <Button type="ghost" onClick={this.getCode}>
                      获取验证码
                    </Button>
                  )}
              </div>
            </Form.Item>
          }
          
          <div style={{ textAlign: "right", width: "100%" }}>
            <Button
              type="link"
              onClick={this.changeLoginType}
            >
              {loginType===1?"验证码登陆":"密码登陆"}
          </Button>
            {isWechat?
              <Button type="link" onClick={this.login}><i className="iconfont icon-weixin"></i> 微信登陆</Button>
            :null}
          </div>
          <Form.Item style={{marginBottom:0}}>
            <Button type="primary" htmlType="submit" block>
              登录
            </Button>
          </Form.Item>
        </Form>
        <div style={{ textAlign: "right", width: "100%" }}>
          <Button
            type="link"
            onClick={() => {
              this.props.showLogin("forget");
            }}
          >
            忘记密码？
          </Button>
          <Button
            type="link"
            onClick={() => {
              this.props.showLogin("reg");
            }}
          >
            注册
          </Button>
         
        </div>
      </div>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(Login);
