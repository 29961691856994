import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/medal";
import PageBox from "components/public/userIndex";
import { Breadcrumb ,Button} from "antd";
import UserPub from "../public";
import UserLeft from "../left";
// import FUNC from "common/public";
// import {Link} from 'react-router-dom'

class LearnRecord extends Component {
  componentDidMount() {
    let uid = localStorage.getItem("uid");
    this.props.lst({ uid: uid });
  }

  render() {
    let { medalLst } = this.props.medal;
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            会员中心
          </Breadcrumb.Item>
          <Breadcrumb.Item>勋章列表</Breadcrumb.Item>
        </Breadcrumb>
        <div className="user-public">
            <UserPub />
          </div>
        <div className="user-center" id="medalpage">
          <div className="user-left">
            <UserLeft path={this.props.history.location.pathname} />
          </div>
          <div className="user-right">
            <div className="main">
              <div className="thumbLst">
                <ul className="medallst">
                  {medalLst &&
                    medalLst.map(item => (
                      <li key={item.medal_id} className={item.medal_get?'active':null} title={item.medal_get?'已获得':'未获得'}>
                          {item.medal_thumb ? (
                            <img src={item.medal_thumb} alt="头像" />
                          ) : (
                            <img src={require('common/img/avatar.png')} alt="头像" />
                          )}
                          <span className="title">{item.medal_title}</span>
                          <div style={{textAlign:'center'}}>{item.medal_get?<Button type="primary">已获得</Button>:<Button disabled>未获得</Button>}</div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(LearnRecord);
