import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/author";
import PageBox from "../public/index";
import { Link } from 'react-router-dom'
import { Row, Col, Breadcrumb, List,Input} from "antd";


class HotNews extends Component {
  constructor(props){
    super(props)
    this.search=this.search.bind(this)
  }
  search(value){
    this.props.lst({search:value})
  }
  componentDidMount() {
    this.props.lst()
  }

  render() {
    let { authors } = this.props.author;

    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            作家列表
          </Breadcrumb.Item>
        </Breadcrumb>
        <div style={{paddingTop:15}}>
          <Input.Search style={{width:300}} placeholder="作者姓名" onSearch={value => this.search(value)} enterButton/>
        </div>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col span={24}>
            <div className="main" id="friendpage">
              {authors ? (
                <List
                  dataSource={authors}
                  pagination={{
                    pageSize: 24
                  }}
                  renderItem={item => (
                    <List.Item>
                      <Link
                        to={`/author/${item.author_id}`}
                        className="oneuser"
                      >
                        <img
                              src={item.author_thumb?item.author_thumb:require('common/img/user.png')}
                              alt="图片"
                            />
                          <div className="name">{item.author_name}</div>
                      </Link>
                    </List.Item>
                  )}
                />
              ) : null}
            </div>
          </Col>
        </Row>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
