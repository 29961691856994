import React from "react";
import { Link } from "react-router-dom";

export default (props) => {
  let data = props.data.filter(item => item.menu_is_footer);
  let siteInfo=props.siteInfo;
  return (
    <div className="footer">
      <div className="wrapper footer-wrapper">
        <Link to="/">
          <img
            src={require("common/img/foot-logo.png")}
            alt="logo"
            className="footLogo"
          />
        </Link>
        <div className="footMenu">
          <ul>
            {data.map(item => (
              <li key={item.menu_id}>
                <Link to={item.menu_link}>{item.menu_name}</Link>
                {item.sub ? (
                  <div className="footSub">
                    {item.sub.map(item2 => (
                      <Link to={item2.menu_link}>{item2.menu_name}</Link>
                    ))}
                  </div>
                ) : null}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="wrapper copyright">
        &copy;2020 {siteInfo.sitename}{" "}
        <a href={siteInfo.beian_link} target="_blank" rel="noopener noreferrer">
          {siteInfo.beian}
        </a>
      </div>
    </div>
  );
};
