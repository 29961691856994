import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/competition";
import PageBox from "../public/index";
import { Link } from 'react-router-dom'
import { Row, Col, Breadcrumb, List } from "antd";
import RightNews from './right'
import PageAd from './ad/index'
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
class HotNews extends Component {
  constructor() {
    super()
    this.state = {
      city_id: null,
      time: null
    }
  }

  /**
   * 选择地区
   * @param {*} val 
   */
  onChange1=(val)=>{
    let { time } = this.state;
    this.setState({ city_id: val })
    let obj = {}
    if (val) {
      obj['city_id'] = val;
    }
    if (time) {
      obj['time'] = time;
    }
    this.props.lst(obj)
  }

  componentDidMount() {
    this.props.setActiveUrl('/competition')
    this.props.lst();
    this.props.getCitys()
    this.props.getAds(4)
  }


  changeType=(time)=>{
    this.setState({ time })
    let { city_id } = this.state;
    let obj = {}
    if (time) {
      obj['time'] = time;
    }
    if (city_id) {
      obj['city_id'] = city_id
    }
    this.props.lst(obj)
  }

  render() {
    let { competitionLst, cityLst } = this.props.competition;
    let {cpLstAds}=this.props.index;

    return (
      <PageBox>
        <Row gutter={{ xs: 0, md: 16 }} style={{ marginTop: 15 }}>
          <Col md={17} xs={24}>
            <div className="main">
              {cpLstAds && cpLstAds.length?
              <PageAd data={cpLstAds} />
              :null}
              <Breadcrumb>
                <Breadcrumb.Item>
                  <a href="/">首页</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  赛事列表
                </Breadcrumb.Item>  
              </Breadcrumb>
              <div className="options">
                <ul>
                  <li>
                    <div className="label">地区：</div>
                    <div className="optionsItem">
                      <dl onClick={() => { this.onChange1(null) }} className={this.state.city_id ? null : "active"}><dt>全部</dt></dl>
                      {cityLst ?
                        cityLst.map(ele => (
                          <dl onClick={() => { this.onChange1(ele.city_id) }} className={this.state.city_id === ele.city_id ? 'active' : null} key={ele.city_id}><dt>{ele.city_name}</dt></dl>
                        ))
                        : null}
                    </div>
                  </li>
                  <li>
                    <div className="label">状态：</div>
                    <div className="optionsItem">
                      <dl onClick={() => { this.changeType() }} className={this.state.time ? null : "active"}><dt>全部</dt></dl>
                      <dl onClick={() => { this.changeType(1) }} className={this.state.time === 1 ? "active" : null}><dt>进行中</dt></dl>
                      <dl onClick={() => { this.changeType(2) }} className={this.state.time === 2 ? "active" : null}><dt>已结束</dt></dl>
                    </div>
                  </li>
                </ul>
              </div>
              {competitionLst ? (
                <List
                  dataSource={competitionLst}
                  pagination={{
                    pageSize: 10
                  }}
                  renderItem={item => (
                    <List.Item>
                      <Link
                        to={`/cominfo/${item.cp_id}`}
                        className="articleItem"
                      >
                        <div className="articleTitle">
                          {item.cp_thumb ? (
                            <img
                              src={item.cp_thumb}
                              alt="图片"
                            />
                          ) : (
                              <img
                                src={require('common/img/nopic.png')}
                                alt="图片"
                              />
                            )}
                          <div className="titlebox">
                            <div className="articleTitle">{item.cp_title} </div>
                            <div className="articleDesp">{item.cp_desp}</div>
                            <div className="pubdate">{moment(item.cp_begin).format("YYYY-MM-DD")}</div>
                          </div>
                        </div>
                      </Link>
                    </List.Item>
                  )}
                />
              ) : null}
            </div>
          </Col>
          <Col md={7} xs={24}>
            <div className="right">
              <RightNews />
            </div>
          </Col>
        </Row>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
