import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/author";
import PageBox from "../public/index";
import { Link } from 'react-router-dom'
import { Row, Col, Breadcrumb, Card, List,Button} from "antd";

import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");


class HotNews extends Component {


  componentDidMount() {
    let { params } = this.props.match;

    this.props.authorInfo({ id: params['id'] })
    this.props.authorBooks({ id: params['id'] })
  }

  render() {
    let { authorInfo, authorBooks } = this.props.author;

    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/authors">作家列表</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            作家主页
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col span={24}>
            <div className="main" id="author">
              {authorInfo ? (
                <div className="authinfo">
                  <img src={authorInfo.author_thumb} alt="作家头像" />
                  <dl>
                    <dt>{authorInfo.author_name}</dt>
                    <dd>{authorInfo.author_desp}</dd>
                  </dl>
                </div>
              ) : null}
              <Card title="全部作品" bordered={false}>
                {authorBooks ?
                  <List
                    dataSource={authorBooks}
                    pagination={{
                      pageSize: 20
                    }}
                    renderItem={item => (
                      <List.Item>
                        <Link
                          to={`/ebook/${item.ebook_id}`}
                          className="ebook-item"
                        >
                          <img
                            src={item.ebook_thumb ? item.ebook_thumb : require('common/img/user.png')}
                            alt="图片"
                          />
                          <dl>
                            <dt>{item.ebook_title}</dt>
                            <dd>{item.ebook_desp}</dd>
                            <dd style={{textAlign:'right',paddingTop:10}}><Button type="primary"><Link to={`/ebook/${item.ebook_id}`}>书籍详情</Link></Button></dd>
                          </dl>
                        </Link>
                      </List.Item>
                    )}
                  />
                  : null}
              </Card>
            </div>
          </Col>
        </Row>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
