import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/ebook";
import PageBox from "../public/index";
import { Row, Col, Breadcrumb, Drawer, Spin, Tag, Button } from "antd";
import { Link } from 'react-router-dom'

class HotNews extends Component {
  constructor() {
    super()
    this.showDrawer = this.showDrawer.bind(this)
    this.onClose = this.onClose.bind(this)
    this.state = {
      visible: false
    }
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    let { id } = this.props.match.params;
    this.props.chapter({ id: id })
    this.props.chaptersByChapterNo({ no: id })
  }

  componentDidUpdate(props){
    let id = this.props.match.params.id;
    let id2=props.match.params.id;
    if(id!==id2){
      this.props.chapter({id})
      this.props.chaptersByChapterNo({ no: id })
    }
  }

  render() {
    let { chapter, chapters } = this.props.ebook;
    //判断是否有章节，是否是第一章，是否是最后一章

    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/ebook">空中书城</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col span={24}>
            {chapter ?
              <div className="main" id="chapter">
                <div className="ebookNav">
                  <div className="headItem" onClick={this.showDrawer}>
                    <i className="iconfont icon-mulu"></i><span>目录</span>
                  </div>
                </div>

                <div id="chapter-content">
                  <h1 style={{ textAlign: 'center'}}>{chapter.chapter_title}</h1>
                  <h2 style={{ textAlign: 'center' }}>{chapter.chapter_title2}</h2>
                  <div className="chapterInfo" style={{ paddingTop: 10 }}>
                    <span>{chapter.author_name}</span>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: chapter.chapter_content }}></div>
                  <div className="prevNext" style={{ textAlign: 'center' }}>
                    <Button onClick={() => { this.props.prevChapter({ id: chapter.chapter_no }) }}>上一章</Button>
                    <Button onClick={() => { this.props.nextChapter({ id: chapter.chapter_no }) }}>下一章</Button>
                  </div>
                </div>
              </div>
              : null}
            <Drawer
              title="章节"
              placement="right"
              closable={false}
              width={300}
              onClose={this.onClose}
              visible={this.state.visible}
              className="chapters"
            >
              {chapters ?
                chapters.map(ele => (
                  <Link to={`/chapter/${ele.chapter_no}`} key={ele.chapter_no}>{ele.chapter_title}{!ele.chapter_is_fee ? <Tag color="red" style={{ marginLeft: 5 }}>免费</Tag> : null}</Link>
                ))
                : <Spin />}
            </Drawer>
          </Col>
        </Row>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
