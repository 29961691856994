import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/medal";
import PageBox from "components/public/userIndex";
import { Button, Card, Divider } from "antd";
import {Link} from 'react-router-dom'

class LearnRecord extends Component {


  render() {

    return (
      <PageBox>
        <div className="center reg-success">
          <Card title="注册成功">
            <p>尊敬的用户：</p>
            <p>恭喜你成功注册旗娘娘会员！</p>
            <p><Button type="link">
              <Link to="/member/upgrade">升级VIP会员</Link></Button>
              <Divider type="vertical"/>
              <Button type="link"><Link to="/member/setting">完善个人资料</Link></Button></p>
          </Card>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(LearnRecord);
