import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/competition";
import { Card } from "antd";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

class RightNews extends Component {

  componentDidMount() {
    this.props.rec();
  }

  render() {
    let { competitionRec } = this.props.competition;
    return (
        <Card title="热门赛事" size="small">
          <ul className="topicrec">
            {competitionRec
              ? competitionRec.map(item => (
                  <li key={item.cp_id}>
                    <Link to={`/cominfo/${item.cp_id}`}>
                      <span>{item.cp_title.length>14?item.cp_title.slice(0,14)+'... ':item.cp_title}</span>
              <span>{moment(item.cp_datetime).format('YY-MM-DD')}</span>
                      
                    </Link>
                  </li>
                ))
              : null}
          </ul>
        </Card>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
) (RightNews);
