import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/address";
import { Divider, Button, Modal, Form } from "antd";
import FUNC from "common/public";
import AddrManage from "./addrManage";

const { confirm } = Modal;
class HotNews extends Component {
  formRef=React.createRef()
  constructor() {
    super();
    this.state = {
      visible: false,
      address: null
    };
    this.del = this.del.bind(this);
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.onFinish = this.onFinish.bind(this);
  }

  add() {
    this.setState({
      visible: true,
      address: null
    });
  }

  edit(data) {
    this.setState({
      visible: true,
      address: data
    });
  }

  handleCancel() {
    this.setState({ visible: false });
  }

  handleOk() {
    this.formRef.current.submit();
  }

  onFinish(values) {
    let obj = { ...values, addr_uid: localStorage.getItem("uid") }
    if (this.state.address) {
      obj['addr_id'] = this.state.address.addr_id;
    }
    this.props.save(obj)
    this.setState({ visible: false, address: null });
  }

  del(item) {
    let that = this;
    confirm({
      title: "确认删除?",
      content: "本次删除无法撤销",
      onOk() {
        that.props.del(item);
      }
    });
  }
  componentDidMount() {
    let uid = localStorage.getItem("uid");
    this.props.lst({ uid: uid });
  }

  render() {
    let { addressLst } = this.props.address;

    return (
      <div>
        <div className="main">
          <div style={{ textAlign: "right", paddingBottom: 15 }}>
            <Button type="primary" size="small" onClick={this.add}>
              新增地址
                </Button>
          </div>
          {addressLst ? (
            <div className="lst">
              <ul>
                {addressLst.map(item => (
                  <li key={item.addr_id}>
                    <div>
                      {item.addr_detail}
                      <Divider type="vertical" />
                      {item.addr_name}
                      <Divider type="vertical" />
                      {item.addr_phone}
                    </div>
                    <div className="btnGroup">
                      <Button
                        type="link"
                        onClick={() => {
                          this.props.onClick(item);
                        }}
                      >
                        选择
                          </Button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
              <div className="empty">
                <div>没有记录</div>
              </div>
            )}
        </div>
        <Modal
          title="地址管理"
          destroyOnClose={true}
          width="360px"
          visible={this.state.visible}
          cancelText="取消"
          okText="保存"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Form
            name="address"
            ref={this.formRef}
            initialValues={this.state.address}
            layout="vertical"
            style={{ width: "100%" }}
            onFinish={this.onFinish}
          >
            <AddrManage />
          </Form>
        </Modal>
      </div>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
