import Type from "./type";

import Conf from "../common/config";
import Axios from './request'
// import FUNC from 'common/public'
// import {message} from 'antd'
const { ARTICLE_LST,ARTICLE_TYPE,ARTICLE_INFO,ACTIVE_MENU } = Type;

export function setActiveUrl(page){
  return({type:ACTIVE_MENU,activeUrl:page})
}
export function lst(args) {
  return dispatch => {
    Axios.post(Conf.api + "/article/lst", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: ARTICLE_LST, articleLst: res.data.data });
      }
    });
  };
}

export function articleType(args) {
  return dispatch => {
    Axios.post(Conf.api + "/article/type", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: ARTICLE_TYPE, articleType: res.data.data });
      }
    });
  };
}

/**
 * 文章详情
 * @param {*} args 
 */
export function info(args) {
  return dispatch => {
    dispatch({ type: ARTICLE_INFO, article: null });
    Axios.post(Conf.api + "/article/index",args).then(res => {
      if (!res.data.code) {
        dispatch({ type: ARTICLE_INFO, article: res.data.data });
      }
    });
  };
}

/**
 * 
 * @param {*} args 
 */
export function cate(args) {
  return dispatch => {
    dispatch({ type: ARTICLE_INFO, cate: null });
    Axios.post(Conf.api + "/article/cate",args).then(res => {
      if (!res.data.code) {
        dispatch({ type: ARTICLE_INFO, cate: res.data.data });
      }
    });
  };
}
