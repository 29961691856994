import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/address";
import { Form, Input} from "antd";
// const {Option}=Select;

class Login extends Component {
  // constructor(props) {
  //   super(props);
  // }
  componentDidMount(){
   
  }
  render() {
    // let {areaLst}=this.props.address;

    return (
      <React.Fragment>
        <Form.Item
          name="ticket_company"
          rules={[
            {
              required: true,
              message: "公司名称!"
            }
          ]}
        >
          <Input placeholder="请输入公司名称" />
        </Form.Item>

        <Form.Item
          name="ticket_tel"
          rules={[
            {
              required: true,
              message: "开票电话不能为空!"
            }
          ]}
        >
          <Input placeholder="请输入公司电话" />
        </Form.Item>
        <Form.Item
          name="ticket_tax"
          rules={[
            {
              required: true,
              message: "税号不能为空!"
            }
          ]}
        >
          <Input placeholder="请输入公司税号" />
        </Form.Item>
      </React.Fragment>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(Login);
