import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/gift";

// import {Link} from 'react-router-dom'
import {Button,Spin, InputNumber,Modal} from 'antd';
import FUNC from 'common/public'

class HotNews extends Component {
  constructor(){
    super()
    this.zengsong=this.zengsong.bind(this)
    this.onChange=this.onChange.bind(this)
    this.song=this.song.bind(this)
    this.state={
      visible:false,
      gift:null,
      score:0,
      num:1
    }
  }

  song(){
    let {gift,num}=this.state;
    let {modalParams}=this.props.index;

    let obj={
        gift_fid:gift.gift_id,
        gift_cp_fid:modalParams.id,
        gift_user_bid:modalParams.uid,
        gift_user_aid:localStorage.getItem("uid"),
        gift_num:num
      }
    this.props.add(obj)
  }

  zengsong(ele){
    let score=parseFloat(ele.gift_price);
    this.setState({visible:true,gift:ele,score:score})
    // this.props.zengsong({gift_id:id,uid:modalData.uid})
  }

  onChange(val){
    let {gift}=this.state;
    let score=parseFloat(gift.gift_price);
    this.setState({score:score*val,num:val})
  }
  toPayMoney=()=>{
    let domain=window.location.protocol+"//"+window.location.host+'/#/member/wallet';
    window.open(domain)
  }

  componentDidMount() {
    this.props.lst()
  }

  render() {
    let {giftLst}=this.props.gift;
    let {gift,visible,score,num}=this.state;
    return (
      <div>
        <div style={{textAlign:'right',paddingBottom:15}}>
          <Button onClick={this.toPayMoney} ghost type="primary">充值</Button>
          <p>友情提示：1元=10旗币，为选手送礼物打榜加油吧！</p>
          </div>
      {giftLst?
      <ul className="gifts">
        {giftLst.map(ele=>(
          <li key={ele.gift_id}>
            {/* <img src={ele.gift_thumb} alt="礼物图片"/> */}
            <img src={ele.gift_big_thumb} alt="礼物图片" className="thumb"/>
            <dl>
              <dt>{ele.gift_name}</dt>
              <dt>{ele.gift_price} <span style={{fontSize:12,color:'#999'}}>旗币</span> </dt>
            </dl>
            <div style={{textAlign:'center'}}>
              <Button onClick={()=>{this.zengsong(ele)}} type="link">送礼</Button>
            </div>
          </li>
        ))}
      </ul>
      :<Spin/>}
      <Modal 
        title="选择数量" 
        visible={visible}
        okText="赠送"
        onCancel={()=>{this.setState({visible:false,num:1})}}
        className="giftbox"
        footer={<div>
          <Button onClick={this.toPayMoney} ghost type="primary">充值</Button>
          <Button onClick={this.song} type="primary">赠送</Button>
        </div>}
        width={300}
      >
        {gift?
          <div className="gift">
            <img src={gift.gift_big_thumb} alt="礼物图片"/>
          <div>{gift.gift_name}</div>
          <div className="num">
            <label style={{textAlign:'right'}}>数量：</label>
          <InputNumber name="num" defaultValue={1} min={1} onChange={this.onChange} value={num}/>
          </div>
          <div className="num score"><label style={{textAlign:'right'}}>所需旗币：</label><span>{score}</span></div>
          <div className="tip2">1元=10旗币，为选手送礼物打榜加油吧！</div>
          {/* <div className="tip">提示：如果旗币不足，账户余额将自动转换成对应数量的旗币使用。</div> */}
          </div>
        :null}
      </Modal>
      </div>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
