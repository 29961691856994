import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/promote";
import PageBox from "components/public/userIndex";
import { Breadcrumb, Card, Tabs } from "antd";
import { HashRouter } from 'react-router-dom'
import UserPub from "../public";
import UserLeft from "../left";
const { Meta } = Card;
const {TabPane}=Tabs;

class EbookBuy extends Component {

  componentDidMount() {
    this.props.lst();
  }

  jump = (item) => {
    let router = new HashRouter()
    router.history.push(`/user/${item.user_id}`)
  }


  render() {
    let { promoteLst } = this.props.user;
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            会员中心
          </Breadcrumb.Item>
          <Breadcrumb.Item>分享记录</Breadcrumb.Item>
        </Breadcrumb>
        <div className="user-public">
          <UserPub />
        </div>
        <div className="user-center" id="medalpage">
          <div className="user-left">
            <UserLeft path={this.props.history.location.pathname} />
          </div>
          <div className="user-right">
            <div className="main">
              <Card title="分享记录">
                {promoteLst?
                <Tabs>
                  {promoteLst.map(ele=>(
                    <TabPane tab={ele.level_name} key={ele.level_id}>
                    <div className="lst promoteRes">
                      {ele.children &&
                        ele.children.map(item => (
                          <Card
                            hoverable
                            onClick={() => { this.jump(item) }}
                            cover={<img alt="example" src={item.user_thumb ? item.user_thumb : require('common/img/avatar.png')} alt="头像" />}
                          >
                            <Meta title={`昵称：${item.user_nickname}`} description={<div>{item.level_name}<br />电话：{item.user_phone}<br />注册时间：{item.user_datetime}</div>} />
                          </Card>
                        ))}
                    </div>
                  </TabPane>
                  ))}
                </Tabs>
                :null}
              </Card>
            </div>
          </div>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(EbookBuy);
