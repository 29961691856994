import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/order";
import { Button,message} from "antd";
import {HashRouter} from 'react-router-dom'
class AuthPage extends Component {


  componentDidMount() {
    let id = this.props.match.params.id;
    this.props.orderInfo({ id: id });
    let url = window.location.search;
    console.log(url)
    let loc = url.substring(url.indexOf('=') + 1); 
    if(!loc){
      message.info('订单已过期或不存在')
    }else{
      let code=loc.split('&');
      let order=code[1].split('=')
      if(code[0]){
        this.props.wx_pay('code='+code[0]+'&out_trade_no='+order[1])
      }
    }
  }

jump=()=>{
  let router=new HashRouter()
  router.history.replace('/member/order')
}



  render() {
    return (
        <div className="pageCenter">
          <Button onClick={this.jump}>返回订单中心</Button>
          </div>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(AuthPage);
