import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/index";
import PageBox from "../public/index";
import { Row, Col, Card } from "antd";
import UserInfo from "./right/userinfo";
import Rank from "./right/rank";
import Banner from "./ad/";
import Competition from "./competition";
import Learn from './learn'
import Ebook from './ebook'
import Author from './author'
import Flink from './flink'
import IndexAd2 from './ad/smallads'
import Zhuanfang from './qipao/zhuanfang'
import Shuji from './qipao/shuji'
import Pinjian from './qipao/pinjian'
import Mingyuan from './qipao/mingyuan'
import Video from './qipao/video'
import Salon from './salon/salon'
import Fabuhui from './fabuhui/index'
import ShopBook from './right/shopbook'
import PageAd from './ad2/index'
import qs from 'qs'


class PageIndex extends Component {
  constructor() {
    super()
    this.onChangeLink = this.onChangeLink.bind(this)
  }

  onChangeLink(url) {
    window.open(url);
  }

  componentDidMount() {
    this.props.IndexInit();

    let url = window.location.href
    if (url.indexOf('?') >= 0) {
      let data = qs.parse(url.split('?')[1])
      let local=url.split('?')[0]
      if (data.id) {
        if(local.includes('/cominfo')){
          window.location.href=local+"/"+data.id
        }
      }
    }
  }



  render() {
    let { banner, flink, qipao, salon, fabuhui,usercard,indexAdCenter} = this.props.index;
    return (
      <PageBox>
        <div className="home">
          {/* banner */}
          {banner && banner.length ? (
            <div className="index-banner">
              <Banner data={banner} />
              <IndexAd2 data={banner} />
            </div>
          ) :null}
          <Row gutter={5} style={{ marginTop: 15,padding:15 }} className="indexLeft">
            <Col xs={24} sm={24} md={19} lg={19}>
              <Row gutter={16}>
                <Col sm={24} md={12} style={{marginBottom:15}}>
                  <Competition />
                </Col>
                <Col sm={24} md={12}>
                  <Learn />
                </Col>
                <Col span={24}>
                  {usercard?
                  <Card size="small" style={{ width: "100%", margin: "15px 0" }} className="userCard">
                    <Link to="/vip">
                      <img src={require('common/img/vipcard.jpg')} alt="会员卡" />
                    </Link>
                    <Link to="/yunyingguan">
                      <img src={require('common/img/yygcard.jpg')} alt="运营官" />
                    </Link>
                  </Card>:null}
                </Col>
                <Col sm={24} md={12}>
                  {salon ?
                    <div className="indexSalon">
                      <Salon />
                    </div>
                    : null}
                </Col>
                <Col sm={24} md={12}>
                  {fabuhui ?
                    <div className="indexFabuhui">
                      <h3><Link to="/salon/2">发布会</Link></h3>
                      <Fabuhui data={fabuhui} />
                    </div>
                    : null}
                </Col>
                <Col sm={24} md={12}>
                  <Ebook />
                </Col>
                <Col sm={24} md={12}>
                  <Author />
                </Col>
              </Row>
              {indexAdCenter && indexAdCenter.length?
              <PageAd data={indexAdCenter}/>
              :null}
              {qipao ?
                <Row className="qipao" gutter={10}>
                  <Col span={24}>
                    <h2><span>品牌荟</span></h2>
                  </Col>
                  <Col sm={24} md={12}>
                    {/* 专访/ */}
                    <Zhuanfang />
                  </Col>
                  {/* 名媛 */}
                  <Col sm={24} md={12}>
                    <Mingyuan />
                  </Col>
                  {/* 品鉴 */}
                  <Col sm={24} md={12}>
                    <Pinjian />
                  </Col>
                  {/* 书籍 */}
                  <Col sm={24} md={12}>
                    <Shuji />
                  </Col>
                  {/* 视频 */}
                  <Col sm={24} md={24}>
                    <Video />
                  </Col>
                </Row>
                : null}
            </Col>
            <Col sm={24} md={5} className="pageRight">
              <ShopBook/>
              <UserInfo />
              <Rank />
            </Col>
          </Row>
          {flink ? <Flink /> : null}
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(PageIndex);
