const Type = {
  //全局
  GLOBAL_SITE_MENU: "GLOBAL_SITE_MENU", //网站菜单
  GLOBAL_SITE_INFO: "GLOBAL_SITE_INFO", //网站配置信息
  GLOBAL_SHOW_MODAL: "GLOBAL_SHOW_MODAL", //弹窗
  GLOBAL_SITE_SEARCH:'GLOBAL_SITE_SEARCH',
  SITE_CITYS:"SITE_CITYS",//站点城市列表
  TOGGLE_MENU:"TOGGLE_MENU",//切换菜单
  CITYS:'CITYS',//城市列表
  ACTIVE_MENU:"ACTIVE_MENU",
  PAGE_AD:'PAGE_AD',
  RECORD_LIST:'RECORD_LIST',
  FORM_FIELDS:'FORM_FIELDS',

  //全站首页
  INDEX_BANNER: "INDEX_BANNER",
  INDEX_AUTHOR: "INDEX_AUTHOR",
  INDEX_COMPETITION: "INDEX_COMPETITION",
  INDEX_EBOOK: "INDEX_EBOOK",
  INDEX_FLINK: "INDEX_FLINK",
  INDEX_SALON: "INDEX_SALON",
  INDEX_QINIANG: "INDEX_QINIANG",
  INDEX_QIPAO: "INDEX_QIPAO",
  INDEX_RANK: "INDEX_RANK",
  INDEX_SERVICE: "INDEX_SERVICE",
  INDEX_LEARN: "INDEX_LEARN",
  INDEX_CONFERENCE: "INDEX_CONFERENCE",
  INDEX_GIFT_RANK:"INDEX_GIFT_RANK",//礼品榜
  INDEX_CITY:"INDEX_CITY",//城市榜
  INDEX_READER:"INDEX_READER",//城市榜
  INDEX_USERCARD:'INDEX_USERCARD',

  //会员相关
  USER_LOGIN: "USER_LOGIN", //会员登录，储存数据
  USER_INFO: "USER_INFO", //用户个人信息
  AREA_LST:"AREA_LST",//地址列表
  USER_PROFIT:"USER_PROFIT",//会员权益
  USER_HOME:"USER_HOME",//会员主页数据（旗袍圈）
  USER_PUBLIC:"USER_PUBLIC",//会员公开内容
  TICKET_LST:"TICKET_LST",//开票信息
  MEDAL_LST:"MEDAL_LST",//勋章
  USER_RELATION:"USER_RELATION",//我与会员的关系
  USER_GAME:"USER_GAME",//会员赛事列表
  USER_TEAM:"USER_TEAM",//会员组团列表
  SCORE_LST:"SCORE_LST",//会员旗币记录
  PROMOTE_LST:"PROMOTE_LST",//推广记录
  VIP_CARD:'VIP_CARD',
  SALON_RECORD:"SALON_RECORD",
  PROMOTE_PIC:'PROMOTE_PIC',
  QRCODE_BG:"QRCODE_BG",
  PROFIT_MONTH:"PROFIT_MONTH",
  OPENID:"OPENID",
  SCORE_TYPE:"SCORE_TYPE",

  //热点新闻
  NEWS_LST: "NEWS_LST", //热点新闻
  NEWS_REC: "NEWS_REC", //新闻推荐
  NEWS_TYPE:"NEWS_TYPE",//新闻栏目
  XIUSHEN:"XIUSHEN",
  //赛事
  COMPETITION_LST: "COMPETITION_LST", //赛事列表
  CP_PAY_LST: "CP_PAY_LST", //打赏记录
  CP_FLOWER_LST: "CP_FLOWER_LST", //鲜花记录
  COMPETITION_HOT_USERS:"COMPETITION_HOT_USERS",//赛事详情页面，推荐人气选手
  COMPETITION_USER:"COMPETITION_USER",//赛事用户主页信息
  CP_RECORD:"CP_RECORD",//赛事报名记录，表单数据
  USER_CP_LST:"USER_CP_LST",//用户参赛记录
  ACTIVITY_RECORD_LST:"ACTIVITY_RECORD_LST",//活动记录
  ACTIVITY_RECORD_INFO:"ACTIVITY_RECORD_INFO",//活动详情
  ACTIVITY_USERS:"ACTIVITY_USERS",//活动用户
  CP_RECORD_INFO:"CP_RECORD_INFO",//赛事报名详情
  SALON_LST:"SALON_LST",//沙龙列表
  SALON_USER:"SALON_USER",//沙龙用户
  CITY_LST:'CITY_LST',//城市列表
  COMPETITION_MENU:"COMPETITION_MENU",
  CP_TICKETS:"CP_TICKETS",
  COMPETITION_ARTICLE:"COMPETITION_ARTICLE",
  REPORT_ARTICLE:"REPORT_ARTICLE",
  CP_RANK:'CP_RANK',//排行榜
  COMPETITION_GIFTS:'COMPETITION_GIFTS',

  //学习
  LEARN_RECORD_LST: "LEARN_RECORD_LST", //学习记录
  LEARN_BUY_LST: "LEARN_BUY_LST", //购买记录
  LEARN_TYPE:"LEARN_TYPE",//学习分类
  LEARN_LST:"LEARN_LST",//学习文章列表
  LEARN_REC:"LEARN_REC",//推荐学习文章
  LEARN_ARTICLE:"LEARN_ARTICLE",//文章详情
  ARTICLE_LST:"ARTICLE_LST",
  ARTICLE_TYPE:"ARTICLE_TYPE",
  ARTICLE_INFO:"ARTICLE_INFO",
  LEARN_CHANNEL:"LEARN_CHANNEL",
  LEARN_CHAPTER_LST:'LEARN_CHAPTER_LST',
  LEARN_VIDEO:'LEARN_VIDEO',

  //空中书城
  EBOOK_LST: "EBOOK_LST", //空中书城列表
  EBOOK_TYPE: "EBOOK_TYPE", //空中书城分类
  EBOOK_INFO:"EBOOK_INFO",//
  EBOOK_CHAPTERS:"EBOOK_CHAPTERS",//电子书
  EBOOK_CHAPTER:"EBOOK_CHAPTER",
  EBOOK_RECORD_LST:"EBOOK_RECORD_LST",//阅读记录
  EBOOK_BUY_LST:"EBOOK_BUY_LST",//购买记录
  EBOOK_COLLECT:"EBOOK_COLLECT",//收藏
  //旗袍圈
  FRIEND_LST: "FRIEND_LST", //好友列表
  TOPIC_LST: "TOPIC_LST",//帖子列表
  TOPIC_INDEX:"TOPIC_INDEX",
  TOPIC_REC:"TOPIC_REC",
  TOPIC_COMMENT:"TOPIC_COMMENT",
  TOPIC_USER_LST:"TOPIC_USER_LST",
  QIPAO_LST:"QIPAO_LST",//旗袍文章列表
  QIPAO_ARTICLE:"QIPAO_ARTICLE",//旗袍文章
  //商城
  ADDRESS_LST:"ADDRESS_LST",//收件地址
  ORDER_LST:"ORDER_LST",//商城订单
  GOODS_LST:"GOODS_LST",//商品列表
  GOODS_TYPE:"GOODS_TYPE",//商品分类
  CART_LST:"CART_LST",//购物车
  GOODS_INFO:"GOODS_INFO",//商品详情
  ORDER_INFO:"ORDER_INFO",//订单详情
  PAY_URL:"PAY_URL",//支付二维码获取
  GIFT_LST:"GIFT_LST",//礼物列表
  ORDER_TYPE_LST:"ORDER_TYPE_LST",//订单分类
  TOPIC_TYPE:"TOPIC_TYPE",
  //交友
  FRIEND_USER_LST:"FRIEND_USER_LST",//交友用户列表
  //其他
  FORM_CONTENT:"FORM_CONTENT",//表单内容
  CONTACT:"CONTACT",
  AUTHORS:"AUTHORS",
  AUTHOR_INDEX:"AUTHOR_INDEX",
  AUTHOR_BOOKS:"AUTHOR_BOOKS",
  SALE_BOOK:"SALE_BOOK",
  MERCHANT:"MERCHANT",

  //斗斗文
  DOU_CHANNEL:"DOU_CHANNEL"
};

export default Type;