import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/index";
import { Card, Tabs } from "antd";
const { TabPane } = Tabs;
class App extends Component {
  constructor() {
    super()
    this.callback = this.callback.bind(this)
    this.callback2 = this.callback2.bind(this)
  }

  callback(key) {
    this.props.indexCity({ sign: key })
  }
  callback2(key) {
    this.props.indexReader({ sign: key })
  }

  render() {
    let { qiniang, cityRank, indexReader } = this.props.index;
    return (
      <div className="page-right">
        <Card
          title="城市榜"
          size="small"
          className="cityRank"
          style={{ width: "100%", marginTop: 15 }}
        >
          <Tabs defaultActiveKey="1" onChange={this.callback}>
            <TabPane tab="城市榜" key="1">
              {cityRank ? cityRank.map(ele => (
                <Link to={`/city/${ele.city_id}`} key={`cs${ele.city_id}`}>{ele.city_name}</Link>
              )) : null}
            </TabPane>
            <TabPane tab="精英榜" key="2">
              {cityRank ? cityRank.map(ele => (
                <Link to={`/city/${ele.city_id}`} key={`jy${ele.city_id}`}>{ele.city_name}</Link>
              )) : null}
            </TabPane>
          </Tabs>
        </Card>
        <Card
          title="读书榜"
          size="small"
          className="userRank"
          style={{ width: "100%", marginTop: 15 }}
        >
          <Tabs defaultActiveKey="1" onChange={this.callback2}>
            <TabPane tab="周榜" key="1">
              {indexReader ? indexReader.map(ele => (
                <Link className="user" to={`/user/${ele.user_id}`} key={`zb${ele.user_id}`}>
                  <span>{ele.user_nickname}</span>
                </Link>
              )) : null}
            </TabPane>
            <TabPane tab="月榜" key="2">
              {indexReader ? indexReader.map(ele => (
                <Link className="user" to={`/user/${ele.user_id}`} key={`yb${ele.user_id}`}>
                  <span>{ele.user_nickname}</span>
                </Link>
              )) : null}
            </TabPane>
            <TabPane tab="年榜" key="3">
              {indexReader ? indexReader.map(ele => (
                <Link className="user" to={`/user/${ele.user_id}`} key={`nb${ele.user_id}`}>
                  <span>{ele.user_nickname}</span>
                </Link>
              )) : null}
            </TabPane>
          </Tabs>
        </Card>
        {qiniang ?
          <Card
            title="会员风采"
            size="small"
            extra={<Link to="/friends">更多</Link>}
            className="userRank"
            style={{ width: "100%", marginTop: 15 }}
          >
            {qiniang.map(ele => (
              <Link className="user" to={`/user/${ele.user_id}`} key={`fc${ele.user_id}`}>
                <img src={ele.user_thumb ? ele.user_thumb : (ele.user_sex==='女'?require('common/img/user.png'):require('common/img/male.jpg'))} alt="图" />
                <span>{ele.user_nickname}</span>
                {ele.user_level_fid===2?<img src={require('common/img/vip.png')} alt="vip" className="vipico"/>:null}
                {ele.user_level_fid===3?<img src={require('common/img/zuan.png')} alt="vip" className="vipico"/>:null}
              </Link>
            ))}
          </Card>
          : null}
      </div>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(App);
