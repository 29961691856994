import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/topic";
import { HashRouter, Link } from 'react-router-dom';


class QnType extends Component {

    componentDidMount() {
        this.props.getTopicType()
    }

    handleClick=(ele)=>{
        if(ele.type_link){
            window.location.href=ele.type_link
        }else{
            let router=new HashRouter()
            router.history.push(`/pinpaihui/${ele.type_id}`)
        }
    }


    render() {
        let { topicType } = this.props.topic;
        const {id}=this.props;
        return (
            <ul className="menuleft">
                {topicType ? topicType.map(ele => (
                    <li key={ele.type_id} onClick={()=>{this.handleClick(ele)}} className={id ?(ele.type_id===parseInt(id)?'active':''):(ele.type_link?'active':'')}>
                        {ele.type_thumb?<img src={ele.type_thumb} alt="图标"/>:null}
                        <span className="title">{ele.type_title}</span>
                    </li>
                )) : null}
            </ul>
        );
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators(actionsCreators, dispatch)
)(QnType);
