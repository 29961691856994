import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/cpUser";
import PageBox from "components/public/userIndex";
import { Link } from 'react-router-dom'
import { Row, Col, Breadcrumb, List, Divider,Spin,Button } from "antd";
import UserPub from '../public'
import UserLeft from '../left'
import FUNC from 'common/public'

class HotNews extends Component {

  componentDidMount() {
    let uid = localStorage.getItem("uid")
    this.props.lst({ uid: uid });
  }

  render() {
    let { cpUserLst } = this.props.cpuser;

    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
           会员中心
          </Breadcrumb.Item>
          <Breadcrumb.Item>我的赛事</Breadcrumb.Item>
        </Breadcrumb>
        <div className="user-center">
          <div className="user-public">
            <UserPub />
          </div>
          <div className="user-left">
            <UserLeft path={this.props.history.location.pathname} />
          </div>
          <div className="user-right">
            <div className="main">
              {cpUserLst ? (
                <div className="mycplst">
                  <List
                    dataSource={cpUserLst}
                    pagination={{
                      pageSize: 10
                    }}
                    renderItem={item => (
                      <List.Item>
                        <dl>
                          <dt>
                            <Link
                              to={`/cominfo/${item.cp_id}`}
                            >
                              <span>{item.cp_title}</span>
                              <span>开始：{item.cp_begin}</span>
                            </Link>
                          </dt>
                          <dd>
                    <span>报名时间：{item.cpu_datetime}</span>
                    {/* <Divider type="vertical"/> */}
                    {/* <Button onClick={()=>{this.props.showModal('cpRecord',{id:item.cpu_id})}}>报名记录</Button> */}
                    <Divider type="vertical"/>
                    <Link to={`/cpuser/${item.cp_id}/${item.cpu_uid}`} className="red">我的主页</Link>
                          </dd>
                        </dl>
                      </List.Item>
                    )}
                  />
                </div>
              ) : (
                <Spin/>
                )}
            </div>
          </div>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
