import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/learn";
import PageBox from "../public/index";
// import {Link} from 'react-router-dom'
import { Row, Col,Breadcrumb } from "antd";
import RightNews from './right'

import FUNC from 'common/public'

class HotNews extends Component {

  componentDidMount() {
    let id=this.props.match.params.id
    this.props.article({id});
    let uid=localStorage.getItem("uid")
    if(uid){
      this.props.setRead({id,uid})
    }
  }

  componentDidUpdate(props){
    let { article } = this.props.learn;
    if(props.learn.article!==article && article){
      document.title=article.learn_title+' - '+this.props.index.siteInfo.sitename;
    }
  }

  render() {
    let { learnArticle } = this.props.learn;

    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href="/learn">学习修身</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            文章详情
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col md={17} xs={24}>
            <div className="main">
              {learnArticle ? (
                <div className="article">
                  <h1>{learnArticle.learn_title}</h1>
                  <div className="article-date">
                    <span>发布时间：{learnArticle.learn_auto_addtime}</span>
                  </div>
                  <div
                    className="article-content"
                    dangerouslySetInnerHTML={{
                      __html: learnArticle.learn_content
                    }}
                  ></div>
                </div>
              ) : null}
            </div>
          </Col>
          <Col md={7} xs={24}>
            <RightNews />
          </Col>
        </Row>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
