import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions";
import { Switch, Form } from "antd"


class UserForm extends Component {
  constructor() {
    super()
    this.state = {
      value: null
    }
  }

  componentDidMount() {
    let { item } = this.props;
    this.setState({ value: item.value })
    this.props.onChange(item.field, !!item.value)
  }



  handleChange = (field, value) => {
    this.setState({ value })
    this.props.onChange(field, value)
  }

  render() {
    let { item } = this.props;
    let {value}=this.state;


    return <Form.Item
      label={item.title}
      key={item.field}
      name={item.field}
    >
      <Switch
        checked={value ? true : false}
        checkedChildren="开启" unCheckedChildren="关闭"
        onChange={e => {
          this.handleChange(item.field, e);
        }}
        disabled={!!item.disabled}
      />
    </Form.Item>
  }
}

export default connect(state => state, dispatch => bindActionCreators(actionsCreators, dispatch))(UserForm);