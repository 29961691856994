import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions";
import { Upload, Modal } from "antd"
import { PlusOutlined } from "@ant-design/icons";
import Conf from "common/config";
import FUNC from 'common/until'

class FormFile extends Component {
  constructor() {
    super()
    this.state = {
      previewVisible: false,
      previewImage: null
    }
  }

  componentDidMount() {
    let { item } = this.props;
    const obj = {};
    let arr = []//图片数组

    if (item.value) {
      if (item.value.indexOf(',')) {
        item.value.split(',').map(url => {
          let file = {
            uid: Math.random(),//UID为新增，删除关联唯一字段
            url: url,
            name: item.title,
            thumbUrl: url,
            status: 'done'
          }
          arr.push(file)
          this.props.onChange(item.field, 'add', file)
        })
      } else {
        const file = {
          uid: Math.random(),//UID为新增，删除关联唯一字段
          url: item.value,
          name: item.title,
          thumbUrl: item.value,
          status: 'done'
        }
        arr.push(file)
        this.props.onChange(item.field, 'add', file)
      }
    }

    obj[item.field] = arr;
    this.setState(obj)
  }


  //--------------------图片处理--------
  handleCancel = () => this.setState({ previewVisible: false });


  // 图片预览
  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await FUNC.getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true
    });
  };


  //图片删除，上传
  handleChange = async ({ file, fileList }, field) => {
    //把图片地址存放到上层组件
    if (file.status === "done") {
      file['url'] = file.response
      this.props.onChange(field, 'add', file);
    }
    if (file.status === "removed") {
      this.props.onChange(field, 'remove', file);
    }
    //本页面的图片展示
    let obj = {};
    obj[field] = fileList;
    this.setState(obj);
  };

  //---------------图片处理 end------------------

  render() {
    let { item } = this.props;
    const { previewVisible, previewImage } = this.state;
    const states = this.state;

    let show = true
    if (states[item.field] && states[item.field].length) {
      show = false
    }
    if (item.type === 'photos' || item.type === 'files') {
      show = true
    }

    return <div className="formThumb" style={{paddingLeft:100}}>
            <div key={item.field} className="thumbBox">
            <Upload
              action={Conf.upload}
              headers={{ token: localStorage.getItem('token') }}
              listType="picture-card"
              onChange={e => {
                this.handleChange(e, item.field);
              }}
              fileList={
                states[item.field] ? states[item.field] : []
              }
              onPreview={this.handlePreview}
            >
              {show ? <div>
                <PlusOutlined />
                <div className="ant-upload-text">上传</div>
              </div> : null}
            </Upload>
            <div className="fieldTitle">
              {item.title}
            </div>
            <Modal
              visible={previewVisible}
              footer={null}
              onCancel={this.handleCancel}
            >
              <img
                alt={item.title}
                style={{ width: "100%" }}
                src={previewImage}
              />
            </Modal>
          </div>
    </div>
  }
}

export default connect(state => state, dispatch => bindActionCreators(actionsCreators, dispatch))(FormFile);