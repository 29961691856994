import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/competition";
import Box from '../article'
import { Link } from 'react-router-dom'
import { Button } from 'antd'

class ArticlePage extends Component {

  componentDidMount() {
    let { id } = this.props.match.params;
    this.props.cpusers({ id })
  }

  componentDidUpdate(props) {
    let { id } = this.props.match.params;
    if (id && id !== props.match.params.id) {
      this.props.cpusers({ id })
    }
  }

  render() {
    let { id } = this.props.match.params;
    let { cpusers } = this.props.competition;
    return (
      <Box id={id} link={'users'}>
        <div className="comp-content" id="cp-user-lst">
          <h2>选手列表 <span>(点击选手头像可以看大图和详情)</span></h2>
          {cpusers ?
            <div
              className="article-content" id="user-lst">
              {cpusers.map((ele, index) => (
                <div key={index + 1} className="oneuser">
                  <div className="oneuserbox">
                  <Link to={`/cpuser/${id}/${ele.user_id}`}>
                    <img src={ele.cpu_xing_thumb ? ele.cpu_xing_thumb : ele.user_thumb} alt="用户头像" key={index} />
                    <span>{ele.cpu_name ? ele.cpu_name : ele.user_nickname}</span>
                    <span className="extra xuanyan">{ele.cpu_xuanyan}</span>
                    <span className="extra">得票数：{ele.cpu_vote}</span>
                    <span className="extra">礼物旗币：{ele.cpu_gift_num}</span>
                    <span className="extra">选手编号：{ele.cpu_id}</span>
                  </Link>
                  <div style={{ textAlign: 'center', paddingTop: 10 }}>
                    <Button type="link" onClick={() => { this.props.vote({ id: id, uid: ele.user_id }) }}>投票</Button>
                    <Button type="link" onClick={() => { this.props.sendGift({ id: id, uid: ele.user_id }) }}>送礼</Button>
                  </div>
                  </div>
                  
                </div>
              ))}
            </div>
            : null}
        </div>
      </Box>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(ArticlePage);
