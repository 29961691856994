import {
  combineReducers
} from 'redux';
import news from './news'
import competition from "./competition";
import ebook from "./ebook";
import user from "./user";
import learn from './learn';
import group from './group';
import address from './address'
import order from './order'
import goods from './goods'
import gift from './gift'
import topic from './topic'
import cpuser from './cpuser'
import ticket from './ticket'
import medal from './medal'
import qipao from './qipao'
import salon from './salon'
import article from './article'
import author from './author'
import doudouwen from './ddw'
import merchant from './merchant'

const indexInit = {
  show_menu:false,
  activeUrl:'/'
}
const index = (state = indexInit, action) => {
  switch (action.type) {
    case "GLOBAL_SITE_MENU":
      return Object.assign({}, state, {
        ...action
      });
      case "CITYS":
        return Object.assign({}, state, {
          ...action
        });
        case "PAGE_AD":
        return Object.assign({}, state, {
          ...action
        });
    case 'INDEX_USERCARD':
      return Object.assign({}, state, {
        ...action
      });
    case "GLOBAL_SITE_INFO":
      return Object.assign({}, state, {
        ...action
      });
    case "INDEX_BANNER":
      return Object.assign({}, state, {
        ...action
      });
    case "INDEX_AUTHOR":
      return Object.assign({}, state, {
        ...action
      });
    case "INDEX_COMPETITION":
      return Object.assign({}, state, {
        ...action
      });
    case "INDEX_EBOOK":
      return Object.assign({}, state, {
        ...action
      });
    case "INDEX_FLINK":
      return Object.assign({}, state, {
        ...action
      });
    case "INDEX_SALON":
      return Object.assign({}, state, {
        ...action
      });
    case "INDEX_QINIANG":
      return Object.assign({}, state, {
        ...action
      });
    case "INDEX_QIPAO":
      return Object.assign({}, state, {
        ...action
      });
    case "INDEX_RANK":
      return Object.assign({}, state, {
        ...action
      });
      case "INDEX_CITY":
        return Object.assign({}, state, {
          ...action
        });
        case "INDEX_READER":
          return Object.assign({}, state, {
            ...action
          });
    case "INDEX_LEARN":
      return Object.assign({}, state, {
        ...action
      });
    //
    case "INDEX_CONFERENCE":
      return Object.assign({}, state, {
        ...action
      });
    case "GLOBAL_SHOW_MODAL":
      return Object.assign({}, state, {
        ...action
      });
    case "EBOOK_LST":
      return Object.assign({}, state, {
        ...action
      });
    case "SITE_CITYS":
      return Object.assign({}, state, {
        ...action
      });
    case "INDEX_GIFT_RANK":
      return Object.assign({}, state, {
        ...action
      });
    case "FORM_CONTENT":
      return Object.assign({}, state, {
        ...action
      });
      case "TOGGLE_MENU":
      return Object.assign({}, state, {
        ...action
      });
      case "ACTIVE_MENU":
        return Object.assign({}, state, {
          ...action
        });
      case "SALE_BOOK":
        return Object.assign({}, state, {
          ...action
        });
        case "RECORD_LIST":
          return Object.assign({}, state, {
            ...action
          });
          case "FORM_FIELDS":
            return Object.assign({}, state, {
              ...action
            });
        case "GLOBAL_SITE_SEARCH":
          return Object.assign({}, state, {
            ...action
          });
    default:
      return state;
  }
}

export default combineReducers({
  index,
  news,
  competition,
  ebook,
  user,
  learn,
  group,
  address,
  order,
  goods,
  gift,
  topic,
  cpuser,
  ticket,
  medal,
  salon,
  qipao,
  article,
  author,
  merchant,
  ddw:doudouwen
})