import Type from "./type";

import Conf from "../common/config";
import { message } from "antd";
import Axios from './request'
const { CART_LST} = Type;

/**
 * 列表
 * @param {*} args 
 */
export function lst(args) {
  return dispatch => {
    Axios.post(Conf.api + "/cart/lst", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: CART_LST, cartLst: res.data.data });
      }
    });
  };
}

/**
 * 删除地址
 * @param {*} args 
 */
export function del(args) {
  let that = this;
  return dispatch => {
    Axios.post(Conf.api + "/cart/del", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        message.info("操作成功");
        that.lst({ uid: args.addr_uid });
      } else {
        message.info("操作失败");
      }
    });
  };
}
