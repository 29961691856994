const indexInit = {
    
}
const goods = (state = indexInit, action) => {
    switch (action.type) {
      case "GOODS_LST":
        return Object.assign({}, state, {
          ...action
        });
        case "GOODS_TYPE":
          return Object.assign({}, state, {
            ...action
          });
          case "GOODS_INFO":
          return Object.assign({}, state, {
            ...action
          });
          //
          case "CART_LST":
            return Object.assign({}, state, {
              ...action
            });
      default:
        return state;
    }
}

export default goods