import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/news";
import PageBox from "../public/index";
import {Link} from 'react-router-dom'
import { Row, Col,Breadcrumb } from "antd";
import RightNews from './right'

// import { HomeOutlined } from "@ant-design/icons";
class HotNews extends Component {

  componentDidMount() {
    let id=this.props.match.params.id
    this.props.article(id);
  }

  componentDidUpdate(props){
    let { article } = this.props.news;
    if(props.news.article!==article && article){
      document.title=article.article_title+' - '+this.props.index.siteInfo.sitename;
    }
    let id = this.props.match.params.id;
    let id2=props.match.params.id;
    if(id!==id2 && id){
      this.props.article(id);
    }
  }


  render() {
    let { article } = this.props.news;

    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/news">文章列表</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            文章详情
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col md={17} xs={24}>
            <div className="main">
              {article ? (
                <div className="article">
                  <h1>{article.article_title}</h1>
                  <div className="article-date">
                    <span>来源：{article.article_author}</span>
                    <span>发布时间：{article.article_datetime}</span>
                  </div>
                  <div
                    className="article-content"
                    dangerouslySetInnerHTML={{
                      __html: article.article_content
                    }}
                  ></div>
                </div>
              ) : null}
            </div>
          </Col>
          <Col md={7} xs={24}>
            <RightNews />
          </Col>
        </Row>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
