import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/topic";
import PageBox from "../public/index";
import { Link } from 'react-router-dom'
import { Row, Col, Breadcrumb, Form, Button,Card ,Modal} from "antd";
import RightNews from './right'
import FUNC from 'common/public'
import 'braft-polyfill'
import 'braft-editor/dist/index.css'
import BraftEditor from 'braft-editor'


class HotNews extends Component {
  constructor() {
    super()
    this.state = {
      id: null,
      visible: false,
      img:null
    }
    this.edit = this.edit.bind(this)
    this.dianzan = this.dianzan.bind(this)
    this.onFinish = this.onFinish.bind(this)
  }

  edit() {
    let uid = localStorage.getItem("uid")
    if (uid) {
      let { visible } = this.state;
      this.setState({ visible: !visible })
    } else {
      this.props.showDialog('login')
    }
  }

  dianzan() {
    let uid = localStorage.getItem("uid")
    let id = this.props.match.params.id
    if (uid) {
      this.props.zan({ id: id, uid: uid })
    } else {
      this.props.showDialog('login')
    }
  }

  componentDidMount() {
    let id = this.props.match.params.id
    let uid = localStorage.getItem("uid")
    let obj = { id: id }
    if (uid) {
      obj['uid'] = uid;
    }
    this.props.indexTopic(obj);
    this.props.getComments(obj)
    this.setState({ id })
  }

  componentDidUpdate() {
    let id2 = this.props.match.params.id
    let id = this.state.id;
    let uid = localStorage.getItem("uid")
    if (id && id2 && id !== id2) {
      this.setState({ id: id2 })
      this.props.index({ id: id2, uid: uid });
      this.props.getComments({ id: id2, uid: uid })
    }
  }

  onFinish(values) {
    let send = {content:values.content.toHTML()}
    send["uid"] = localStorage.getItem("uid");
    send['id'] = this.state.id;
    this.setState({ visible: false })
    this.props.pinglun(send);
  }


  render() {
    let { topicIndex, topicComments } = this.props.topic;
    let { visible } = this.state;
    const controls = [
      {
        key: 'bold',
        text: <b>加粗</b>
      },
      'italic', 'underline', 'emoji'
    ]
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/topic">旗袍圈</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            话题
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col md={17} xs={24}>
            <div className="main">
              {topicIndex ? (
                <div className="onesubject">
                  <Card title="话题内容" bordered={false}>
                  <div
                    className="onetopic"
                    dangerouslySetInnerHTML={{
                      __html: topicIndex.topic_content
                    }}
                  ></div>
                  {topicIndex.topic_thumbs?
                  <div className="topicalbums">
                    {topicIndex.topic_thumbs.split(',').map(ele=>(
                      <img src={ele} alt="图片" onClick={()=>{this.setState({img:ele})}} key={ele}/>
                    ))}
                  </div>
                  :null}
                  </Card>
                  
                  <div className="topicUser">
                    <Link className="topicLeft" to={`/user/${topicIndex.user_id}`}>
                      <img src={topicIndex.user_thumb?topicIndex.user_thumb:require('common/img/avatar.png')} alt="用户" />
                      <span>{topicIndex.user_nickname}({topicIndex.topic_datetime})</span>
                    </Link>
                    <div className="actions">
                      <span onClick={this.edit} className={visible ? "red" : null}><i className="iconfont icon-pinglun"></i></span>
                      {topicIndex.zan ?
                        <span onClick={this.dianzan} className='red'><i className="iconfont icon-dianzan1"></i></span>
                        :
                        <span onClick={this.dianzan}><i className="iconfont icon-dianzan"></i></span>
                      }

                    </div>
                  </div>
                  {visible ?
                    <Form name="topic"
                      layout="vertical"
                      ref={this.formRef}
                      style={{ width: "100%" }}
                      onFinish={this.onFinish}>
                      <Form.Item
                        name="content"
                        rules={[
                          {
                            required: true,
                            message: "内容不能为空"
                          }
                        ]}
                      >
                        <BraftEditor
                          controls={controls}
                          contentStyle={{ height: 210, boxShadow: '0 0 1px 1px #ccc' }}
                        />
                      </Form.Item>
                      <Form.Item style={{ textAlign: "right" }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                        >
                          提交
                      </Button>
                      </Form.Item>
                    </Form>
                    : null}
                </div>
              ) : null}
              {topicComments ?
                <div className="comments">
                  <h2>评论列表</h2>
                  <ul>
                    {topicComments.map(ele => (
                      <li key={ele.ct_id}>
                        <div className="user">
                          <div className="avatar">
                            <img src={ele.user_thumb?ele.user_thumb:require('common/img/avatar.png')} alt="头像" />
                            <span>{ele.user_nickname}</span>
                          </div>
                          <span className="dateline">{ele.ct_dateline}</span>
                        </div>
                        <div className="content" dangerouslySetInnerHTML={{__html:ele.ct_content}}></div>
                      </li>
                    ))}
                  </ul>
                </div>
                : null}
                <Modal
                  footer={null}
                  className="modalimg"
                  onCancel={()=>{this.setState({img:null})}}
                  visible={!!this.state.img}
                  centered={true}
                  >
                  <img src={this.state.img} alt="放大图" onClick={()=>{this.setState({img:null})}}/>
                </Modal>
            </div>
          </Col>
          <Col md={7} xs={24}>
            <RightNews />
          </Col>
        </Row>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
