import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/order";
import { Button, message } from "antd";
import qs from 'qs'
import Axios from 'actions/request'
import Conf from 'common/config'

class AuthPage extends Component {
  componentDidMount() {
    let data = window.location.search;
    //去掉第一个问号
    data=data.substring(1)
    let params = qs.parse(data);
    const {out_trade_no} = this.props.match.params
    const {search}=this.props.location
    params['out_trade_no']=out_trade_no
    params['callback']=search
    this.h5pay(params)
  }


  h5pay = (params) => {
    const that = this
    Axios.post(Conf.api + "/v2/order/authCodePay",params).then(res => {
      if (res.data.code===200) {
        that.onBridgeReady(res.data.data)
      }else{
        message.error(res.data.msg)
      }
    });
  }


  // appId: "wxf9770f0d25a2c31a"
  // nonceStr: "iHYaMHhhgDTOF1Zy"
  // package: "prepay_id=wx02173221597840d47cdcdc7c1264844500"
  // paySign: "29080E007AE977CEEED926861D0DE174"
  // signType: "MD5"
  // timeStamp: "1596360741"
  onBridgeReady = (data) => {
    window.WeixinJSBridge.invoke(
      'getBrandWCPayRequest', {
      "appId": data.appId, //公众号名称，由商户传入     
      "timeStamp": data.timeStamp, //时间戳，自1970年以来的秒数     
      "nonceStr": data.nonceStr, //随机串     
      "package": data.package,
      "signType": data.signType, //微信签名方式：     
      "paySign": data.paySign //微信签名 
    },
      function (res) {
        if (res.err_msg === "get_brand_wcpay_request:ok") {
          alert("支付成功");
          window.location.href =data['callback'];
        } else {
          alert("支付失败,请重试");
        }
      });
  }



  render() {
    return (
      <div className="pageCenter">
        <Button>支付中</Button>
      </div>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(AuthPage);
