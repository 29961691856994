import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/friend";
import PageBox from "components/public/userIndex";
import {Breadcrumb } from "antd";
import UserPub from "./public";
import UserLeft from "./left";
import {Link} from 'react-router-dom'

class LearnRecord extends Component {
  componentDidMount() {
    let uid = localStorage.getItem("uid");
    this.props.lst({ uid: uid });
  }

  render() {
    let { friendLst } = this.props.group;
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            会员中心
          </Breadcrumb.Item>
          <Breadcrumb.Item>好友列表</Breadcrumb.Item>
        </Breadcrumb>
        <div className="user-public">
            <UserPub />
          </div>
        <div className="user-center" id="medalpage">
         
          <div className="user-left">
            <UserLeft path={this.props.history.location.pathname} />
          </div>
          <div className="user-right">
            <div className="main">
              <div className="thumbLst">
                <ul>
                  {friendLst &&
                    friendLst.map(item => (
                      <li key={item.fr_bid}>
                        <Link to={`/user/${item.fr_bid}`}>
                          {item.user_thumb ? (
                            <img src={item.user_thumb} alt="头像" />
                          ) : (
                            <img src={require('common/img/avatar.png')} alt="头像" />
                          )}

                          <div style={{textAlign:'center',paddingTop:10}}>{item.user_nickname}</div>
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(LearnRecord);
