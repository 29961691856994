import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/index";
import { Input, message } from 'antd'
import Logo from './logo'
import { HashRouter } from 'react-router-dom'

const { Search } = Input;
class PublicTop extends Component {

  search = (value) => {
    if (!value) {
      message.info('搜索内容不能为空')
      return false;
    }
    let router = new HashRouter()
    router.history.push('/search?=' + value)
    this.props.siteSearch(value)
  }

  componentDidMount() {
    this.props.toggleMenu(false)
  }

  render() {
    let { show_menu } = this.props.index;

    return <div className="site-search">
      <div className="wrapper site-search-wrapper">
        <div className="logo-mmenu">
          <Logo />
          <div className="mobile-menu" onClick={() => { this.props.toggleMenu(!show_menu) }}> <span>所有频道</span> <i className="iconfont icon-caidan"></i></div>
        </div>
        <Search
          placeholder="请输入关键词"
          enterButton="搜索"
          size="large"
          onSearch={value => this.search(value)}
          style={{ width: 360 }}
        />
      </div>
    </div>
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(PublicTop);

