import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/ebook";
import PageBox from "components/public/userIndex";
import { Row, Col, Breadcrumb, Divider } from "antd";
import UserPub from "../public";
import UserLeft from "../left";
import FUNC from "common/public";

class EbookBuy extends Component {

  componentDidMount() {
    let uid = localStorage.getItem("uid");
    this.props.buyRecord({ uid: uid});
  }

  render() {
    let { ebookBuyLst } = this.props.ebook;
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href="/news">会员中心</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>购买记录</Breadcrumb.Item>
        </Breadcrumb>
        <div className="user-center">
          <div className="user-public">
            <UserPub />
          </div>
          <div className="user-left">
            <UserLeft path={this.props.history.location.pathname} />
          </div>
          <div className="user-right">
            <div className="main">
              <div className="lst">
                <ul>
                  {ebookBuyLst &&
                    ebookBuyLst.map(item => (
                      <li>
                        <div>
                          <a href={`/ebook/${item.by_fid}`}>
                            <span>空中书城：{item.ebook_title}</span>
                          </a>
                        </div>
                        <div className="btnGroup">
                          金额：{item.ebook_price}<Divider type="vertical"/>订单日期：
                          {item.by_datetime}
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(EbookBuy);
