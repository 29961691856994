import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/index";

class App extends Component {


  render() {
    let { qipao } = this.props.index;
    let { zhuanfang} = qipao;
    return (
        <div className="zhuanfang">
          <h3>
            <Link to="/pinpaihui/1">专访</Link>
          </h3>
          {zhuanfang?
          <a href={`/qnarticle/${zhuanfang.qp_id}`} className="wordInpic">
            <img src={zhuanfang.qp_thumb} alt="专访" />
            <span>{zhuanfang.qp_title}</span>
          </a>
          :null}
        </div>
    )
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(App);
