import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/cpFlower";
import PageBox from "components/public/userIndex";
import { Breadcrumb, Tabs, Spin, List } from "antd";
import UserPub from "../public";
import UserLeft from "../left";
const { TabPane } = Tabs;

class HotNews extends Component {
  constructor() {
    super()
    this.state = {
      type: 0
    }
  }
  componentDidMount() {

    this.props.lst({ current: 1, pageSize: 8, type: 0 });
  }

  callback = (key) => {
    this.setState({ type: key })
    this.props.lst({ current: 1, pageSize: 8, type: key });
  }

  pages = (page) => {
    let { type } = this.state;
    this.props.lst({ pageSize: 8, current: page, type: type })
  }


  render() {
    let { cpFlowerLst } = this.props.competition;
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            会员中心
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="user-public">
          <UserPub />
        </div>
        <div className="user-center" id="medalpage">
          <div className="user-left">
            <UserLeft path={this.props.history.location.pathname} />
          </div>
          <div className="user-right">
            <div className="main">
              <Tabs defaultActiveKey="0" onChange={this.callback}>
                <TabPane tab="我收到的礼物" key="0">
                  {cpFlowerLst ? (
                    cpFlowerLst ? (
                      <div>
                        {/* <div>收礼物次数：{cpFlowerLst.total} 旗币：{cpFlowerLst.score?cpFlowerLst.score:0}</div> */}
                        <List
                          itemLayout="vertical"
                          className="mygifts"
                          size="large"
                          pagination={{
                            onChange: this.pages,
                            pageSize: 8,
                            total: cpFlowerLst ? cpFlowerLst.total : 0
                          }}
                          dataSource={cpFlowerLst ? cpFlowerLst.data : []}
                          renderItem={item => (
                            <List.Item
                              key={item.title}
                            >
                              <List.Item.Meta
                                title={`用户：${item.user_nickname}`}
                                description={`${item.gift_datetime} 数量：${item.gift_num}`}
                              />
                              <img src={item.gift_big_thumb} alt="礼物" />
                            </List.Item>
                          )}
                        />
                      </div>

                    ) : (
                        null
                      )
                  ) : (
                      <div className="empty">
                        <Spin />
                      </div>
                    )}
                </TabPane>
                <TabPane tab="我送出的礼物" key="1">
                  {cpFlowerLst ? (
                    cpFlowerLst ? (
                      <div>
                        {/* <div style={{padding:'10px 0'}}>送礼物次数：{cpFlowerLst.total} 旗币：{cpFlowerLst.score?cpFlowerLst.score:0}</div> */}
                        <List
                          itemLayout="vertical"
                          size="large"
                          className="mygifts"
                          pagination={{
                            onChange: this.pages,
                            pageSize: 8,
                            total: cpFlowerLst ? cpFlowerLst.total : 0
                          }}
                          dataSource={cpFlowerLst ? cpFlowerLst.data : []}
                          renderItem={item => (
                            <List.Item
                              key={item.title}
                            >
                              <List.Item.Meta
                                title={`用户：${item.user_nickname}`}
                                description={`${item.gift_datetime} 数量：${item.gift_num}`}
                              />
                              <img src={item.gift_big_thumb} alt="礼物" />
                            </List.Item>
                          )}
                        />
                      </div>
                    ) : (
                        null
                      )
                  ) : (
                      <div className="empty">
                        <Spin />
                      </div>
                    )}
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
