import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/competition";
import { Link } from 'react-router-dom'
import './style.css'

class HotNews extends Component {
  constructor(props) {
    super(props)
    this.state = {
      link: props.link,
      id:props.id
    }
  }

  componentDidMount(){
    //判断哪些菜单存在
    let id=this.props.id;
    this.props.getCpMenu({id})
  }

  componentDidUpdate(props){
    let { id } = this.props;
    if(id && id!==props.id){
      this.props.getCpMenu({id})
    }
  }

  


  render() {
    let {link,id}=this.state;
    let {competitionMenu}=this.props.competition;
    return (
      competitionMenu?
      <div className="compTabs">
        <ul>
          <li className={link==='comreportlst'?'active':null}>
            <Link to={`/comreportlst/${id}`}>
              <i className="iconfont icon-jieshao"></i>
              <span>赛事报道</span>
            </Link>
          </li>
          <li className={link==='cominfo'?'active':null}>
            <Link to={`/cominfo/${id}`}>
              <i className="iconfont icon-jieshao"></i>
              <span>赛事介绍</span>
            </Link>
          </li>
          {competitionMenu.xuanchuan?
          <li className={link==='promote'?'active':null}>
            <Link to={`/com-promote/${id}`}>
              <i className="iconfont icon-peixunxuanchuan"></i>
              <span>宣传片</span>
            </Link>
          </li>
          :null}
          {competitionMenu.pingwei?
          <li className={link==='judgy'?'active':null}>
            <Link to={`/com-judgy/${id}`}>
              <i className="iconfont icon-zhuanjiazhuye"></i>
              <span>评委介绍</span>
            </Link>
          </li>
          :null}
          {competitionMenu.zanzhu?
          <li className={link==='sponsor'?'active':null}>
            <Link to={`/com-sponsor/${id}`}>
              <i className="iconfont icon-iconzhengli-"></i>
              <span>赞助商</span>
            </Link>
          </li>
          :null}
          {competitionMenu.daka?
          <li className={link==='master'?'active':null}>
            <Link to={`/com-master/${id}`}>
              <i className="iconfont icon-toutiao"></i>
              <span>大咖选手</span>
            </Link>
          </li>
          :null}
          {competitionMenu.paiming?
          <li className={link==='rank'?'active':null}>
            <Link to={`/com-rank/${id}`}>
              <i className="iconfont icon-paiming"></i>
              <span>人气排名</span>
            </Link>
          </li>
          :null}
          {competitionMenu.xuanshou?
          <li className={link==='users'?'active':null}>
            <Link to={`/com-users/${id}`}>
              <i className="iconfont icon-yonhu"></i>
              <span>选手列表</span>
            </Link>
          </li>
          :null}
          {competitionMenu.huifang?
          <li className={link==='video'?'active':null}>
            <Link to={`/com-video/${id}`}>
              <i className="iconfont icon-shipinmov"></i>
              <span>视频回放</span>
            </Link>
          </li>
          :null}
        </ul>
      </div>
      :null
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
