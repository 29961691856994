import Type from "./type";

import Conf from "../common/config";
import { message } from "antd";
// import { HashRouter } from "react-router-dom";
import Axios from './request'
const { GOODS_LST,GOODS_TYPE,GOODS_INFO,GLOBAL_SHOW_MODAL} = Type;

/**
 * 列表
 * @param {*} args 
 */
export function lst(args) {
  return dispatch => {
    Axios.post(Conf.api + "/goods/lst", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: GOODS_LST, goodsLst: res.data.data });
      }
    });
  };
}

/**
 * 产品分类
 * @param {*} args 
 */
export function goodsType(args) {
  return dispatch => {
    Axios.post(Conf.api + "/goods/type", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: GOODS_TYPE, goodsType: res.data.data });
      }
    });
  };
}
/**
 * 产品详情
 * @param {*} args 
 */
export function goodsinfo(args) {
  return dispatch => {
    Axios.post(Conf.api + "/goods/index", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        if(!res.data.data){
          message.info('此商品不存在')
        }
        dispatch({ type: GOODS_INFO, goodsInfo: res.data.data });
      }
    });
  };
}


/**
 * 删除地址
 * @param {*} args 
 */
export function del(args) {
  let that = this;
  return dispatch => {
    Axios.post(Conf.api + "/goods/del", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        message.info("操作成功");
        that.lst({ uid: args.addr_uid });
      } else {
        message.info("操作失败");
      }
    });
  };
}

export function showLogin(arg){
  return { type: GLOBAL_SHOW_MODAL, showModal: arg };
}

/**
 * 创建订单
 * @param {*} args 
 * gd_id 可选 商品ID
 * uid 用户ID
 * num 数量
 * type 订单类型 1=goods表商品,2=充值 3=购买会员
 * order_pirce 充值金额
 */
export function createOrder(args) {
  return dispatch => {
    Axios.post(Conf.api + "/order/create", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        window.location.href='/#/order/'+res.data.data.out_trade_no
      }else{
        message.info(res.data.msg)
      }
    });
  };
}