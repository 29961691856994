import Type from "./type";

import Conf from "../common/config";
// import FUNC from 'common/public'
import {message} from 'antd'
import Axios from './request'
const { EBOOK_COLLECT } = Type;

/**
 * 列表
 * @param {*} args 
 */
export function lst(args) {
  return dispatch => {
    Axios.post(Conf.api + "/ebook_collect/lst", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: EBOOK_COLLECT, ebookCollect: res.data.data });
      }
    });
  };
}

export function del(args) {
  let that = this;
  return dispatch => {
    Axios.post(Conf.api + "/ebook_collect/del", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        message.info("操作成功");
        that.lst({ uid: args.cl_uid });
      } else {
        message.info("操作失败");
      }
    });
  };
}