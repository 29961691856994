import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/user";
import PageBox from "./user";
import { List,  Card } from "antd";
import { Link } from 'react-router-dom'
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

/**
 * 用户的公开个人主页，放在好友里面，展示旗袍圈的内容
 */
class UserHomePage extends Component {

  componentDidMount() {
    let { id } = this.props.match.params;
    this.props.userGame({ id })
  }



  render() {
    let { userGame } = this.props.user;
    let { id } = this.props.match.params;
    return (
      <PageBox id={id} keyName="game">
        <Card title="参赛记录" size="small" style={{ marginTop: 15 }}>
          {userGame ? (
            <List
              dataSource={userGame}
              className="userGames"
              pagination={{
                pageSize: 10
              }}
              renderItem={item => (
                <List.Item>
                    <div className="cpRecords">
                      <Link to={`/cominfo/${item.cp_id}`}>{item.cp_title}<span>{moment(item.cpu_datetime).format('Y-M-D')}</span></Link>
                      <Link to={`/cpuser/${item.cp_id}/${id}`}>她的报名主页</Link>
                    </div>
                </List.Item>
              )}
            />
          ) : null}
        </Card>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(UserHomePage);
