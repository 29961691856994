import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/cpUser";
import { message } from "antd";
import QRCode from 'qrcode.react'
import Axios from 'actions/request'
import "./pay.css";
import moment from "moment";
import "moment/locale/zh-cn";
import Dates from 'common/date'


/**
 * PC端支付，弹窗显示二维码，扫码支付后，刷新页面
 * 如果是手机端，直接301从商品详情页，跳转到微信官方页面，通过微信的授权，获取code，再跳转到支付回调页面 /h5-callback
 */
class PayCom extends Component {
  constructor() {
    super()
    this.state = {
      validtime: '00:00:00',
      t: null
    }
  }

  componentDidMount() {
    let { modalParams } = this.props.index;
    if (modalParams) {
      this.setTime(modalParams)
    }
  }

  componentWillUnmount() {
    const { t } = this.state;
    if (t) {
      clearInterval(t)
    }
  }

  setTime = (modalParams) => {
    let start = modalParams.order_createtime
    let end = moment(modalParams.order_createtime).subtract(-5, 'minutes')

    let t = setInterval(() => {
      let time = Dates.difSeconds(start, end)
      if (time === 'over') {
        this.setState({ validtime: 'over' })
        clearInterval(t)
      }
      this.setState({ validtime: time })
      this.checkPayStatus(modalParams['out_trade_no'])
    }, 1000);
    this.setState({ t })
  }

  /**
   * 检查订单状态
   * @param {*} out_trade_no 
   */
  checkPayStatus = (out_trade_no) => {
    Axios.get('/api/v2/order/checkPayStatus?out_trade_no=' + out_trade_no).then(res => {
      if (res.data.code === 201) {
        message.success(res.data.msg)
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      }
    })
  }


  render() {
    let { modalParams } = this.props.index;
    if (!modalParams) return null;
    return (
      <div className="yestian-pay">
        <dl>
          <dt>{modalParams.title}</dt>
          <dd>订单金额：￥{modalParams.price}</dd>
          <dd>订单编号：{modalParams.out_trade_no}</dd>
        </dl>
        <div className="qrcode">
          <QRCode value={modalParams.payUrl} />
          <div className="time">剩余支付时间：{this.state.validtime}</div>
          <div className="tip">请用微信扫码支付</div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(PayCom);
