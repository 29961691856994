import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/index";
import PageBox from "../public/index";
import { Link } from 'react-router-dom'
import { Row, Col, Breadcrumb,message } from "antd";

class HotNews extends Component {

  componentDidMount() {
    let url=window.location.href;
    if(url.indexOf('?=')>=0){
      let params=url.split('?=')
      if(params[1]){
        this.props.siteSearch(params[1])
      }else{
        message.info('关键词不能为空')
      }
    }
  }

  render() {
    let { siteSearch } = this.props.index;

    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            搜索结果
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col md={17} xs={24}>
            <div className="main">
              {siteSearch ? (
                <div className="searchres">
                  {siteSearch.map((ele,index) => (
                    <dl key={index}>
                      <dt>{ele.title}</dt>
                      {ele.children.map((ele2,index2) => (
                        <dd key={`cl-${index2}`}><Link to={ele2.url}>{ele2.title}</Link></dd>
                      ))}
                    </dl>
                  ))}
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
