import Type from "./type";
import {createPublicOrder} from './order'
import Conf from "../common/config";
import {message} from 'antd'
import FUNC from 'common/public'
import {HashRouter} from 'react-router-dom'
import Axios from './request'
const { ACTIVE_MENU,EBOOK_LST, EBOOK_TYPE,EBOOK_INFO,EBOOK_CHAPTERS,EBOOK_CHAPTER,EBOOK_BUY_LST,EBOOK_RECORD_LST,GLOBAL_SHOW_MODAL } = Type;

export function setActiveUrl(page){
  return({type:ACTIVE_MENU,activeUrl:page})
}

/**
 * 新闻列表
 * @param {*} args 
 */
export function lst(args) {
  return dispatch => {
    dispatch({ type: EBOOK_LST, ebookLst: null });
    Axios.post(Conf.api + "/ebook/lst", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: EBOOK_LST, ebookLst: res.data.data });
      }
    });
  };
}

//作者所有书籍
export function authorEbookLst(args) {
  return dispatch => {
    dispatch({ type: EBOOK_LST, authorEbookLst: null });
    Axios.post(Conf.api + "/ebook/authorEbookLst", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: EBOOK_LST, authorEbookLst: res.data.data });
      }
    });
  };
}
/**
 * 新闻列表推荐新闻
 * @param {*} args 
 */
export function rec(args) {
  return dispatch => {
    Axios.post(Conf.api + "/ebook/rec", {
      data: args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: EBOOK_LST, ebookRec: res.data.data });
      }
    });
  };
}

/**
 * 文章详情
 * @param {*} args 
 */
export function article(id) {
  return dispatch => {
    dispatch({ type: EBOOK_LST, article: null });
    Axios.post(Conf.api + "/ebook/index?id="+id).then(res => {
      if (!res.data.code) {
        dispatch({ type: EBOOK_LST, article: res.data.data });
      }
    });
  };
}

/**
 * 空中书城分类
 */
export function types() {
  return dispatch => {
    Axios.post(Conf.api + "/ebook/type").then(res => {
      if (!res.data.code) {
        dispatch({ type: EBOOK_TYPE, ebookType: res.data.data });
      }
    });
  };
}

//
export function typename(data) {
  return dispatch => {
    Axios.post(Conf.api + "/ebook/typename",data).then(res => {
      if (!res.data.code) {
        dispatch({ type: EBOOK_TYPE, ebookTypeName: res.data.data });
      }
    });
  };
}

/**
 * 一本书
 * @param {*} data 
 */
export function ebookInfo(data) {
  return dispatch => {
    dispatch({ type: EBOOK_INFO, ebookInfo: null});
    let uid=localStorage.getItem("uid")
    if(uid){
      data['uid']=uid;
    }
    Axios.post(Conf.api + "/ebook/ebookInfo",data).then(res => {
      if (!res.data.code) {
        dispatch({ type: EBOOK_INFO, ebookInfo: res.data.data });
      }
    });
  };
}

/**
 * 章节列表
 * @param {*} data 
 */
export function chapters(data) {
  return dispatch => {
    dispatch({ type: EBOOK_CHAPTERS, chapters: null});
    Axios.post(Conf.api + "/ebook/chapters",data).then(res => {
      if (!res.data.code) {
        dispatch({ type: EBOOK_CHAPTERS, chapters: res.data.data });
      }
    });
  };
}

export function nextChapter(data) {
  let that=this;
  return dispatch => {
    let uid=localStorage.getItem("uid")
    if(uid){
      data['uid']=uid
    }
    Axios.post(Conf.api + "/chapter/nextChapter",data).then(res => {
      if (!res.data.code) {
        let router=new HashRouter()
        router.history.push('/chapter/'+res.data.data)
        that.chapter({id:res.data.data})
      }
    });
  };
}

export function prevChapter(data) {
  let that=this;
  return dispatch => {
    let uid=localStorage.getItem("uid")
    if(uid){
      data['uid']=uid
    }
    Axios.post(Conf.api + "/chapter/prevChapter",data).then(res => {
      if (!res.data.code) {
        let router=new HashRouter()
        router.history.push('/chapter/'+res.data.data)
        that.chapter({id:res.data.data})
      }
    });
  };
}

/**
 * 根据章节编号获取章节列表
 * @param {*} data 
 */
export function chaptersByChapterNo(data){
  return dispatch => {
    dispatch({ type: EBOOK_CHAPTERS, chapters: null});
    Axios.post(Conf.api + "/chapter/chaptersByNo",data).then(res => {
      if (!res.data.code) {
        dispatch({ type: EBOOK_CHAPTERS, chapters: res.data.data });
      }
    });
  };
}
/**
 * 点击阅读按钮，获取第一章节，判断空中书城是否免费，是否需要登录，如果是付费，用户是否付费了。
 * @param {*} data 
 */
export function chapter(data) {
  return dispatch => {
    dispatch({ type: EBOOK_CHAPTER, chapter: null});
    let uid=localStorage.getItem("uid")
    if(uid){
      data['uid']=uid
    }
    Axios.post(Conf.api + "/ebook/chapter",data).then(res => {
      if (!res.data.code) {
        dispatch({ type: EBOOK_CHAPTER, chapter: res.data.data });
      }
    });
  };
}

/**
 * 读书记录
 * @param {*} args 
 */
export function record(args) {
  return dispatch => {
    Axios.post(Conf.api + "/ebook/readrecord", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: EBOOK_RECORD_LST, ebookRecordLst: res.data.data });
      }
    });
  };
}

/**
 * 购买记录
 * @param {*} args 
 */
export function buyRecord(args) {
  return dispatch => {
    Axios.post(Conf.api + "/ebook/buyrecord", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: EBOOK_BUY_LST, ebookBuyLst: res.data.data });
      }
    });
  };
}

/**
 * 设置浏览记录
 * @param {*} args 
 */
export function setRead(args) {
  return dispatch => {
    Axios.post(Conf.api + "/ebook_record/setread",args).then(res => {
      if (!res.data.code && res.data.data) {
        message.info(res.data.data)
      }
    });
  };
}

/**
 * 收藏事件
 * @param {*} args 
 */
export function collect(args) {
  let that=this;
  return dispatch => {
    let uid=localStorage.getItem("uid")
    if(!uid){
      message.info('请先登录后再操作')
      return false;
    }
    args['uid']=uid;
    Axios.post(Conf.api + "/ebook_collect/collect",args).then(res => {
      if (!res.data.code) {
        message.info(res.data.msg)
        that.ebookInfo(args)
      }
    });
  };
}

/**
 * 创建空中书城订单
 * @param {*} args 
 */
export function buy(args){
  return createPublicOrder(args)
}

export function login(){
  return showDialog('login')
}

export function showDialog(arg,param){
  return { type: GLOBAL_SHOW_MODAL, showModal: arg,modalParams:param };
}