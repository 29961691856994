import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/competition";
import Box from '../article'
import {HashRouter} from 'react-router-dom'
import {Table} from 'antd'
import {Link} from 'react-router-dom'
// import { record } from "actions/learn";

class ArticlePage extends Component {

  componentDidMount(){
    let { id } = this.props.match.params;
    this.props.cpRank({id})
    this.props.rankTop3({id})
  }

  componentDidUpdate(props){
    let { id } = this.props.match.params;
    if(id && id!==props.match.params.id){
      this.props.cpRank({ id })
      this.props.rankTop3({id})
    }
  }
  toUserHome=(record)=>{
    let router=new HashRouter()
    router.history.push(`/cpuser/${record.cpu_cp_fid}/${record.cpu_uid}`)
  }

  render() {
    let { id } = this.props.match.params;
    let { cpRank,rankTop } = this.props.competition;
    let head=[]
    let guanjun,yajun,jijun=null;
    if(rankTop){
      guanjun=rankTop.find(ele=>ele.sort==='冠军')
      yajun=rankTop.find(ele=>ele.sort==='亚军')
      jijun=rankTop.find(ele=>ele.sort==='季军')
    }
    if(cpRank){
      cpRank.head.forEach((ele,index)=> {
        if(ele.dataIndex==='cpu_xing_thumb'){
          ele.render=(text,record)=>(
            record['cpu_xing_thumb']?
            <img src={record['cpu_xing_thumb']} alt="形象照片" className="rankthumb"/>
            :null
          )
        }
        head.push(ele)
      });
    }
    return (
      <Box id={id}  link={'rank'}>
        <div className="comp-content cpranktable">
          <div className="ranktop">
          {yajun?
            <Link className="onerank yajun" id="yajun" to={`/cpuser/${id}/${yajun.cpu_uid}`}>
              <div className="imgbg">
              <img src={require('common/img/yajun.png')} alt="亚军背景" className="rankbg"/>
                <img src={yajun.cpu_xing_thumb} alt="亚军" className="upic"/>
                {/* <img src={require('common/img/test.jpg')} alt="" className="upic"/> */}
                {/* <img src={require('common/img/yajun2.png')} alt="亚军底座" className="rankbase"/> */}
                </div>
                
            <p><span>选手：</span>{yajun.cpu_name}</p>
            <p><span>礼物：</span>{yajun.cpu_gift_num}</p>
              
              </Link> :null}
            {guanjun?
            <Link className="onerank guanjun" id="guanjun" to={`/cpuser/${id}/${guanjun.cpu_uid}`}>
              <div className="imgbg">
              <img src={require('common/img/guanjun.png')} alt="冠军背景" className="rankbg"/>
                <img src={guanjun.cpu_xing_thumb} alt="冠军" className="upic"/>
                {/* <img src={require('common/img/test.jpg')} alt="" className="upic"/> */}
                {/* <img src={require('common/img/guanjun2.png')} alt="亚军底座" className="rankbase"/> */}
                </div>
            <p><span>选手：</span>{guanjun.cpu_name}</p>
            <p><span>礼物：</span>{guanjun.cpu_gift_num}</p>
              </Link> :null}
              {jijun?
            <Link className="onerank jijun" id="jijun" to={`/cpuser/${id}/${jijun.cpu_uid}`}>
              <div className="imgbg">
                <img src={require('common/img/jijun.png')} alt="季军背景" className="rankbg"/>
                <img src={jijun.cpu_xing_thumb} alt="季军" className="upic"/>
                {/* <img src={require('common/img/test.jpg')} alt="" className="upic"/> */}
                {/* <img src={require('common/img/jijun2.png')} alt="季军底座" className="rankbase"/> */}
                </div>
            <p><span>选手：</span>{jijun.cpu_name}</p>
            <p><span>礼物：</span>{jijun.cpu_gift_num}</p>
              </Link> :null}
          </div>
        <h2>选手排名</h2>
        {cpRank ?
            <Table dataSource={cpRank.body} columns={head} onRow={record => {
              return {
                onClick: event => {this.toUserHome(record)}
              }
            }}/>
            : null}
        </div>
      </Box>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(ArticlePage);
