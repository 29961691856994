
import { Link } from "react-router-dom";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/index";

class PublicTop extends Component {

  render(){
    let data=this.props.data.filter(item=>item.menu_is_head);
    let {show_menu}=this.props.index;
    let {activeUrl}=this.props.index;
    // console.log(data)
    return (
      <header className={show_menu?'active':''}>
        <div className="wrapper">
          <ul>
            {data.map(item => (
              <li key={item.menu_id} className={activeUrl===item.menu_link?'active':null}>
                {item.menu_is_static?
                <a href={item.menu_link}>{item.menu_name}</a>
                :
                <Link to={item.menu_link}>{item.menu_name}</Link>
  }
              </li>
            ))}
          </ul>
        </div>
      </header>
    );
  }
}



export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(PublicTop);