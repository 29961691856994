import React, { Component } from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/goods";
import { Card } from "antd";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

class RightNews extends Component {
  constructor(){
    super()
    this.state={
      attr:{
        type:null,
        cate:null
      }
    }
    this.change=this.change.bind(this)
  }

  change(type,item){
    let {attr}=this.state;
    if(type==='type'){
      attr[type]=item?item.id:null;
    }else{
      attr[type]=item?item.cate_id:null;
    }
    this.setState({attr})
  }

  componentDidMount() {
    this.props.goodsType();
  }

  render() {
    let { goodsType } = this.props.goods;
    let {attr}=this.state;
    return (
      <div className="left">
        <Card title="产品分类" size="small">
          {goodsType
            ? goodsType.map(ele => (
                <div className="bookTag" key={ele.key}>
                  <h3>{ele.title}</h3>
                  <ul>
                    <li className={attr[ele.key]?'':'active'} onClick={()=>{this.change(ele.key,null)}}>全部</li>
                    {ele.data.map(ele2 => (
                      ele.key==='type'?
                      <li key={ele.key+ele2.id} onClick={()=>{this.change(ele.key,ele2)}} className={attr[ele.key]===ele2.id?'active':''}>{ele2.title}</li>
                      :
                      <li key={ele.key+ele2.cate_id} onClick={()=>{this.change(ele.key,ele2)}}  className={attr[ele.key]===ele2.cate_id?'active':''}>{ele2.title}</li>
                    ))}
                  </ul>
                </div>
              ))
            : null}
        </Card>
      </div>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
) (RightNews);
