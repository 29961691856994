import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/merchant";
import PageBox from "../public/index";
import { Link } from 'react-router-dom'
import { Breadcrumb, Row, Col, Button,Form } from "antd";
import PageForm from 'components/public/pageForm'
import FUNC from 'common/until'

class Merchant extends Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      time: 0,
      id:null
    };
  }

  onFinish = (values) => {
    values['form_type'] = 'comment';
    const {id}=this.state;
    values['id']=id
    this.props.submitForm(values);
  }

  componentDidMount() {
    const { id } = this.props.match.params
    this.setState({ id })
    this.props.getCommentForm({ id })
  }

  children_changePhoto = (field, event, file) => {
    let formPhotos = FUNC.savePhotoFromChild(this.state.formPhotos, field, event, file);
    this.setState({ formPhotos });
  }

  children_changeField = (field, value) => {
    const obj = {}
    obj[field] = value
    if(this.formRef.current){
      this.formRef.current.setFieldsValue(obj)
    }
  }

  render() {
    let {commentForm } = this.props.index;

    return (
      <PageBox>
        <div className="main">
          <Breadcrumb>
            <Breadcrumb.Item>
              <a href="/">首页</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/merchants">商家评论</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          <Row gutter={16}>
            <Col md={17} xs={24}>
              <div className="main">
               {commentForm?
               <Form
               name="autoForm"
               layout="vertical"
               ref={this.formRef}
               onFinish={this.onFinish}
             >
                 <PageForm data={commentForm}
                   onRef={(ref) => this.child = ref}
                   changePhoto={this.children_changePhoto}
                   changeField={this.children_changeField}
                 />
               <Form.Item style={{marginTop:15}}>
                 <Button
                   type="primary"
                   block
                   htmlType="submit"
                   disabled={this.state.time ? true : false}
                 >
                   提交{this.state.time ? this.state.time : null}
                 </Button>
               </Form.Item>
             </Form>
               :null}
              </div>
            </Col>
            <Col md={7} xs={24}></Col>
          </Row>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(Merchant);
