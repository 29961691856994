import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/address";
import { Form, Input} from "antd";
// const {Option}=Select;

class Login extends Component {
  // constructor(props) {
  //   super(props);
  // }
  componentDidMount(){
    this.props.getArea()
  }
  render() {
    // let {areaLst}=this.props.address;

    return (
      <React.Fragment>
        <Form.Item
          name="addr_name"
          rules={[
            {
              required: true,
              message: "姓名不能为空!"
            }
          ]}
        >
          <Input placeholder="请输入姓名" />
        </Form.Item>

        <Form.Item
          name="addr_phone"
          rules={[
            {
              required: true,
              message: "联系电话不能为空!"
            }
          ]}
        >
          <Input placeholder="请输入联系电话" />
        </Form.Item>
        {/* <Form.Item
          name="addr_area"
          rules={[
            {
              required: true,
              message: "区域不能为空!"
            }
          ]}
        >
          <Select placeholder="选择区域">
            {areaLst?
            areaLst.map(item=>(
            <Option value={item.value} key={item.value}>{item.lable}</Option>
            ))
            :null}
          </Select>
        </Form.Item> */}
        <Form.Item
          name="addr_detail"
          rules={[
            {
              required: true,
              message: "详细地址不能为空!"
            }
          ]}
        >
          <Input.TextArea placeholder="请输入详细地址" rows={3}/>
        </Form.Item>
      </React.Fragment>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(Login);
