import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/index";

class App extends Component {


  render() {
    let { qipao } = this.props.index;
    let { mingyuan } = qipao;
    return (
        <div className="mingyuan">
        <h3>
            <Link to="/pinpaihui/2">旗袍圈</Link>
          </h3>
          <ul>
          {mingyuan ? mingyuan.map((ele, index) => (
            !index ?<li key={ele.qp_id}> <a href={`/qnarticle/${ele.qp_id}`}>
              <img src={ele.qp_thumb} alt="名媛" />
            </a></li> :
              <li key={ele.qp_id}>
                <a href={`/qnarticle/${ele.qp_id}`} title={ele.qp_title}>
                ▪ <span>{ele.qp_title.length>20?ele.qp_title.substr(0,20)+'...':ele.qp_title}</span>
              </a>
              </li>
          )) : null}
          </ul>
        </div>
    )
  }
}


export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(App);
