import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/order";
import PageBox from "components/public/userIndex";
import { List, Breadcrumb, Tabs } from "antd";
import UserPub from "../public";
import UserLeft from "../left";
import { Link } from 'react-router-dom'
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
class EbookBuy extends Component {
  constructor(){
    super()
    this.state={
      orderType:null
    }
  }

  componentDidMount() {
    this.props.orderType();
  }

  componentDidUpdate(props){
    let that=this;
    let {orderType}=this.props.order;
    if(props.order.orderType!==orderType && orderType){
      orderType.forEach((ele,index) => {
        if(!index){
          that.props.lst({pageSize: 5, current: 1,type:ele.key});
          this.setState({orderType:ele.key})
        }
      });
    }
  }

  callback=(key)=>{
    this.setState({orderType:key})
    this.props.lst({ pageSize: 5, current: 1,type:key })
  }

  pages = (page) => {
    let {orderType}=this.state;
    this.props.lst({ pageSize: 5, current: page,type:orderType })
  }

  render() {
    let { orderLst, orderType } = this.props.order;
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            会员中心
          </Breadcrumb.Item>
          <Breadcrumb.Item>我的订单</Breadcrumb.Item>
        </Breadcrumb>
        <div className="user-public">
            <UserPub />
          </div>
        <div className="user-center"  id="medalpage">
          <div className="user-left">
            <UserLeft path={this.props.history.location.pathname} />
          </div>
          <div className="user-right">
            <div className="main">
              {orderType ?
                <Tabs onChange={this.callback}>
                  {orderType.map(ele => (
                    <Tabs.TabPane tab={ele.label} key={ele.key}>
                      <div className="lst orderlst">
                        <List
                          itemLayout="vertical"
                          size="large"
                          pagination={{
                            onChange: this.pages,
                            pageSize: 5,
                            total: orderLst ? orderLst.total : 0
                          }}
                          dataSource={orderLst ? orderLst.data : []}
                          renderItem={item => (
                            <List.Item
                              key={item.title}
                            >
                              <List.Item.Meta
                                title={<Link to={`/order/${item.out_trade_no}`}>{item.order_title}</Link>}
                                description={item.out_trade_no}
                              />
                              <div>订单金额：{item.order_total} 订单日期：{item.order_createtime} 订单状态：{item.step_name} <Link to={`/order/${item.out_trade_no}`}>详情&gt;&gt;</Link></div>
                            </List.Item>
                          )}
                        />
                      </div>
                    </Tabs.TabPane>
                  ))}
                </Tabs>
                : null}
            </div>
          </div>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(EbookBuy);
