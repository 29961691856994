import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/news";
import PageBox from "../public/index";
import { Link} from 'react-router-dom'
import { Row, Col, Breadcrumb, List } from "antd";
import RightNews from './right'

class HotNews extends Component {

  componentDidMount() {
    this.props.help()
  }


  render() {
    let { helpLst } = this.props.news;

    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            帮助中心
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col md={17} xs={24}>
            <div className="main">
              {helpLst ? (
                <List
                  dataSource={helpLst}
                  pagination={{
                    pageSize: 10
                  }}
                  renderItem={item => (
                    <List.Item>
                      <Link
                        to={`article/${item.article_id}`}
                        className="articleItem"
                      >
                        <div className="articleTitle">
                          <div className="articleTitle">{item.article_title}</div>
                          <div className="articleDesp">{item.article_desp}</div>
                        </div>
                      </Link>
                    </List.Item>
                  )}
                />
              ) : null}
            </div>
          </Col>
          <Col md={7} xs={24}>
            <RightNews />
          </Col>
        </Row>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
