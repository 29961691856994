import Type from "./type";

import Conf from "../common/config";
import Axios from './request'


const { AUTHORS,AUTHOR_INDEX,AUTHOR_BOOKS} = Type;

export function lst(args) {
  return dispatch => {
    Axios.post(Conf.api + "/author/lst", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: AUTHORS, authors: res.data.data });
      }
    });
  };
}

/**
 * 作家主机
 * @param {*} args 
 */
export function authorInfo(args) {
  return dispatch => {
    Axios.post(Conf.api + "/author/index", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: AUTHOR_INDEX, authorInfo: res.data.data });
      }
    });
  };
}

/**
 * 作家书籍
 * @param {*} args 
 */
export function authorBooks(args) {
  return dispatch => {
    Axios.post(Conf.api + "/author/books", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: AUTHOR_BOOKS, authorBooks: res.data.data });
      }
    });
  };
}
