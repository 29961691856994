import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/index";
import { Card, Tabs } from "antd";
import { Link } from "react-router-dom";
const { TabPane } = Tabs;

class EbookModal extends Component {

  render() {
    let { ebook } = this.props.index;

    return (
      <Card
        title="空中书城"
        size="small"
        extra={<Link to="/ebooktype">更多</Link>}
        style={{ width: "100%" }}
        className="cardTabsBox cardEbook"
      >
        <Tabs onChange={(e) => { this.props.ebook({ sign: e }) }}>
          <TabPane tab="推荐" key="1">
            <div className="cardBox ebooklst">
              {ebook
                ? ebook.map(ele => (
                  <Link
                    to={`/ebook/${ele.ebook_id}`}
                    key={`ebook-${ele.ebook_id}`}
                  >
                    <img src={ele.ebook_thumb} alt="空中书城" />
                    <div className="title">{ele.ebook_title}</div>
                  </Link>
                ))
                : null}
            </div>
          </TabPane>
          <TabPane tab="最新上架" key="2">
            <div className="cardBox ebooklst">
              {ebook
                ? ebook.map(ele => (
                  <Link
                    to={`/ebook/${ele.ebook_id}`}
                    key={`ebook-${ele.ebook_id}`}
                  >
                    <img src={ele.ebook_thumb} alt="空中书城" />
                    <div>{ele.ebook_title}</div>
                  </Link>
                ))
                : null}
            </div>
          </TabPane>
        </Tabs>
      </Card>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(EbookModal);