import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/user";
import { Link } from "react-router-dom";
import { Card,Breadcrumb } from 'antd'
import PageBox from "components/public/userIndex";

class UserPublicLeft extends Component {
  render() {
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            个人中心
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="mmenu wrapper">
          <Card title="赛事活动">
            <Link to="/mcomp/lst">我的赛事</Link>
            <Link to="/mcomp/salon">沙龙活动</Link>
            <Link to="/mcomp/gift">礼物记录</Link>
          </Card>
          <Card title="旗袍圈">
            <Link to="/mtopic/friend">关注列表</Link>
            <Link to="/mtopic/lst">我的旗袍圈</Link>
          </Card>
          <Card title="商城">
            <Link to="/mshop/addr">收货地址</Link>
            <Link to="/mshop/ticket">发票</Link>
          </Card>
          <Card title="空中书城">
            <Link to="/mbook/buy">购买记录</Link>
            <Link to="/mbook/read">阅读记录</Link>
            <Link to="/mbook/collect">我的收藏</Link>
          </Card>
          <Card title="个人信息">
            <Link to="/member/wallet">我的钱包</Link>
            <Link to="/member/order">我的订单</Link>
            <Link to="/member/upgrade">会员VIP</Link>
            <Link to="/member/medal">我的勋章</Link>
            <Link to="/member/setting">我的设置</Link>
            <Link to="/member/scores">旗币记录</Link>
            <Link to="/member/promoter">分享二维码</Link>
            <Link to="/member/promoterres">分享记录</Link>
            <Link to="/member/msg">我的消息</Link>
            <Link to="/member/sytj">收益记录</Link>
          </Card>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(UserPublicLeft);
