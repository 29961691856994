import React from "react";
import BannerAnim, { Element } from 'rc-banner-anim';
import 'rc-banner-anim/assets/index.css';
import './banner.less'
import {Link} from 'react-router-dom';
import TweenOne from 'rc-tween-one';
const BgElement = Element.BgElement;
export default (props) => {
return (
  <BannerAnim prefixCls="banner-fabu" autoPlay arrow={false}>
    {props.data.map(ele => (
      <Element prefixCls="banner-fabu-elem" key={`banner-${ele.salon_id}`}>
        <BgElement
          key={`bannerBg-${ele.salon_id}`}
          className="bg"
          style={{
            backgroundImage: `url(${ele.salon_thumb})`
          }}
        />
        <TweenOne className="banner-fabu-title" animation={{ y: 30, opacity: 0, type: 'from' }}>
            <Link to={`/salon`}>{ele.salon_title}</Link>
          </TweenOne>
      </Element>
    ))}
  </BannerAnim>
);
}
