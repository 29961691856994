import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/articles";
import PageBox from "../public/index";
import {Link} from 'react-router-dom'
import { Row, Col, Breadcrumb, List,Radio} from "antd";
import RightNews from './right'

// import { HomeOutlined } from "@ant-design/icons";
class HotNews extends Component {
  constructor(){
    super()
    this.handleChange=this.handleChange.bind(this)
    this.state={
      id:null
    }
  }
  handleChange(e){
    let id=e.target.value;
    this.props.lst({id})
    this.setState({id})
  }

  componentDidMount() {
    let id = this.props.match.params.id;

    this.props.lst({id});
    this.props.articleType()
  }

  render() {
    let { articleLst,articleType } = this.props.article;

    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            其他文章
          </Breadcrumb.Item>
        </Breadcrumb>
        <Radio.Group onChange={this.handleChange} style={{paddingTop:10}}>
          {articleType?articleType.map(ele=>(
            <Radio.Button value={ele.cate_id} key={ele.cate_id} className={this.state.id===ele.cate_id?'active':''}>{ele.cate_name}</Radio.Button>
          )):null}
        </Radio.Group>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col md={17} xs={24}>
            <div className="main">
              {articleLst ? (
                <List
                  dataSource={articleLst}
                  pagination={{
                    pageSize: 10
                  }}
                  renderItem={item => (
                    <List.Item>
                      <Link
                        to={`/article/${item.article_id}`}
                        className="articleItem"
                      >
                        <div>{item.article_title}</div>
                        <span>{item.article_auto_addtime}</span>
                      </Link>
                    </List.Item>
                  )}
                />
              ) : null}
            </div>
          </Col>
          <Col md={7} xs={24}>
            <RightNews />
          </Col>
        </Row>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
