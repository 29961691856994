import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/cate";
import PageBox from "../public/index";
import { Link } from 'react-router-dom'
import { Breadcrumb } from "antd";
import PageAd from './ad/index'
import PageAd2 from './ad2/index'

// import { HomeOutlined } from "@ant-design/icons";
class HotNews extends Component {

  componentDidMount() {
    this.props.setActiveUrl('/learn')
    this.props.xiushen()
    this.props.getAds(6)
    this.props.getAds2(7)
  }

  render() {
    let { xiushen } = this.props.article;
    let { learnLstAds,learnLstAds2} = this.props.index;
    return (
      <PageBox>
        <div className="main">
          <div style={{ display: 'flex' }}>
            {learnLstAds && learnLstAds.length ?
              <PageAd data={learnLstAds} />
              : null}
            {learnLstAds2 && learnLstAds2.length ?
              <PageAd2 data={learnLstAds2} />
              : null}
          </div>
          <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/learn">修身</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
          <div className="channel">
            <ul>
              {xiushen ? xiushen.map(ele => (
                <li key={ele.cate_id}>
                  <Link to={`/learn/${ele.cate_id}`}>
                    <div className="imgbox">
                      <img src={ele.cate_thumb ? ele.cate_thumb : require('common/img/nopic.png')} alt="缩略图" />
                    </div>
                    <div className="channelInfo">
                      <div className="title">{ele.cate_name}</div>
                    </div>
                  </Link>
                  {ele.articles ?
                    <div className="articles">
                      {ele.articles.map(ele2 => (
                        <p key={`art${ele2.article_id}`} style={{ textAlign: 'left' }}><Link to={`/article/${ele2.article_id}`}>{ele2.article_title.length > 15 ? (ele2.article_title.substr(0, 15) + '...') : ele2.article_title}</Link></p>
                      ))}
                    </div>
                    : null}
                  <div className="channelmore"><Link to={`/learn/${ele.cate_id}`}>更多&gt;&gt;</Link></div>
                </li>
              )) : null}
            </ul>
          </div>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
