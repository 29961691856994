import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/index";
import PageBox from "../public/index";
import PageForm from 'components/public/pageForm'
import { Breadcrumb, Form, Button } from "antd";
import './pform.css'
import FUNC from 'common/until'

class Publish extends Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      time: 0,
      formPhotos:{}
    };
  }
  onFinish = (values) => {
    values['form_type'] = 'merchant';
    const {formPhotos}=this.state;
    let photos = FUNC.getStatePhotos(formPhotos);//图片数据
    values={...values,...photos}
    this.props.mch_shop_apply(values);
  }

  componentDidMount() {
    this.props.getFields({ sign: 'merchant' })
  }

  children_changePhoto = (field, event, file) => {
    let formPhotos = FUNC.savePhotoFromChild(this.state.formPhotos, field, event, file);
    this.setState({ formPhotos });
  }

  children_changeField = (field, value) => {
    const obj = {}
    obj[field] = value
    this.formRef.current.setFieldsValue(obj)
  }

  render() {
    const { formFields } = this.props.index;
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            商家发布
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="main publish">
          <div id="publicForm">
            <Form
              name="autoForm"
              layout="inline"
              ref={this.formRef}
              onFinish={this.onFinish}
            >
              {formFields ?
                <PageForm data={formFields}
                  onRef={(ref) => this.child = ref}
                  changePhoto={this.children_changePhoto}
                  changeField={this.children_changeField}
                />
                : <div className="flexCenter"></div>}
              <Form.Item style={{marginTop:15,width:'100%'}}>
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  disabled={this.state.time ? true : false}
                >
                  提交{this.state.time ? this.state.time : null}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(Publish);
