import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/index";
import { Row, Col, Select, Button } from "antd";
import { LinkOutlined } from '@ant-design/icons'
const { Option } = Select;
class PageIndex extends Component {
  constructor() {
    super()
    this.onChangeLink = this.onChangeLink.bind(this)
  }

  onChangeLink(url) {
    window.open(url);
  }

  render() {
    let { flink } = this.props.index;
    return (
      <Row gutter={{xs:0,md:50}} className="flink">
        <Col sm={24} md={4} className="flinkTitle"><Button block><LinkOutlined />友情链接</Button></Col>
        {flink.map(ele => (
          <Col sm={24} md={5} key={`flink${ele.value}`}>
            <Select style={{ width: '100%' }} name={ele.label} placeholder={ele.label} onChange={this.onChangeLink}>
              {ele.children.map(ele2 => (
                <Option value={ele2.link_url} key={ele.flink_id + '-' + ele2.value}>{ele2.label}</Option>
              ))}
            </Select>
          </Col>
        ))}
      </Row>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(PageIndex);
