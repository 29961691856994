import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/index";
import {Spin,Button} from 'antd'

import SiteTop from "./Top";
import SiteMenu from "./Menu";
import SiteSearch from "./TopSearch";
import Footer from "./Footer";

class PublicIndex extends Component {

  componentDidMount() {
   let uid = localStorage.getItem("uid");
   if(!uid){
     this.props.showModal('login')
   }
  }

  render() {
    let {siteMenu,siteInfo}=this.props.index;
    let uid=localStorage.getItem("uid")
    let {userInfo}=this.props.user;
    if(siteMenu && siteInfo){
     return (
       <div className="site-box">
         <SiteTop data={siteMenu} />
         <SiteSearch />
         <SiteMenu data={siteMenu} />
         <div className="site-body">
           <div className="wrapper">
             {uid || userInfo ? (
               this.props.children
             ) : (
               <div className="center">
                 <Button type="primary" onClick={()=>{this.props.showModal('login')}}>点击登录</Button>
               </div>
             )}
           </div>
         </div>
         <Footer data={siteMenu} siteInfo={siteInfo} />
       </div>
     );
    }else{
      return <div className="flexCenter"><Spin tip="loading..."/></div>
    }
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(PublicIndex);
