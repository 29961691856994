import Type from "./type";

import Conf from "../common/config";
import Axios from './request'
const { NEWS_LST,NEWS_TYPE,ACTIVE_MENU } = Type;



export function setActiveUrl(page){
  return({type:ACTIVE_MENU,activeUrl:page})
}

/**
 * 新闻列表
 * @param {*} args 
 */
export function newsLst(args) {
  return dispatch => {
    Axios.post(Conf.api + "/news/lst", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: NEWS_LST, newsLst: res.data.data });
      }
    });
  };
}

/**
 * 帮助文章列表
 * @param {*} args 
 */
export function help(args) {
  return dispatch => {
    Axios.post(Conf.api + "/news/help", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: NEWS_LST, helpLst: res.data.data });
      }
    });
  };
}
//newsType
export function newsType() {
  return dispatch => {
    Axios.post(Conf.api + "/news/newsType").then(res => {
      if (!res.data.code) {
        dispatch({ type: NEWS_TYPE, newsType: res.data.data });
      }
    });
  };
}

/**
 * 新闻列表推荐新闻
 * @param {*} args 
 */
export function newsRec(args) {
  return dispatch => {
    Axios.post(Conf.api + "/news/rec", {
      data: args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: NEWS_LST, newsRec: res.data.data });
      }
    });
  };
}

/**
 * 热门问题
 * @param {*} args 
 */
export function helpRec(args) {
  return dispatch => {
    Axios.post(Conf.api + "/news/helprec", {
      data: args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: NEWS_LST, helpRec: res.data.data });
      }
    });
  };
}
/**
 * 文章详情
 * @param {*} args 
 */
export function article(id) {
  return dispatch => {
    dispatch({ type: NEWS_LST, article: null });
    Axios.post(Conf.api + "/news/index?id="+id).then(res => {
      if (!res.data.code) {
        dispatch({ type: NEWS_LST, article: res.data.data });
      }
    });
  };
}
