import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/user";
import PageBox from "../public/index";
import {Link} from 'react-router-dom'
import {Breadcrumb,Button} from "antd";

class VipIntroduction extends Component {
  componentDidMount(){
    this.props.vipCardInfo()
  }

  render() {
    let { vipCard } = this.props.user;
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            VIP会员卡介绍
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="wrapper article">
          {vipCard?
          <div>
            <div dangerouslySetInnerHTML={{__html:vipCard.level_content}}></div>
            <div style={{textAlign:'center',paddingTop:30}}>
              <Button type="primary"><Link to="/member/upgrade">立即升级</Link></Button>
            </div>
          </div>
          :null}
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(VipIntroduction);
