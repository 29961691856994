import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/cart";
import PageBox from "components/public/userIndex";
import { Link } from 'react-router-dom'
import { Row, Col, Breadcrumb, Checkbox, Button, InputNumber } from "antd";
import UserPub from '../public'
import UserLeft from '../left'
import FUNC from 'common/public'

class HotNews extends Component {

  componentDidMount() {
    let uid = localStorage.getItem("uid")
    this.props.lst({ uid });
  }

  render() {
    let { cartLst } = this.props.goods;
    console.log(cartLst)
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href="/news">会员中心</a>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="user-center">
          <div className="user-public">
            <UserPub />
          </div>
          <div className="user-left">
            <UserLeft path={this.props.history.location.pathname} />
          </div>
          <div className="user-right">
            <div className="main cart">
              {cartLst ?
                cartLst.map(ele => (
                  <div className="oneCart">
                    <div>
                      <div className="shopTitle">
                        <Checkbox />
                        <span>店铺：听见花开</span></div>
                    </div>
                    <div className="cartInfo">
                      <div className="cartTitleInfo">
                        <Checkbox />
                        <Link to="/goods/1"><img src={ele.gd_thumb} alt="商品图" className="thumb" /></Link>
                        <div className="titleAndSize">
                          <div className="title">{ele.gd_title}</div>
                          {/* <div className="size">食品口味：185gx2袋</div> */}
                        </div>
                      </div>
                      <div className="price">{ele.gd_price}</div>
                      <div className="goodsNum"><InputNumber value={ele.cart_goods_num} style={{ width: 70 }} /></div>
                <div className="totalPrice">{ele.total}</div>
                      <div className="action">
                        <Button type="link">收藏</Button>
                        <Button type="link">删除</Button>
                      </div>
                    </div>
                  </div>
                ))
                : null}
            </div>
          </div>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
