import Type from "./type";

import Conf from "../common/config";
import Axios from './request'
const { FRIEND_LST,FRIEND_USER_LST,ACTIVE_MENU } = Type;


export function setActiveUrl(page){
  return({type:ACTIVE_MENU,activeUrl:page})
}
/**
 * 列表
 * @param {*} args 
 */
export function lst(args) {
  return dispatch => {
    Axios.post(Conf.api + "/friend/lst", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: FRIEND_LST, friendLst: res.data.data });
      }
    });
  };
}

/**
 * 交友页面显示的用户列表
 * @param {*} args 
 */
export function frUserLst(args) {
  return dispatch => {
    Axios.post(Conf.api + "/friend/fruserlst", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: FRIEND_USER_LST, frUserLst: res.data.data });
      }
    });
  };
}