import Type from "./type";

import Conf from "../common/config";
import FUNC from 'common/public'
import {message} from 'antd'
import Axios from './request'
import { HashRouter } from "react-router-dom";
const { PAGE_AD,SALON_LST,SALON_USER,GLOBAL_SHOW_MODAL,CITY_LST,SALON_RECORD,ACTIVE_MENU} = Type;


export function getAds(arg){
  return dispatch => {
    Axios.get(Conf.api + "/banner/lst?type="+arg).then(res => {
      if (!res.data.code) {
        dispatch({ type: PAGE_AD, salonLstAds: res.data.data });
      }
    });
  };
}

export function setActiveUrl(page){
  return({type:ACTIVE_MENU,activeUrl:page})
}
export function lst(args) {
  return dispatch => {
    Axios.post(Conf.api + "/salon/lst", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: SALON_LST, salonLst: res.data.data });
      }
    });
  };
}

/**
 * 活动用户列表
 * @param {*} args 
 */
export function users(args) {
  return dispatch => {
    Axios.post(Conf.api + "/salon/users", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: SALON_USER, users: res.data.data });
      }
    })
  };
}


export function rec(args) {
  return dispatch => {
    Axios.post(Conf.api + "/salon/rec", {
      data: args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: SALON_LST, salonRec: res.data.data });
      }
    });
  };
}

/**
 * 文章详情
 * @param {*} args 
 */
export function article(args) {
  return dispatch => {
    let uid=localStorage.getItem("uid")
    if(uid){
      args['uid']=uid
    }
    dispatch({ type: SALON_LST, article: null });
    Axios.post(Conf.api + "/salon/index",args).then(res => {
      if (!res.data.code) {
        dispatch({ type: SALON_LST, article: res.data.data });
      }
    });
  };
}
/**
 * 活动报名信息页面
 * @param {*} id ,uid
 */
export function sign(data) {
  return dispatch => {
    let uid=localStorage.getItem("uid")
    if(!uid){
      dispatch({type:GLOBAL_SHOW_MODAL,showModal:'login'})
      return false;
    }
    data['uid']=uid;
    Axios.post(Conf.api + "/salon_record/sign",{
      ...data
    }).then(res => {
      if (!res.data.code) {
        if(res.data.data && res.data.data.out_trade_no){
          window.location.href='/#/order/'+res.data.data.out_trade_no
        }else{
          message.info(res.data.data)
        }
      }
    });
  };
}


export function getCitys(){
  return dispatch => {
    Axios.post(Conf.api + "/city/lst").then(res => {
      if (!res.data.code) {
        dispatch ({ type: CITY_LST, cityLst: res.data.data });
      }
    });
  };
}

/**
 * 沙龙报名记录
 */
export function userSalonRecord(){
  return dispatch => {
    let args={
      uid:localStorage.getItem("uid")
    }
    Axios.post(Conf.api + "/salon/record",args).then(res => {
      if (!res.data.code) {
        dispatch ({ type: SALON_RECORD, salonRecord: res.data.data });
      }
    });
  };
}