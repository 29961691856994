import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/ebookCollect";
import PageBox from "components/public/userIndex";
import { Row, Col, Breadcrumb,Button,Modal ,Divider} from "antd";
import UserPub from "../public";
import UserLeft from "../left";
import FUNC from "common/public";
import {Link} from 'react-router-dom'
const { confirm } = Modal;
class LearnRecord extends Component {
  constructor(){
    super()
    this.del = this.del.bind(this);
  }
  del(item) {
    let that = this;
    confirm({
      title: "确认删除?",
      content: "本次删除无法撤销",
      onOk() {
        that.props.del(item);
      }
    });
  }

  componentDidMount() {
    let uid = localStorage.getItem("uid");
    this.props.lst({ uid: uid});
  }

  render() {
    let { ebookCollect } = this.props.ebook;
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href="/news">会员中心</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            空中书城读书记录
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="user-center">
          <div className="user-public">
            <UserPub />
          </div>
          <div className="user-left">
            <UserLeft path={this.props.history.location.pathname} />
          </div>
          <div className="user-right">
            <div className="main">
              <div className="lst">
                <ul>
                  {ebookCollect &&
                    ebookCollect.map(item => (
                      <li key={item.cl_fid}>
                        <div>
                          <Link to={`/ebook/${item.cl_fid}`}>
                            <span>书籍：{item.ebook_title}</span>
                          </Link>
                        </div>
                        <div className="btnGroup">收藏时间：{item.cl_datetime}<Divider type="vertical" />
                          <Button
                            type="link"
                            onClick={() => {
                              this.del(item);
                            }}
                          >
                            删除
                          </Button></div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(LearnRecord);
