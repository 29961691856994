import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/competition";
import Box from '../article'

class ArticlePage extends Component {


  render() {
    let {id}=this.props.match.params;
    let { article } = this.props.competition;
    return (
      <Box id={id} link={'cominfo'}>
        <div className="comp-content">
        <h2>赛事信息</h2>
          {article?
          <div
            className="article-content" dangerouslySetInnerHTML={{__html:article.cp_content}} id="rich-media"></div>
            :null}
        </div>
      </Box>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(ArticlePage);
