const {
  REACT_APP_TOKEN_TYPE
} = process.env


const config = {
  token_type: REACT_APP_TOKEN_TYPE,
}


export function getToken() {
  const token = config.token_type === 'sessionStorage' ? sessionStorage.getItem("token") : localStorage.getItem("token")
  return token ? token : ""
}


export function setToken(token) {
  if (config.token_type === 'sessionStorage') {
    sessionStorage.setItem("token", token)
  } else {
    localStorage.setItem("token", token)
  }
}


export function clearToken() {
  sessionStorage.clear()
  localStorage.clear()
}

export default config;