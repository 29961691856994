import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/index";

class App extends Component {


  render() {
    let { salon } = this.props.index;
    return (
      <div>
        <div className="indexSalon">
          <h3><Link to="/salon">沙龙活动</Link></h3>
          <ul>
          {salon ? salon.map(ele => (
              <li key={ele.salon_id}>
                <Link to={`/salon/${ele.salon_id}`}>
                <dl>
                  <dt title={ele.salon_title}>{ele.salon_title.substr(0,20)}...</dt>
                  <dd>{ele.salon_desp.substr(0,40)}</dd>
                </dl>
                <img src={ele.salon_thumb} alt="沙龙" />
              </Link>
            </li>
          )):null}
          </ul>
        </div>
      </div>
    )
  }
}


export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(App);
