import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/news";
import PageBox from "./index";
import {Link} from 'react-router-dom'
import { Row, Col, Breadcrumb} from "antd";

// import { HomeOutlined } from "@ant-design/icons";
class HotNews extends Component {

  componentDidMount() {
    this.props.newsLst();
  }

  render() {
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href="/map">网站地图</a>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col span={24}>
            <div className="main siteMap">
              <ul>
                <li>
                  <h3>空中书城</h3>
                  <div>
                    <Link to="/ebook">空中书城</Link>
                    <Link to="/author">作者</Link>
                  </div>
                </li>
                <li>
                  <h3>帮助中心</h3>
                  <div>
                    <Link to="/qa/reg">登录</Link>
                    <Link to="/qa/shop">商城</Link>
                    <Link to="/qa/user">会员</Link>
                    <Link to="/qa/agent">商家</Link>
                    <Link to="/qa/bund">众筹</Link>
                    <Link to="/call">联系客服</Link>
                  </div>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
