import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/competition";
import Box from '../article'
import {Link} from 'react-router-dom'
class ArticlePage extends Component {

componentDidMount(){
  let { id } = this.props.match.params;
  this.props.daka({id})
}

componentDidUpdate(props){
  let { id } = this.props.match.params;
  if(id && id!==props.match.params.id){
    this.props.daka({ id })
  }
}

  render() {
    let { id } = this.props.match.params;
    let { daka } = this.props.competition;
    return (
      <Box id={id} link={'master'}>
        <div className="comp-content">
        <h2>大咖选手</h2>
          {daka?
            <div
              className="article-content" id="user-lst">
              {daka.map((ele, index) => (
                <Link to={`/cpuser/${id}/${ele.user_id}`}>
                  <img src={ele.user_thumb} alt="用户头像" key={index} />
                  <span>{ele.user_nickname}</span>
                </Link>
              ))}
            </div>
            : null}
        </div>
      </Box>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(ArticlePage);
