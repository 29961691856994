import Type from "./type";

import Conf from "../common/config";
import {message} from 'antd';
// import FUNC from 'common/public'
import Axios from './request'
const { TOPIC_LST,TOPIC_INDEX,TOPIC_REC,TOPIC_COMMENT ,TOPIC_USER_LST,GLOBAL_SHOW_MODAL,ACTIVE_MENU,PAGE_AD,TOPIC_TYPE} = Type;

export function getAds(arg){
  return dispatch => {
    Axios.get(Conf.api + "/banner/lst?type="+arg).then(res => {
      if (!res.data.code) {
        dispatch({ type: PAGE_AD, topicAds: res.data.data });
      }
    });
  };
}
export function setActiveUrl(page){
  return({type:ACTIVE_MENU,activeUrl:page})
}

/**
 * 列表
 * @param {*} args
 */
export function lst(args) {
  return dispatch => {
    Axios.post(Conf.api + "/topic/lst", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: TOPIC_USER_LST, topicUserLst: res.data.data });
      }
    });
  };
}

/**
 * 旗袍圈文章
 * @param {*} args sign 1=最新，2=人气，3=关注，4=推荐
 */
export function albumFlow(args) {
  return dispatch => {
    dispatch({ type: TOPIC_LST, topicLst: null});
    Axios.post(Conf.api + "/topic/albumFlow", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: TOPIC_LST, topicLst: res.data.data });
      }
    });
  };
}

/**
 * 发布旗袍圈
 * @param {*} args 
 */
export function add(args) {
  // let that=this;
  //   let uid = localStorage.getItem("uid");
  return dispatch => {
    Axios.post(Conf.api + "/topic/add", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        message.info('发布成功')
        window.location.reload()
      }
    });
  };
}

/**
 * 删除操作
 * @param {*} id 
 */
export function del(id) {
  let that = this;
  let uid=localStorage.getItem("uid")
  return dispatch => {
    Axios.post(Conf.api + "/topic/del", {
      id:id,
      uid:uid
    }).then(res => {
      if (!res.data.code) {
        message.info('删除成功')
        that.lst({ uid: uid});
      }
    });
  };
}

/**
 * 单一话题
 * @param {*} args 
 */
export function indexTopic(data) {
  return dispatch => {
    Axios.post(Conf.api + "/topic/index",data).then(res => {
      if (!res.data.code) {
        dispatch({ type: TOPIC_INDEX, topicIndex: res.data.data });
      }
    });
  };
}

/**
 * 获取评论
 * @param {*} data 
 */
export function getComments(data) {
  return dispatch => {
    Axios.post(Conf.api + "/topic/comments",data).then(res => {
      if (!res.data.code) {
        dispatch({ type: TOPIC_COMMENT, topicComments: res.data.data });
      }
    });
  };
}
/**
 * 推荐话题，取10条
 * @param {*} args 
 */
export function topicRec(args) {
  return dispatch => {
    Axios.post(Conf.api + "/topic/topicRec", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: TOPIC_REC, topicRec: res.data.data });
      }
    });
  };
}


/**
 * 点赞
 * @param {*} data
 */
export function zan(data) {
  let that = this;
  return dispatch => {
    Axios.post(Conf.api + "/topic/zan", data).then(res => {
      if (!res.data.code) {
        message.info('操作成功')
        that.index(data)
      }
    });
  };
}

/**
 * 评论
 * @param {*} data 
 */
export function pinglun(data) {
  let that = this;
  return dispatch => {
    Axios.post(Conf.api + "/topic/comment", data).then(res => {
      if (!res.data.code) {
        message.info('操作成功')
        that.getComments({id:data.id,uid:data.uid})
      }
    });
  };
}

/**
 * 旗袍文章类型
 * @param {*} data 
 */
export function getTopicType(data) {
  let that = this;
  return dispatch => {
    Axios.post(Conf.api + "/topic/topicType", data).then(res => {
      if (!res.data.code) {
        dispatch({type:TOPIC_TYPE,topicType:res.data.data})
      }
    });
  };
}

/**
 * 登录框
 */
export function showDialog(arg){
  return ({type:GLOBAL_SHOW_MODAL,showModal:arg})
}