import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/competition";
import PageBox from "../public/userIndex";
import { Row, Col, Breadcrumb, Card, Button, message,InputNumber } from "antd";
import RightNews from "./right";
import FUNC from 'common/public'
import { Link } from 'react-router-dom'

class HotNews extends Component {
  constructor(){
    super()
    this.state={
      selected:null,
      num:1
    }
    this.create=this.create.bind(this)
    this.selectTicket=this.selectTicket.bind(this)
  }

  selectTicket(id){
    this.setState({selected:id,num:1})
  }

  create(){
    let {selected,num}=this.state;
    let obj={}
    if(!selected){
      message.info('请选择门票')
      return false;
    }
    obj['id']=this.props.match.params.id;
    obj['num']=num;
    obj['type']=5;
    obj['ticket_id']=selected;
    this.props.createCpOrder(obj)
  }
  componentDidMount() {
    let uid = localStorage.getItem("uid")
    if (uid) {
      let id = this.props.match.params.id;
      this.props.cpSign({ id: id, uid: uid });
    } else {
      this.props.showDialog('login')
    }
    let { id } = this.props.match.params;
    this.props.article({ id })
    this.props.tickets({id})
  }

  render() {
    let { cpSign, article,tickets } = this.props.competition;
    let {num}=this.state;
    let id = this.props.match.params.id;
    let text = null;
    let cpInfo = null;
    let baoming = null
    if (cpSign) {
      if (cpSign.dead) {
        text = <Card className="baomingjieshu" title="温馨提示">
          <dl>
            <dt><i className="iconfont icon-toupiaojieshu"></i></dt>
            <dd>报名已结束</dd>
          </dl>
        </Card>
      }
      if (cpSign.signup) {
        cpInfo = <div className="baomingxinxi">
          <p>报名时间：{cpSign.signup.cpu_datetime}</p>
          <p>报名费用：{cpSign.signup.order_total}</p>
          <p>所属赛事：<Link to={`/cominfo/${id}`}>{article ? article.cp_title : null}</Link></p>
        </div>
      }
      if (!cpSign.signup && !cpSign.dead) {
        baoming = <Card className="baominginfo" title="确认订单信息" size="small">
          <div>点击选择门票：</div>
          {article ?
            <ul className="tickets">
              {tickets && tickets.map((ele) => (
                <li key={ele.ticket_id} onClick={()=>{this.selectTicket(ele.ticket_id)}} className={this.state.selected===ele.ticket_id?'active':''}>
                  <img src={ele.ticket_thumb?ele.ticket_thumb:require('common/img/nopic.png')} alt="menpiao " />
                  <div>
                  <div className="titl">门票类型：{ele.ticket_title}(￥{ele.ticket_price})</div>
                  {ele.ticket_desp?
                                    <div className="deps">门票描述：{ele.ticket_desp}</div>
                  :null}

                  <div>
                  余票：{ele.ticket_has}张
                  </div>
                  </div>
                </li>
              ))}
            </ul>
            : null}
          <p>活动时间：{cpSign.cp_begin} - {cpSign.cp_end}</p>
          {cpSign.cp_bmsm?
                    <p>报名说明：{cpSign.cp_bmsm}</p>
          :null}

          <div>
            购买数量：<InputNumber min={1} value={num} onChange={(v)=>{this.setState({num:v})}}/>
                  </div>
          <p style={{ textAlign: 'center' }}><Button type="primary" onClick={this.create}>创建订单</Button></p>
        </Card>
      }
    }
    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/competition">赛事</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/cominfo/${id}`}>{article ? article.cp_title : null}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>买票</Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col md={17} xs={24}>
            <div className="main baoming">
              <h1>{article ? article.cp_title : null} 门票购买</h1>
              {text}
              {cpInfo}
              {baoming}
            </div>
          </Col>
          <Col md={7} xs={24}>
            <RightNews />
          </Col>
        </Row>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
