import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/user";
import { Form, Button, Input, InputNumber } from "antd";

class Login extends Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.onFinish = this.onFinish.bind(this);
    this.getCode = this.getCode.bind(this);
    this.state = {
      time: 0
    };
  }

  getCode() {
    this.formRef.current.validateFields(["phone"]).then(value => {
      this.props.sendSms({ phone: value.phone,sign:'forget' });
      let time = 60;
      let t = setInterval(() => {
        if (time <= 0) {
          clearInterval(t);
        }
        this.setState({ time: time-- });
      }, 1000);
    });
  }

  componentDidMount() {}

  onFinish = values => {
    this.props.forget(values)
  };
  render() {
    return (
      <div className="login">
        <div className="login-tip">找回密码</div>
        <Form
          name="login"
          ref={this.formRef}
          layout="vertical"
          style={{ width: 300 }}
          onFinish={this.onFinish}
        >
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: "请输入正确格式手机号!",
                pattern: /^1[3456789]\d{9}$/
              }
            ]}
          >
            <Input placeholder="请输入手机号" />
          </Form.Item>
          <Form.Item
            name="code"
            rules={[
              {
                required: true,
                message: "请输入验证码!"
              }
            ]}
          >
            <div style={{ display: "flex", justifyContent: "spaceBetween" }}>
              <InputNumber
                placeholder="请输入验证码"
                style={{ flexGrow: 1, marginRight: 10 }}
              />
              {this.state.time ? (
                <Button type="ghost" disabled>
                  {this.state.time} s
                </Button>
              ) : (
                <Button type="ghost" onClick={this.getCode}>
                  获取验证码
                </Button>
              )}
            </div>
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "请输入密码"
              }
            ]}
          >
            <Input.Password placeholder="请输入新的密码" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              提交
            </Button>
          </Form.Item>
        </Form>

        <div style={{ textAlign: "right", width: "100%" }}>
          <Button
            type="link"
            onClick={() => {
              this.props.showLogin("login");
            }}
          >
            返回登录
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(Login);
