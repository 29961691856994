import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/user";
import { Form, Button, InputNumber, Card, Radio, message } from "antd";
import FUNC from 'common/public'

class Login extends Component {
  constructor() {
    super()
    this.onFinish = this.onFinish.bind(this)
    this.state = {
      loading: false,
      value: 7,
      value2: 0
    }
  }
  componentDidMount() {
    let uid = localStorage.getItem("uid")
    this.props.wallet({ uid: uid })
    this.props.closeDialog()
  }

  onFinish = values => {
    let { value2 } = this.state;
    if (!values.money && !value2) {
      message.info('充值金额不能为空')
      return false;
    }
    this.setState({ loading: true })
    setTimeout(() => {
      this.setState({ loading: false })
    }, 3000);
    this.props.createPayMoneyOrder({
      money: values.money ? values.money : value2,
      type: 2
    })
  }


  render() {
    let { wallet } = this.props.user;
    const radioStyle = {
      display: 'inline-block',
      height: '30px',
      lineHeight: '30px',
    };

    return (
      <div style={{ paddingBottom: 20 }}>
        <Card title='现金充值' extra={`现金余额：￥${wallet ? wallet.user_money : 0}`}>
          <Form
            name="wallet"
            layout="inline"
            initialValues={{
              money: this.state.value
            }}
            onFinish={this.onFinish}
          >
            <Form.Item
              name="money"
            >
              <Radio.Group onChange={(e) => { this.setState({ value: e.target.value }) }}>
                <Radio style={radioStyle} value={7}>
                  7元
        </Radio>
                <Radio style={radioStyle} value={27}>
                  27元
        </Radio>
                <Radio style={radioStyle} value={57}>
                  57元
        </Radio>
                <Radio style={radioStyle} value={177}>
                  177元
        </Radio>
                <Radio style={radioStyle} value={777}>
                  777元
        </Radio>
                <Radio style={radioStyle} value={3000}>
                  3000元
        </Radio>
                <Radio style={radioStyle} value={5000}>
                  5000元
        </Radio>
                <Radio style={radioStyle} value={0}>
                  其他金额
          {this.state.value === 0 ?
                    <InputNumber style={{ width: 100, marginLeft: 10 }} min={1} onChange={(v) => { this.setState({ value2: v }) }} />
                    : null}
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={this.state.loading ? true : false}>
                立即充值
            </Button>
            </Form.Item>
            <Form.Item className="card-tip">
              提示：充值后不能提现，只能用于该平台给选手送礼和消费平台产品，请理性充值。
            </Form.Item>
          </Form>
        </Card>

      </div>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(Login);
