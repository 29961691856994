import Type from "./type";

import Conf from "../common/config";
import { message } from "antd";
import Axios from './request'
const { ADDRESS_LST,FORM_CONTENT } = Type;

export function lst(args) {
  return dispatch => {
    Axios.post(Conf.api + "/a/lst", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: ADDRESS_LST, addressLst: res.data.data });
      }
    });
  };
}

/**
 * 代理商申请
 * @param {*} args 
 */
export function apply(args) {
  return dispatch => {
    Axios.post(Conf.api + "/form/apply", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        message.info("提交成功");
      }
    });
  };
}

/**
 * 获取表单内容
 */
export function getFormContent(args){
  return dispatch => {
    Axios.post(Conf.api + "/form/content", args).then(res => {
      if (!res.data.code) {
        dispatch({type:FORM_CONTENT,formContent:res.data.data})
      }
    })
  }
}

