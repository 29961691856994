import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/competition";
import Box from '../article'
import { Link } from 'react-router-dom'
import { Row, Col, Breadcrumb, List } from "antd";
import moment from "moment";
// import { HomeOutlined } from "@ant-design/icons";
class HotNews extends Component {
  componentDidMount() {
    let { id } = this.props.match.params;
    this.props.reportArticle({ id })
  }

  componentDidUpdate(props) {
    let { id } = this.props.match.params;
    if (id && id !== props.match.params.id) {
      this.props.reportArticle({ id })
    }
  }

  render() {
    let { competitionReport } = this.props.competition;
    let { id } = this.props.match.params;
    return (
      <Box id={id} link="comreportlst">
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/comreportlst">赛事报道列表</Link>
          </Breadcrumb.Item>
        </Breadcrumb>

        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col md={24} xs={24}>
            <div className="main">
              {competitionReport ? (
                <List
                  dataSource={competitionReport}
                  pagination={{
                    pageSize: 10
                  }}
                  renderItem={item => (
                    <List.Item>
                      <Link
                        to={`/comreport/${item.report_id}`}
                        className="articleItem"
                      >
                        <div className="articleTitle">
                          {item.report_thumb ? (
                            <img
                              src={item.report_thumb}
                              alt="图片"
                            />
                          ) : (
                              <img
                                src={require('common/img/nopic.png')}
                                alt="图片"
                              />
                            )}
<div>
                          <div className="articleTitle">
                            {item.report_title}
                            <span> ({moment(item.report_datetime).format("YYYY-MM-DD")})</span>
                          </div>
                          <div className="articleDesp">
                            {item.report_desp}
                          </div>
                          </div>
                        </div>
                      </Link>
                    </List.Item>
                  )}
                />
              ) : null}
            </div>
          </Col>
        </Row>
      </Box>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
