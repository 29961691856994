import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/cpUser";
// import {Link} from 'react-router-dom'
import { Spin} from "antd";

class HotNews extends Component {

  componentDidMount() {
    let {modalParams}=this.props.index;
    this.props.info(modalParams)
  }

  render() {
    let {cpResInfo}=this.props.cpuser;
    return (
      cpResInfo?
      <div className="actRecord">
        <dl>
          <dt>{cpResInfo.cp_title}({cpResInfo.cp_begin})
          </dt>
            <dd>报名时间：{cpResInfo.cpu_datetime}</dd>
            <dd>活动地址：{cpResInfo.cp_city}{cpResInfo.cp_address}</dd>
            <dd>报名状态：{cpResInfo.cpu_is_check?'报名成功':'报名失败'}</dd>
        </dl>
      </div>
      :<Spin/>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
