import React from "react";
import BannerAnim, { Element } from 'rc-banner-anim';
import 'rc-banner-anim/assets/index.css';
import './banner.less'

const BgElement = Element.BgElement;
export default (props) => {
  let ads = props.data
  return (
    <BannerAnim prefixCls="learn-ads" autoPlay arrow={false}>
      {ads.map(ele => (
        <Element prefixCls="banner-ads-elem" key={`banner-${ele.banner_id}`}>
          <BgElement
            key={`bannerBg-${ele.banner_id}`}>
            <a href={ele.banner_link} target="_blank" rel="noopener noreferrer">
              <img src={ele.banner_thumb} alt="图片" />
            </a>
          </BgElement>

        </Element>
      ))}
    </BannerAnim>
  );
}
