import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/qipao";
import PageBox from "../public/index";
import { Row, Col, Breadcrumb } from "antd";
import RightNews from './right'
import {Link} from 'react-router-dom'

class HotNews extends Component {

  componentDidMount() {
    let id = this.props.match.params.id
    this.props.article({id})
  }

  componentDidUpdate(props){
    let { article } = this.props.qipao;
    if(props.qipao.article !== article && article){
      document.title=article.qp_title+' - '+this.props.index.siteInfo.sitename;
    }
  }

  render() {
    let { article } = this.props.qipao;

    return (
      <PageBox>
        <Breadcrumb style={{ paddingTop: 20 }}>
          <Breadcrumb.Item>
            <a href="/">首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            旗袍圈
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            旗袍圈文章
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col md={17} xs={24}>
            <div className="main">
              {article ? (
                <div className="article">
                  <h1>{article.qp_title}</h1>
                  <div className="datetime">发布时间：{article.qp_dateline}</div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: article.qp_content
                    }}
                  ></div>
                </div>
              ) : null}
            </div>
          </Col>
          <Col md={7} xs={24}>
            <RightNews />
          </Col>
        </Row>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
