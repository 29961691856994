const indexInit = {

}
const news = (state = indexInit, action) => {
  switch (action.type) {
    case "COMPETITION_LST":
      return Object.assign({}, state, {
        ...action
      });
    case "CP_PAY_LST":
      return Object.assign({}, state, {
        ...action
      });
    case "CP_FLOWER_LST":
      return Object.assign({}, state, {
        ...action
      });
      case "CP_RANK":
      return Object.assign({}, state, {
        ...action
      });
    case "CP_RECORD":
      return Object.assign({}, state, {
        ...action
      });
    case "COMPETITION_HOT_USERS":
      return Object.assign({}, state, {
        ...action
      });
    case "COMPETITION_USER":
      return Object.assign({}, state, {
        ...action
      });
    case "CITY_LST":
      return Object.assign({}, state, {
        ...action
      });
    case "COMPETITION_MENU":
      return Object.assign({}, state, {
        ...action
      });
    case "CP_TICKETS":
      return Object.assign({}, state, {
        ...action
      });
    case "COMPETITION_ARTICLE":
      return Object.assign({}, state, {
        ...action
      });
    case "REPORT_ARTICLE":
      return Object.assign({}, state, {
        ...action
      });
    case "COMPETITION_GIFTS":
      return Object.assign({}, state, {
        ...action
      });
    default:
      return state;
  }
}

export default news