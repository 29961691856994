import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/user";
import { Form, Button, Input, InputNumber } from "antd";
// import { Link } from "react-router-dom";
import PageBox from "../public/index";
import FUNC from 'common/public'

class Login extends Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      time: 0,
      values:{}
    };
  }

  getCode=()=>{
    this.formRef.current.validateFields(["phone"]).then(value => {
      this.props.sendSms({ phone: value.phone, sign: 'reg' });
      let time = 60;
      let t = setInterval(() => {
        if (time <= 0) {
          clearInterval(t);
        }
        this.setState({ time: time-- });
      }, 1000);
    });
  }

  onFinish = values => {
    values['page']=1;
    let uid=FUNC.getCookie('promoter')
    //没有填写推广码，但是有员工ID
    if(!values.promoter && uid){
      values['promoter_uid']=uid
    }
    this.props.regYYG(values)
  };

  handleClick=(e)=>{
    e.target.focus();
  }

  render() {
    let {values}=this.state;
    let {promoter}=this.props.match.params;
    if(promoter){
      let {values}=this.state;
      values['promoter']=promoter;
    }
    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };
    const tailLayout = {
      wrapperCol: { offset: 6, span: 18 },
    };

    return (
      <PageBox>
        <div className="login" style={{ paddingTop: 50 }}>
          <div className="login-tip">运营官注册</div>
          <Form
            name="login"
            {...layout}
            ref={this.formRef}
            initialValues={values}
            onFinish={this.onFinish}
          >
            <Form.Item
              name="phone"
              label="登录手机号"
              rules={[
                {
                  required: true,
                  message: "请输入正确格式手机号!",
                  pattern: /^1[3456789]\d{9}$/
                }
              ]}
            >
              <Input placeholder="请输入手机号" onClick={this.handleClick}/>
            </Form.Item>
            <Form.Item
              name="code"
              label="手机验证码"
              rules={[
                {
                  required: true,
                  message: "请输入验证码!"
                }
              ]}
            >
              <div style={{ display: "flex", justifyContent: "spaceBetween" }}>
                <InputNumber
                  placeholder="请输入验证码"
                  onClick={this.handleClick}
                  style={{ flexGrow: 1, marginRight: 10 }}
                />
                {this.state.time ? (
                  <Button type="ghost" disabled>
                    {this.state.time}
                  </Button>
                ) : (
                    <Button type="ghost" onClick={this.getCode}>
                      获取验证码
                    </Button>
                  )}
              </div>
            </Form.Item>
            <Form.Item
            name="password"
            label="登录密码"
            rules={[
              {
                required: true,
                message: "请输入密码",
              }
            ]}
          >
            <Input.Password placeholder="请输入密码" autoComplete="new-password"  onClick={this.handleClick}/>
          </Form.Item>
            <Form.Item
              name="promoter"
              label="邀请码"
            >
              <Input placeholder="分享码"  onClick={this.handleClick}/>
            </Form.Item>
            <Form.Item  {...tailLayout} className="regbtn">
              <Button type="primary" htmlType="submit">
                立即注册
            </Button>
            </Form.Item>
            <Form.Item  {...tailLayout} className="loginlink">
              <div style={{ textAlign: "right"}}>
                <Button
                  type="link"
                  onClick={() => {
                    this.props.showLogin("login");
                  }}
                >
                  已有账号? 立即登录
          </Button>
              </div>
            </Form.Item>
          </Form>


        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(Login);
