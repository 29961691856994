import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions";
import { Input, Form } from "antd"


class UserForm extends Component {
  constructor() {
    super()
    this.state = {
      value: null
    }
  }

  componentDidMount() {
    let { item } = this.props;
    this.setState({ value: item.value })
    this.props.onChange(item.field, item.value)
  }

  handleClick = (e) => {
    e.target.focus();
  }

  handleChange = (field, value) => {
    this.setState({ value })
    this.props.onChange(field, value)
  }

  render() {
    let { item,index } = this.props;

    return <Form.Item
      label={item.title}
      key={item.field}
      name={item.field}
      rules={[
        {
          required: !!item.required,
          message: "请输入" + item.title + "!"
        }
      ]}
    >
      <Input
        placeholder={item.placeholder}
        tabIndex={index}
        disabled={!!item.disabled}
      />
    </Form.Item>
  }
}

export default connect(state => state, dispatch => bindActionCreators(actionsCreators, dispatch))(UserForm);