const indexInit = {
    
}
const group = (state = indexInit, action) => {
    switch (action.type) {
      case "FRIEND_LST":
        return Object.assign({}, state, {
          ...action
        });
      case "TOPIC_LST":
        return Object.assign({}, state, {
          ...action
        });
        case "TOPIC_USER_LST":
          return Object.assign({}, state, {
            ...action
          });
      default:
        return state;
    }
}

export default group