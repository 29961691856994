import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, HashRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/index";
import { Card, Button, Divider } from 'antd'

class App extends Component {

  site=()=>{
    let router = new HashRouter()
    router.history.push('/form/agent')
  }
  
  train=()=>{
    let router = new HashRouter()
    router.history.push('/form/training')
  }

  saishi=()=>{
    let router = new HashRouter()
    router.history.push('/form/competition')
  }

  ruzhu=()=>{
    let router = new HashRouter()
    router.history.push('/street')
  }

  yunying=()=>{
    let router = new HashRouter()
    router.history.push('/yunying')
  }

  render() {
    let { userInfo } = this.props.user;
    //未登录默认女性头像
    let avatar=<img src={require('common/img/user.png')} alt="用户头像" className="avatar" />
    if(userInfo){
      if(userInfo.user_thumb){
        avatar=<img src={userInfo.user_thumb} alt="用户头像" className="avatar" />
      }else{
        if(userInfo.user_sex==='男'){
          avatar=<img src={require('common/img/male.jpg')} alt="用户头像" className="avatar" />
        }
      }
    }
    return (
      <div className="indexUserBox">
        <Card style={{ width: '100%' }}>
          <div className="userrightinfo">
          {avatar}
            {userInfo ?
              <div className="btns">
                <Link to={`/user/${userInfo.user_id}`}>{userInfo.user_nickname}</Link>
              </div>
              :
              <div className="btns">
                <Button type="primary" size="small" onClick={() => { this.props.showModal('login') }}>登录</Button>
                {/* <Divider type="vertical" /> */}
                {/* <Button type="primary" size="small" onClick={() => { this.props.showModal('reg') }}>注册</Button> */}
              </div>
            }
          <div className="indexRightMenu">
            <Link className="menuItem" to="/news">
              <i className="iconfont icon-toutiao"></i>
              <div>头条</div>
            </Link>
            <Link className="menuItem" to="/topic">
              <i className="iconfont icon-pengyouquan"></i>
              <div>朋友圈</div>
            </Link>
          </div>
          <div className="indexRightMenu2">
            <Link className="menuItem" to="/cate/25">
              <i className="iconfont icon-zhinan"></i>
              <div>服务指南</div>
            </Link>
            <Link className="menuItem" to="/cate/34">
              <i className="iconfont icon-xiazai"></i>
              <div>资料下载</div>
            </Link>
          </div>
          </div>
          <div className="toprightMenu">
            <Button type="primary" size="large" block onClick={() => { this.props.showModal('reg') }}><i className="iconfont icon-ziyuan"></i>会员申请</Button>
            <Button type="primary" size="large" block onClick={this.site}><i className="iconfont icon-zhaoshangdailifenlei"></i>分站申请</Button>
            <Button type="primary" size="large" block onClick={this.train}><i className="iconfont icon-peixun"></i>培训申请</Button>
            <Button type="primary" size="large" block onClick={this.saishi}><i className="iconfont icon-bisaihuodong"></i>赛事申请</Button>
            <Button type="primary" size="large" block onClick={this.ruzhu}><i className="iconfont icon-chanpin"></i>产品入驻</Button>
            <Button type="primary" size="large" block onClick={this.yunying}><i className="iconfont icon-yunyingshang"></i>运营官申请</Button>
          </div>
        </Card>
      </div>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(App);
