import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/user";
import { Link } from "react-router-dom";
import {Menu,Button  } from "antd";
const { SubMenu } = Menu;
class UserPublicLeft extends Component {
  constructor(props){
    super(props)
    this.state={
      path:props.path,
      active:false
    }
    this.onClick=this.onClick.bind(this)
  }

  onClick(){
    let {active}=this.state;
    this.setState({active:!active})
  }

  render() {
    let path=this.props.path;
    let {active}=this.state;
    let keyArr=path.split('/')
    let key='/'+keyArr[1]
    return (
      <div className={active?'menubox active':'menubox'}>
        <div className="gerenzhongxin">
          <Button block type="primary" ghost><Link to="/mmenu">返回主页菜单</Link></Button>
        </div>
      <Menu
        defaultOpenKeys={[key]}
        defaultSelectedKeys={[path]}
        className="userLeftMenu"
        mode="inline"
      >
        <SubMenu
          key="/mcomp"
          title={
            <span>
              <span>赛事活动</span>
            </span>
          }
        >
          <Menu.Item key="/mcomp/lst">
            <Link to="/mcomp/lst">我的赛事</Link>
          </Menu.Item>
          <Menu.Item key="/mcomp/salon">
            <Link to="/mcomp/salon">沙龙活动</Link>
          </Menu.Item>
          <Menu.Item key="/mcomp/gift">
            <Link to="/mcomp/gift">礼物记录</Link>
          </Menu.Item>
        </SubMenu>

{/* 修身 */}
        {/* <SubMenu
          key="/mlearn"
          title={
            <span>
              <span>修身</span>
            </span>
          }
        >
          <Menu.Item key="/mlearn/record">
            <Link to="/mlearn/record">浏览记录</Link>
          </Menu.Item>
        </SubMenu> */}
        {/* 旗袍圈 */}
        <SubMenu
          key="/mtopic"
          title={
            <span>
              <span>旗袍圈</span>
            </span>
          }
        >
          <Menu.Item key="/mtopic/friend">
            <Link to="/mtopic/friend">关注列表</Link>
          </Menu.Item>
          <Menu.Item key="/mtopic/lst">
            <Link to="/mtopic/lst">我的旗袍圈</Link>
          </Menu.Item>
        </SubMenu>
        {/* 商城 */}
        <SubMenu
          key="/mshop"
          title={
            <span>
              <span>商城</span>
            </span>
          }
        >
          {/* <Menu.Item key="/mshop/cart">
            <Link to="/mshop/cart">购物车</Link>
          </Menu.Item> */}
          <Menu.Item key="/mshop/addr">
            <Link to="/mshop/addr">收货地址</Link>
          </Menu.Item>
          <Menu.Item key="/mshop/ticket">
            <Link to="/mshop/ticket">发票</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="/mbook"
          title={
            <span>
              <span>空中书城</span>
            </span>
          }
        >
          <Menu.Item key="/mbook/buy">
            <Link to="/mbook/buy">购买记录</Link>
          </Menu.Item>
          <Menu.Item key="/mbook/read">
            <Link to="/mbook/read">阅读记录</Link>
          </Menu.Item>
          <Menu.Item key="/mbook/collect">
            <Link to="/mbook/collect">我的收藏</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="/member"
          title={
            <span>
              <span>个人信息</span>
            </span>
          }
        >
           <Menu.Item key="/member/wallet">
            <Link to="/member/wallet">我的钱包</Link>
          </Menu.Item>
           <Menu.Item key="/member/order">
            <Link to="/member/order">我的订单</Link>
          </Menu.Item>
          <Menu.Item key="/member/upgrade">
            <Link to="/member/upgrade">会员VIP</Link>
          </Menu.Item>
          <Menu.Item key="/member/medal">
            <Link to="/member/medal">我的勋章</Link>
          </Menu.Item>
          <Menu.Item key="/member/setting">
            <Link to="/member/setting">我的设置</Link>
          </Menu.Item>
          <Menu.Item key="/member/scores">
            <Link to="/member/scores">旗币记录</Link>
          </Menu.Item>
          <Menu.Item key="/member/promoter">
            <Link to="/member/promoter">分享二维码</Link>
          </Menu.Item>
          <Menu.Item key="/member/sytj">
            <Link to="/member/sytj">收益统计</Link>
          </Menu.Item>
          <Menu.Item key="/member/promoterres">
            <Link to="/member/promoterres">分享记录</Link>
          </Menu.Item>
          <Menu.Item key="/member/msg">
            <Link to="/member/msg">我的消息</Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
      </div>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(UserPublicLeft);
