import moment from "moment";
import "moment/locale/zh-cn";
import {message} from 'antd'
moment.locale("zh-cn");
// 获取cookie
function getCookie(key) {
  const name = key + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trim();
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

// 设置cookie,默认是30天
function setCookie(key, value) {
  const d = new Date();
  d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toGMTString();
  document.cookie = key + "=" + value + "; " + expires + ";path=/;domain=.qiniangniang.com";
}

/**
 * formPic state保存的原有图片对象{}
 * @param {*} field 保存的表单字段名
 * @param {*} action add/remove
 * @param {*} file
 */
 function savePhotoFromChild(formPic,field, action, file){
  if(action!=='add' && action!=='remove'){
    message.info('操作事件有误,请更新核心组件')
    return false;
  }
  //新增
  if (action==='add') {
    if(formPic.hasOwnProperty(field)){
      formPic[field].push(file)
    }else{
      formPic[field]=[file]
    }
  } else {
    //删除
    formPic[field].forEach((ele,index)=> {
      if(ele.uid===file.uid){
        delete(formPic[field][index])
      }
    });
  }
  return formPic;
}



//删除cookie
function delCookie(name) {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = getCookie(name);
  if (cval != null)
    document.cookie =
      name + "=" + cval + ";expires=" + exp.toGMTString() + ";path=/";
}

//生成从minNum到maxNum的随机数
function randomNum(minNum, maxNum) {
  switch (arguments.length) {
    case 1:
      return parseInt(Math.random() * minNum + 1, 10);
    case 2:
      return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
    default:
      return 0;
  }
}

/**
 * 图片文件转base64
 * @param {*} file
 */
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

/**
 * 格式化提交数据
 */
function formatSubmit(data) {
  for (const k in data) {
    if (data.hasOwnProperty(k)) {
      // 富媒体类型转换
      if (k.indexOf("_content")>=0 && typeof data[k] === "object" && data[k]) {
        data[k] = data[k].toHTML();
      } else if (k.indexOf("date")>=0 && data[k]) {
        data[k] = moment(data[k]).format("YYYY-MM-DD");
      } else if (k.indexOf("datetime")>=0 && data[k]) {
        data[k] = moment(data[k]).format("YYYY-MM-DD HH:mm:ss");
      }else if(k.indexOf("_begin")>=0 && data[k]){
        data[k] = moment(data[k]).format("YYYY-MM-DD HH:mm:ss");
      }else if(k.indexOf("_end")>=0 && data[k]){
        data[k] = moment(data[k]).format("YYYY-MM-DD HH:mm:ss");
      }
    }
  }
  return data;
}

/**
 * 格式化表单初始值
 */
function formatInitial(modalForm) {
  let values = {};
  modalForm.forEach(ele => {
    if (ele.children) {
      ele.children.forEach(ele2 => {
        if (ele2.type === "date" && ele2.value) {
          values[ele2.field] = moment(ele2.value, "YYYY-MM-DD");
        } else if (ele2.type === "datetime" && ele2.value) {
          values[ele2.field] = moment(ele2.value, "YYYY-MM-DD HH:ii:ss");
        } else {
          values[ele2.field] = ele2.value;
        }
      });
    }else{
      values[ele.field]=ele.value;
    }
  });
  return values;
}

/**
 * 获取state中的图片
 * @param {*} pics 
 */

/**
 * 获取state中的图片
 * @param {*} photoFields {pic1:[],pic2:[]}
 */
 function getStatePhotos(photoFields){
  let all={}

  for (const k in photoFields) {
    let picArr=[]
    photoFields[k].forEach(ele => {
      picArr.push(ele.url)
    });

    all[k]=picArr.join(',')
  }

  return all;
}

function getStatePhotos2(photoFields){
  let all={}

  for (const k in photoFields) {
    let picArr=[]
    for (const k2 in photoFields[k]) {
      if(photoFields[k][k2]){
        picArr.push(photoFields[k][k2])
      }
    }

    all[k]=picArr.join(',')
  }

  return all;
}


export default {
  setCookie: setCookie,
  getCookie: getCookie,
  delCookie: delCookie,
  randomNum: randomNum,
  getBase64: getBase64,
  formatSubmit: formatSubmit,
  formatInitial: formatInitial,
  getStatePhotos: getStatePhotos,
  getStatePhotos2:getStatePhotos2,
  savePhotoFromChild:savePhotoFromChild
};
