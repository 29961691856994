import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions";
import { Checkbox, Form } from "antd"


class UserForm extends Component {
  constructor() {
    super()
    this.state = {
      value: null
    }
  }

  componentDidMount() {
    let { item } = this.props;
    let { value } = item;

    let value2 = []
    if (value && value.length) {
      value2 = value.split(',')
      value2.forEach((ele, index) => {
        value2[index] = parseInt(ele)
      });
    }
    this.setState({ value: value2 })
    this.props.onChange(item.field, value2)
  }

  handleClick = (e) => {
    e.target.focus();
  }

  handleChange = (field, value) => {
    this.setState({ value })
    this.props.onChange(field, value)
  }

  render() {
    let { item } = this.props;
    return <Form.Item
      label={item.title}
      key={item.field}
      name={item.field}
      rules={[
        {
          required: !!item.required,
          message: "请选择" + item.title + "!"
        }
      ]}
    >
      <Checkbox.Group
        onChange={e => {
          this.handleChange(item.field, e);
        }}
        value={this.state.value}
        options={item.options}
      />
      <span className="placeholder">{item.placeholder}</span>
    </Form.Item>
  }
}

export default connect(state => state, dispatch => bindActionCreators(actionsCreators, dispatch))(UserForm);