import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionsCreators from "actions/merchant";
import PageBox from "../public/index";
import { HashRouter, Link } from 'react-router-dom'
import { Breadcrumb, Card, Button, List, Rate, message, Divider } from "antd";

class HotNews extends Component {
  constructor() {
    super()
    this.state = {
      id: null
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (!id) {
      window.location.href = "/"
    } else {
      this.setState({ id: parseInt(id) })
      this.props.mchType({ id })
      this.props.getTypeMerchants({ id })
    }
  }

  componentDidUpdate(props) {
    const { id } = this.props.match.params
    if (id && props.match.params.id != id) {
      this.setState({ id: parseInt(id) })
      this.props.getTypeMerchants({ id })
    }
  }

  publish = () => {
    let router = new HashRouter()
    router.history.push("/publish")
  }

  toTel = (tel) => {
    if (tel) {
      window.location.href = "tel:" + tel
    } else {
      message.info('暂无电话')
    }
  }

  toDetail=(ele)=>{
    let router=new HashRouter()
    router.history.push(`/merchant/${ele.id}`)
  }

  render() {
    let { mchType, mchTypeMerchants } = this.props.index;
    const { id } = this.state;
    const desc = [1, 2, 3, 4, , 5]

    return (
      <PageBox>
        <div className="main">
          <Breadcrumb style={{ padding: '5px 0' }}>
            <Breadcrumb.Item>
              <a href="/">首页</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/merchants">商家分类</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="merHead">
            <div></div>
            <Button onClick={this.publish} type="primary">快速开店</Button>
          </div>

          {mchType ?
            <Card title="商家分类" size="small" style={{ marginBottom: 15 }} className="mchType">
              <dl className="mchType">
                <dt className={mchType.id === id ? 'active' : null}> <Link to={`/mch_type/${mchType.id}`}>{mchType.title}</Link></dt>
                {mchType.children.map(ele => (
                  <dd className={ele.id === id ? 'active' : null}><Link to={`/mch_type/${ele.id}`}>{ele.title}</Link></dd>
                ))}
              </dl>
            </Card> : null}
          {mchTypeMerchants ?
            <ul className="mchLst">
              {mchTypeMerchants.map(ele => (
                <div key={ele.id} className="mchItem">
                  <div className="left" onClick={()=>{this.toDetail(ele)}}>
                    <img src={ele.thumb} alt="缩略图" />
                    <dl>
                      <dt>{ele.title}</dt>
                      <dd className="rate">
                        <span>
                          <Rate value={ele.value} disabled />
                          {ele.value ? <span className="ant-rate-text">{desc[ele.value - 1]}</span> : ''}
                        </span>
                        <span>0 条评价</span>
                      </dd>
                      <dd><Divider type="vertical" /><span>{ele.address}</span></dd>
                    </dl>
                  </div>
                  <div className="right">
                    <div className="tags">
                      {ele.dianpingRes&&ele.dianpingRes.length?ele.dianpingRes.map(dp=>(
                        <span>{dp}</span>
                      )):null}
                    </div>
                    <div className="contact">
                      {ele.discount ? <Button type="primary">VIP{ele.discount}折</Button> : null}
                      <Button onClick={()=>{this.toTel(ele.tel)}} type="primary">联系我们</Button>
                    </div>
                  </div>
                </div>
              ))}
            </ul> : null}
        </div>
      </PageBox>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators(actionsCreators, dispatch)
)(HotNews);
