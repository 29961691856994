import Type from "./type";

import Conf from "../common/config";
import { message } from "antd";
import Axios from './request'
const { TICKET_LST,AREA_LST } = Type;

/**
 * 列表
 * @param {*} args 
 */
export function lst(args) {
  return dispatch => {
    Axios.post(Conf.api + "/ticket/lst", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        dispatch({ type: TICKET_LST, ticketLst: res.data.data });
      }
    });
  };
}

/**
 * 设置我的默认地址
 * @param {*} args 
 */
export function setDefault(args) {
  let that=this;
  return dispatch => {
    Axios.post(Conf.api + "/ticket/set_default", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        message.info('更新成功')
        that.lst({uid:args.ticket_uid});
      }else{
        message.info('更新失败')
      }
    });
  };
}

/**
 * 删除地址
 * @param {*} args 
 */
export function del(args) {
  let that = this;
  return dispatch => {
    Axios.post(Conf.api + "/ticket/del", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        message.info("操作成功");
        that.lst({ uid: args.ticket_uid });
      } else {
        message.info("操作失败");
      }
    });
  };
}

/**
 * 获取地址列表
 */
export function getArea() {
  return dispatch => {
    Axios.post(Conf.api + "/ticket/area").then(res => {
      if (!res.data.code) {
        dispatch({ type: AREA_LST, areaLst: res.data.data });
      }
    });
  };
}

/**
 * 新增，更新用户地址
 * @param {*} args 
 */
export function save(args) {
  let that = this;
  return dispatch => {
    Axios.post(Conf.api + "/ticket/save", {
      ...args
    }).then(res => {
      if (!res.data.code) {
        message.info("操作成功");
        that.lst({ uid: args.ticket_uid });
      } else {
        message.info("操作失败");
      }
    });
  };
}
